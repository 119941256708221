import useStore from "../../../../store"
import InfoItem from "../../../common/InfoItem"

export default function Uccs() {
  const application = useStore((state) => state.application)

  return(
    <div>
    <div className="w-full text-left bg-grey-900 rounded-t">
      <h3 className="font-header font-semibold text-md text-green">{'UCCs'}</h3>
    </div>
    <div className="grid grid-cols-1 lg:grid-cols-3 w-full text-left p-2 bg-grey-900 rounded" data-testid="dealership-uccs">
      <InfoItem title={'Previous UCC Filings'}>
        {application.attributes["ucc-notes"]}
      </InfoItem>
      <InfoItem title={'Added by'}>
        {application.attributes["ucc-by"]}
      </InfoItem>
    </div>
    </div>
  )
}