import { useEffect } from "react"
import useStore from "../../../store"
import Spinner from "../../common/Spinner"
import CompletedTransfers from "./CompletedTransfers"
import PendingTransfers from "./PendingTransfers"
import ProcessingTransfers from "./ProcessingTransfers"
import { completedTransferParams } from "../../../constants/defaultTransferParams"
import DesktopExpandableInfo from "../../common/DesktopExpandableInfo"
import SideInfoPanel from "../../common/SideInfoPanel"
import TransferPageBody from "../TransferPage/TransferPageBody"

export default function TransferHistory() {
  const fetchPendingTransfers = useStore((state) => state.fetchPendingTransfers)
  const fetchProcessingTransfers = useStore((state) => state.fetchProcessingTransfers)
  const fetchCompletedTransfers = useStore((state) => state.fetchCompletedTransfers)
  const completedTransfers = useStore((state) => state.completedTransfers)
  const pendingTransfers = useStore((state) => state.pendingTransfers)
  const processingTransfers = useStore((state) => state.processingTransfers)
  const transfer = useStore((state) => state.transfer)
  const setTransfer = useStore((state) => state.setTransfer)

  useEffect(() => {
    fetchPendingTransfers()
    fetchProcessingTransfers()
    if (completedTransfers === null) {
      fetchCompletedTransfers(completedTransferParams)
    }
    setTransfer(null)
  }, [])

  return(
    <div className="flex grow justify-center overflow-y-auto">
      <div
        className={`flex flex-col w-full lg:px-2 ${transfer ? 'lg:w-1/2' : 'lg:w-2/3'}
        lg:h-[85vh] lg:overflow-y-auto rounded transition-all duration-500`}
        data-testid='transfer-container'
      >
        {completedTransfers && pendingTransfers ?
          <div className="w-full">
            {pendingTransfers?.length > 0 && <PendingTransfers />}
            {processingTransfers?.length > 0 && <ProcessingTransfers />}
            <CompletedTransfers />
          </div> :
          <div className="flex grow w-full items-center justify-center">
            <Spinner />
          </div>
        }
      </div>
      <DesktopExpandableInfo condition={transfer}>
        <SideInfoPanel
          body={<TransferPageBody shiftPill={true}/>}
          onClose={() => setTransfer(null)}
        />
      </DesktopExpandableInfo>
    </div>
  )
}