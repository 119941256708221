import {
  Divider,
  Stack,
  Checkbox,
  Box,
} from "@chakra-ui/react"
import useStore from "../../../store"
import SelectDropdown from "../../common/SelectDropdown"
import { purposeEnum, typeEnum } from "../../../constants/touchEnums"

export default function NotesFilterOptions() {
  const setNotesFilterParams = useStore((state) => state.setNotesFilterParams)
  const notesFilterParams = useStore((state) => state.notesFilterParams)
  const setSelectedNotesScopeLabel = useStore((state) => state.setSelectedNotesScopeLabel)
  const setNotePurposeLabel = useStore((state) => state.setNotePurposeLabel)
  const setNoteTypeLabel = useStore((state) => state.setNoteTypeLabel)

  const handleFilterByScope = (scope, label) => {
    if (notesFilterParams['scope'] !== scope) {
      setNotesFilterParams({
        ...notesFilterParams,
        "scope": scope
      })
      setSelectedNotesScopeLabel(label)
    } else {
      clearFilters()
    }
  }

  const handleFilter = (filterType, value, label) => {
    setNotesFilterParams({
      ...notesFilterParams,
      [`filter[${filterType}]`]: value
    })
    if (filterType === 'purpose') {
      setNotePurposeLabel(label)
    } else if ( filterType === 'type') {
      setNoteTypeLabel(label)
    }
  }

  const clearFilters = () => {
    setNotesFilterParams('')
  }

  const getEnumLabel = (enumObject, value) => {
    return enumObject[value]
  }

  return(
    <Box p={4}>
      <Divider my={4} />
      <Stack>
        <Checkbox
          data-testid='with-touches'
          isChecked={notesFilterParams['scope'] === "with_touches"}
          onChange={() => handleFilterByScope("with_touches", "with Touches")}
        >
          Touches
        </Checkbox>
        <Checkbox
          data-testid='with-reminders'
          isChecked={notesFilterParams['scope'] === "with_touches_and_reminders"}
          onChange={() => handleFilterByScope("with_touches_and_reminders", "with Reminders")}
        >
          Reminders
        </Checkbox>
        <SelectDropdown
          label="Filter By Purpose"
          options={Object.keys(purposeEnum)}
          defaultText={'Select purpose'}
          selectedItem={notesFilterParams['filter[purpose]']}
          setSelectedItem={(item) =>
            handleFilter('purpose', item, purposeEnum[item])
          }
          displaySelectedItem={(item) => purposeEnum[item]}
          testId="filter-by-purpose-select"
        />
        <SelectDropdown
          label="Filter By Type"
          options={Object.keys(typeEnum)}
          defaultText={'Select type'}
          selectedItem={notesFilterParams['filter[type]']}
          setSelectedItem={(item) =>
            handleFilter('type', parseInt(item, 10), typeEnum[item])
          }
          displaySelectedItem={(item) => typeEnum[item]}
          testId="filter-by-type-select"
        />
      </Stack>
    </Box>
  )
}