export default function ApprovedExceptions({message, approvedBy, approvedAt}) {
  return (
    <div className="w-full pt-2 pb-2 bg-grey-900 rounded-b">
      <div className="text-sm text-grey-300 mr-1 bg-grey-900 p-1">Approved Exceptions</div>
      {message &&
        <div className="w-full p-1">
          <p className={`text-sm text-grey-100`}>{message}</p>
          <div className="w-full flex items-center">
            <p className="w-full text-sm text-grey-100">
              {(approvedBy || approvedAt) && '- created '}
              {approvedBy && `by ${approvedBy} `}
              {approvedAt && `on ${approvedAt}`}
            </p>
          </div>
        </div>
      }
    </div>
  )
}