import useStore from "../../../store"
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import TransferRow from "./TransferRow"
import { completedTransferParams } from "../../../constants/defaultTransferParams"
import transferFilter from "../../../utilities/transferFilter"
import Spinner from "../../common/Spinner"
import { useIsMobile } from "../../../hooks/useIsMobile"
import CompletedTransfersHeader from "./CompletedTransfersHeader"

export default function CompletedTransfers() {
  const navigate = useNavigate()
  const completedTransfers = useStore((state) => state.completedTransfers)
  const fetchCompletedTransfers = useStore((state) => state.fetchCompletedTransfers)
  const isFetchingCompletedTransfers = useStore((state) => state.isFetchingCompletedTransfers)
  const setTransfer = useStore((state) => state.setTransfer)
  const [transferParams, setTransferParams] = useState(completedTransferParams)
  const [showFilters, setShowFilters] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const filteredTransfers = transferFilter(completedTransfers, searchTerm)
  const isMobile = useIsMobile()

  useEffect(() => {
    fetchCompletedTransfers(transferParams)
  }, [transferParams])

  const handleClick = (transfer) => {
    setTransfer(transfer)
    if (isMobile) {
      navigate(`/transfers/${transfer.id}`)
    }
  }

  return(
    <div className="flex flex-col w-full text-left bg-grey-900 rounded">
      <CompletedTransfersHeader
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        transferParams={transferParams}
        setTransferParams={setTransferParams}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
      />
      {isFetchingCompletedTransfers ?
        <div className="flex w-full justify-center mb-2">
          <Spinner />
        </div> :
        <div className="w-full h-full px-2 rounded">
          {filteredTransfers.map((transfer) => {
            return (
              <TransferRow
                date={transfer.attributes['actual-transfer-date']}
                type={transfer.attributes['payment-type']}
                unitName={`${transfer.attributes.make} ${transfer.attributes.model}`}
                amount={transfer.attributes.amount}
                vin={transfer.attributes.vin}
                filter={searchTerm}
                onClick={() => handleClick(transfer)}
                key={transfer.id}
              />
            )
          })}
        </div>
      }
    </div>
  )
}