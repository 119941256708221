import { useEffect, useState } from "react"
import { FaCaretDown, FaCaretUp } from "react-icons/fa"
import { GrPowerReset } from "react-icons/gr"
import Spinner from "./Spinner"
import Pagination from "./Pagination"
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Button,
  TableContainer,
} from '@chakra-ui/react'
import tailwind from "../../../tailwind.config"
import { useIsMobile } from "../../hooks/useIsMobile"

export default function TableWithSorting({
  data,
  loading,
  columns,
  fetchData,
  paginationData,
  setPage,
  RowComponent,
  page,
  additionalProps
}) {
  const grey300 = tailwind.theme.colors["grey-300"]
  const grey100 = tailwind.theme.colors["grey-100"]
  const red = tailwind.theme.colors["fpx-red"]
  const { currentPage, lastPage, dataTotal, totalPages } = paginationData
  const isMobile = useIsMobile()
  const [tableParams, setTableParams] = useState(null)
  const [direction, setDirection] = useState('ASC')

  useEffect(() => {
    fetchData(tableParams)
  },[page, tableParams])

  const handlePageClick = (event) => {
    setPage(event.selected + 1)
  }

  const handleColumnClick = (attribute) => {
    const newDirection = direction === "ASC" ? "DESC" : "ASC"
    setDirection(newDirection)
    setTableParams({ [`sort[${attribute}]`]: true, [`sort[${attribute}_direction]`]: newDirection })
    setPage(1)
  }

  const clearSorting = () => {
    setTableParams(null)
    setDirection(null)
    setPage(1)
  }

  const renderCaretIcon = (attribute) => {
    const isCurrent = tableParams && tableParams[`sort[${attribute}]`]
    return isCurrent ? (
      direction === 'ASC' ? <FaCaretUp color={grey300} /> : <FaCaretDown color={grey300} />
    ) : null
  }

  return (
    <div>
      {!loading ? (
        <div className="w-full">
          <div className="flex justify-end mr-4 mb-4">
            {tableParams && (
              <div data-testid='clear-sorting-button'>
                <Button bg={red} onClick={clearSorting}>
                  <GrPowerReset color={grey100} size={'20px'} />
                </Button>
              </div>
            )}
          </div>
          <TableContainer>
            <Table variant='simple'>
              <Thead>
                <Tr>
                  {columns.map((column) => (
                    <Th
                      key={column.key}
                      onClick={() => column.isSortable && handleColumnClick(column.key)}
                      color={grey300}
                      cursor={column.isSortable ? 'pointer' : 'default'}
                    >
                      <div className="flex items-center">
                        {column.headerText}
                        {column.isSortable &&
                          <div className="ml-1">
                            {renderCaretIcon(column.key)}
                          </div>
                        }
                      </div>
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {data.map((item) => (
                  <RowComponent key={item.id} item={item} />
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          <Pagination
            currentPage={currentPage}
            lastPage={lastPage}
            dataTotal={dataTotal}
            handlePageClick={handlePageClick}
            totalPages={totalPages}
          />
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  )
}
