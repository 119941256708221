import { useState } from "react"
import { useTranslation } from "react-i18next"
import Swipeable from '../../common/Swipeable'
import ExpandableOptionsRow from '../../common/ExpandableOptionsRow'
import QuickPayoff from "./QuickPayoff"
import { MdAttachMoney } from "react-icons/md"
import { IoMdClose } from "react-icons/io"

export default function UnitRowQuickPayoff({ unit, children }) {
  const { t } = useTranslation()
  const [optionsOpen, setOptionsOpen] = useState(false)
  const [isProcessingQuickPayoff, setIsProcessingQuickPayoff] = useState(false)

  const optionsRowItems = [
    {
      icon: <MdAttachMoney className='text-lg' />,
      label: t('Payoff'),
      onClick: () => {
        setIsProcessingQuickPayoff(true)
        setOptionsOpen(false)
      }
    },
    {
      icon: <IoMdClose className='text-lg' />,
      label: t('Close'),
      onClick: () => setOptionsOpen(false)
    },
  ]

  return(
    <>
      <Swipeable
        onSwipeLeft={() => {
          if (!unit.attributes['ach-initiated']) {
            setOptionsOpen(true)
          }
        }}
        onSwipeRight={() => setOptionsOpen(false)}
      >
        <ExpandableOptionsRow
          items={optionsRowItems}
          optionsOpen={optionsOpen}
        >

          {children}

        </ExpandableOptionsRow>
      </Swipeable>

      {isProcessingQuickPayoff && <QuickPayoff unitId={unit.id} setIsProcessingQuickPayoff={setIsProcessingQuickPayoff} />}
    </>
  )
}