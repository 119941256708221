import AppName from "./AppName"
import useStore from "../../../store"
import { DateTime } from "luxon"
import { useNavigate } from "react-router-dom"
import { useIsMobile } from "../../../hooks/useIsMobile"
import DesktopExpandableInfo from "../../common/DesktopExpandableInfo"
import DashboardAppInfo from "./DashboardAppInfo"

export default function AppRow({app, filter}) {
  const createdAtDate = DateTime.fromISO(app.attributes['created-at']).toLocaleString()
  const isMobile = useIsMobile()
  const setDashboardAppId = useStore((state) => state.setDashboardAppId)
  const dashboardAppId = useStore((state) => state.dashboardAppId)
  const navigate = useNavigate()
  const setDealerId = useStore((state) => state.setDealerId)

  const handleClick = () => {
    if (isMobile) {
      navigate(`/apps/${app.id}`)
      setDealerId(app.attributes['dealer-id'])
    } else {
      setDashboardAppId(app.id)
      setDealerId(app.attributes['dealer-id'])
    }
  }

  return(
    <>
    <tr key={app.id} className={`border-b-2 border-grey-700 w-full hover:cursor-pointer lg:hover:bg-grey-700 ${dashboardAppId === app.id ? 'bg-grey-700': ''}`} onClick={handleClick}>
      <td>
        <div className="flex w-full py-2 px-2 text-grey-300 items-center">
          <AppName app={app} filter={filter}/>
        </div>
      </td>
      <td className="text-center" data-testid='assigned-to-name'>{app.attributes['assigned-to-name']}</td>
      <td className="text-center" data-testid='created-at'>{createdAtDate}</td>
    </tr>
    </>
  )
}