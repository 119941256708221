import { useEffect } from "react"
import useStore from "../../store"
import BankAccountTable from "./BankAccountTable"
import UserInfo from "./UserInfo"
import { useTranslation } from "react-i18next"

export default function AccountManagement() {
  const logoutUser = useStore((state) => state.logoutUser)
  const fetchBankAccounts = useStore((state) => state.fetchBankAccounts)
  const { t } = useTranslation()
  const user = useStore((state) => state.user)

  useEffect(() => {
    if (user?.type === 'DealerUser') {
      fetchBankAccounts()
    }
  }, [])

  return(
    <div className="flex flex-col grow overflow-y-auto items-center">
      <UserInfo />
      {user?.type === "DealerUser" && <BankAccountTable />}
      <div className="flex justify-center w-full lg:w-1/2 p-2 bg-grey-900 mt-2 rounded">
        <div
          className="text-center text-fpx-red hover:cursor-pointer"
          onClick={() => logoutUser()}
        >
          {t('Logout')}
        </div>
      </div>
    </div>
  )
}
