export default function HeaderWithoutSorting({ title, width, style }) {

  return(
    <th
      className={`text-left px-2 font-header text-grey-300 font-semibold ${width} hover:cursor-pointer`}
    >
      <div className={`flex items-center ${style} text-center pt-2`}>
        {title}
      </div>
    </th>
  )
}