import {
  Stack,
  Box,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  Text,
  Flex,
} from "@chakra-ui/react"
import useStore from "../../../../store"
import { useState } from "react"

export default function UtilizationFilter() {
  const dealerTableFilterParams = useStore((state) => state.dealerTableFilterParams)
  const setDealerTableFilterParams = useStore((state) => state.setDealerTableFilterParams)
  const minUtilization = dealerTableFilterParams['utilization[min]'] || 0
  const maxUtilization = dealerTableFilterParams['utilization[max]'] || 100
  const [sliderValues, setSliderValues] = useState([minUtilization, maxUtilization])

  const handleUtilizationChange = (values) => {
    setSliderValues(values)
  }

  const handleUtilizationChangeEnd = (values) => {
    const [min, max] = values
    const utilObj = {
      "utilization[min]": min.toString(),
      "utilization[max]": max.toString(),
    }

    setDealerTableFilterParams({ ...dealerTableFilterParams, ...utilObj })
  }

  return(
    <Box p={4}>
      <Stack spacing={3} mb={4}>
      <Flex alignItems="center" justifyContent="space-between">
          <Text marginRight={4}>{sliderValues[0]}%</Text>
          <RangeSlider
            data-testid='slider'
            aria-label={['min', 'max']}
            value={sliderValues}
            min={0}
            max={100}
            step={5}
            onChange={handleUtilizationChange}
            onChangeEnd={handleUtilizationChangeEnd}
          >
            <RangeSliderTrack>
              <RangeSliderFilledTrack />
            </RangeSliderTrack>
            <RangeSliderThumb data-testid='min-thumb' index={0} />
            <RangeSliderThumb data-testid='max-thumb' index={1} />
          </RangeSlider>
          <Text marginLeft={4}>{sliderValues[1]}%</Text>
        </Flex>
      </Stack>
    </Box>

  )
}