export default function HighlightMatch({str, match, truncate = false}) {
  if (str?.toUpperCase().includes(match?.toUpperCase()) && match != '') {
    const index = str.toUpperCase().indexOf(match.toUpperCase())

    return (
      <div className={`w-full ${truncate && "truncate text-ellipsis"}`}>
        {str.slice(0, index)}
        <span className="text-green">
          {str.slice(index, index + match.length)}
        </span>
        {str.slice(index + match.length, str.length)}
      </div>
    )
  } else {
    return (
      <div className={`w-full ${truncate && "truncate text-ellipsis"}`}>
        {str}
      </div>
    )
  }
}