
export default function ActionButton({ buttonText, onClick, altTextHeader, altTextBody }) {
  return(
    <div className="flex items-center justify-between py-2 pr-3">
      <div className="flex flex-col items-start text-sm ml-5">
        <span className="font-bold">{altTextHeader}</span>
        <span className="text-xs">{altTextBody}</span>
      </div>
      <div
        className="bg-green py-1.5 px-8 text-grey-100 text-center rounded-full"
        onClick={onClick}
      >
        {buttonText}
      </div>
    </div>
  )
}