import { useState } from "react"
import useStore from "../../../store"
import { useNavigate } from "react-router-dom"
import Spinner from "../../common/Spinner"
import { HappyButton } from "../../common/Buttons"
import { useTranslation } from "react-i18next"
import { MdPhotoCamera } from "react-icons/md"
import BarcodeScanner from "../../common/BarcodeScanner"

export default function BBSearchForm() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const setBlackbook = useStore((state) => state.setBlackbook)
  const searchBlackbook = useStore((state) => state.searchBlackbook)
  const setErrMessage = useStore((state) => state.setErrMessage)
  const resetMessages = useStore((state) => state.resetMessages)
  const [formValues, setFormValues] = useState({ vin: '', mileage: '' })
  const [isFetching, setIsFetching] = useState(false)
  const [openCamera, setOpenCamera] = useState(false)

  const handleSubmit = async (e) => {
    if (!formValues.vin || formValues.vin.trim().length === 0) {
      setErrMessage('VIN is a required field')
      return
    }
    if (!formValues.mileage || formValues.mileage.trim().length === 0) {
      setErrMessage('Mileage is a required field')
      return
    }
    setIsFetching(true)
    try {
      const response = await searchBlackbook(formValues)
      const data = await response.json()
      if (response.ok && data.data.usedvehicles.usedvehicles.length > 0) {
        const results = { ...data.data.usedvehicles.usedvehicles[0], mileage: formValues.mileage, vin: formValues.vin.toUpperCase() }
        setBlackbook(results)
        navigate('/blackbook/data')
      } else if (data.data?.usedvehicles?.usedvehicles?.length === 0) {
        setErrMessage('Search did not return any results')
      } else {
        setErrMessage(data.error)
      }
    } catch (err) {
      setErrMessage('Request could not be processed at this time.')
    } finally {
      setIsFetching(false)
    }
  }

  return(
    <div className="w-full p-2 mb-2 bg-grey-900 rounded">
      <div className="sticky top-0 left-0 z-0 bg-grey-900 pb-2">
        <h3 className="font-header font-semibold text-md text-green">{t('Request Floor')}</h3>
      </div>
      <form
        onSubmit={handleSubmit}
        className="flex flex-col w-3/4 mt-2 mx-1"
      >
          <div className="text-sm text-grey-300 my-1">
            VIN
            <div className="flex items-center border-solid border-2 border-grey-700 focus-within:border-grey-300 rounded">
              <input
                value={formValues.vin}
                aria-label="vin"
                name="vin"
                title="vin"
                className="w-full text-md text-grey-100 p-0.5 bg-grey-900 border-none focus:border-none focus:ring-0"
                onChange={(e) => setFormValues({ ...formValues, vin: e.target.value })}
                onClick={() => resetMessages()}
              />
              <MdPhotoCamera
                className="text-2xl mx-1 hover:cursor-pointer"
                onClick={() => setOpenCamera(true)}
              />
            </div>
          </div>
        <label className="text-sm text-grey-300 my-1">
          {t('Mileage')}
          <input
            type="number"
            value={formValues.mileage}
            aria-label="mileage"
            name="mileage"
            title="mileage"
            className="w-full text-md text-grey-100 p-0.5 bg-grey-900 border-solid border-2 border-grey-700 focus:border-grey-300 focus:ring-0 rounded"
            onChange={(e) => setFormValues({ ...formValues, mileage: e.target.value })}
            onClick={() => resetMessages()}
          />
        </label>

        <HappyButton text={t("Search")} onClick={handleSubmit} style="my-4 w-1/2" />

        {isFetching && <Spinner color="green" />}
      </form>

      <div className="fixed z-50 top-0 right-0">
        {openCamera &&
          <BarcodeScanner
            close={() => setOpenCamera(false)}
            setBarcode={(vin) => setFormValues({ ...formValues, vin: vin})}
          />
        }
      </div>
    </div>
  )
}