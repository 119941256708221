import FilterIndicator from "./FilterIndicator"

export default function TableFilterIndicators({filterParams, setFilterParams, setSelectedScopeLabel, selectedScopeLabel, onClose, resetFilters}) {
  const updateFilterParams = (paramsToRemove) => {
    const updatedFilterParams = { ...filterParams }
    paramsToRemove.forEach(param => delete updatedFilterParams[param])
    setFilterParams(updatedFilterParams)
  }

  const handleResetScope = () => {
    updateFilterParams(resetFilters)
    setSelectedScopeLabel('')
    onClose()
  }

  return(
    <div className="flex justify-start w-full gap-2 mb-2 mt-2">
      <FilterIndicator
        filterName={selectedScopeLabel || 'No filter selected'}
        displayFor={filterParams && filterParams != 'current'}
        onClick={handleResetScope}
      />
    </div>
  )
}