import { useTranslation } from "react-i18next"
import useStore from "../../../store"
import PaymentForm from "./PaymentForm"
import AlertMessage from "../../common/AlertMessage"


export default function PaymentSelect() {
  const unit = useStore((state) => state.unit)
  // Payment Types => Payoff:3, Refloor:1, Principal:7
  const paymentType = useStore((state) => state.paymentType)
  const setPaymentType = useStore((state) => state.setPaymentType)
  const position = paymentType == 3 ? 'left-0' : paymentType == 1 ? 'left-1/3' : 'left-2/3'
  const { t } = useTranslation()
  
  return(
    <div>
      <div className="relative w-full">
        <div className="flex justify-evenly mb-4">
          <div 
            className={`text-sm w-1/3 text-center rounded py-0.5 px-2 transition-all duration-300 hover:cursor-pointer ${paymentType == 3 ? 'decoration-fpx-red underline-offset-8 decoration-4' : 'text-grey-300'}`} 
            onClick={() => setPaymentType(3)}
          >
            {t('Payoff')}
          </div>
          <div 
            className={`text-sm w-1/3 text-center rounded py-0.5 px-2 transition-all duration-300 hover:cursor-pointer ${paymentType == 1 ? 'decoration-fpx-red underline-offset-8 decoration-4' : 'text-grey-300'}`} 
            onClick={() => setPaymentType(1)}
          >
            {t('Refloor')}
          </div>
          <div 
            className={`text-sm w-1/3 text-center rounded py-0.5 px-2 transition-all duration-300 hover:cursor-pointer ${paymentType == 7 ? 'decoration-fpx-red underline-offset-8 decoration-4' : 'text-grey-300'}`} 
            onClick={() => setPaymentType(7)}
          >
            {t('Principal')}
          </div>
          <div className={`absolute z-10 bottom-0 ${position} w-1/3 transition-all duration-500`}>
            <div className="flex justify-center w-full">
              <div className="bg-green h-[3px] w-1/2" />
            </div>
          </div> 
        </div>
      </div>
      {
        paymentType == 1 && 
        !unit.attributes['refloor-allowed'] ? 
        <AlertMessage message={"This unit is not currently able to be refloored"} /> :
        <PaymentForm />
      }
    </div>
  )
}