import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react'
import tailwind from '../../../tailwind.config'

const ActionConfirmPopup = ({ isOpen, onClose, onConfirm, cancelRef, title, body, confirmText }) => {
  const red = tailwind.theme.colors["fpx-red"]
  const deepOcean = tailwind.theme.colors["deep-ocean"]

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent bg={deepOcean}>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            {title}
          </AlertDialogHeader>
          <AlertDialogBody>
            {body}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={onClose}
              sx={{
                borderRadius: 'full'
              }}
            >
              Cancel
            </Button>
            <Button
              bg={red}
              onClick={onConfirm}
              ml={3}
              sx={{
                borderRadius: 'full'
              }}
            >
              {confirmText}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default ActionConfirmPopup
