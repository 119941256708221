import { useEffect, useState } from "react"

export default function SwipeAnimation({ children }) {
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    setIsLoaded(true)
  }, [])

  return(
    <div className="flex flex-col w-full h-full relative overflow-y-auto overflow-x-hidden grow lg:min-h-0 items-center">
      <div className={`absolute top-0 ${isLoaded ? 'right-0' : '-right-full'} w-full h-full transition-all duration-300`}>
        {children}
      </div>
    </div>
  )
}