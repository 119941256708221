import { useTranslation } from "react-i18next"
import UnitTableFilterButtons from "../../AccountManager/Dealer/Units/UnitTableFilterButtons"
import useStore from "../../../store"
import NoResultsFound from "../../common/NoResultsFound"

export default function NoCurrentUnits() {
  const { t } = useTranslation()
  const user = useStore((state) => state.user)

  return(
    <div className="w-full bg-grey-900 rounded-md text-center">
      {user?.type === 'AccountManager' ?
        <NoResultsFound/>
        :
        <h3 className="font-header font-semibold text-md text-fpx-red">{t('No Current Units')}</h3>
      }
    </div>
  )
}