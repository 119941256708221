import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
  Link,
  Box,
  useBreakpointValue
} from '@chakra-ui/react'
import useStore from '../../store'
import { useNavigate } from 'react-router-dom'

export default function Breadcrumbs() {
  const breadcrumbTrail = useStore((state) => state.breadcrumbTrail)
  const navigate = useNavigate()
  const setBreadcrumbTrail = useStore((state) => state.setBreadcrumbTrail)
  const displayedCount = useBreakpointValue({ base: 3, md: 5, lg: 5})
  const displayedBreadcrumbs = breadcrumbTrail.slice(-displayedCount)

  const handleBreadcrumbClick = (path, originalIndex) => {
    const newBreadcrumbTrail = breadcrumbTrail.slice(0, originalIndex + 1)
    setBreadcrumbTrail(newBreadcrumbTrail)

    window.history.replaceState(null, '', path)

    navigate(path)
  }

  const renderBreadcrumbItem = (crumb, index) => {
    const originalIndex = breadcrumbTrail.length - displayedBreadcrumbs.length + index

    return (
      <BreadcrumbItem key={index}>
        <BreadcrumbLink as={Link} onClick={() => handleBreadcrumbClick(crumb.path, originalIndex)}>
          <Box
            as='span'
            maxW={'100px'}
            whiteSpace={'nowrap'}
            overflow={'hidden'}
            textOverflow={'ellipsis'}
            display={'inline-block'}
          >
            {crumb.label}
          </Box>
        </BreadcrumbLink>
      </BreadcrumbItem>
    )
  }

  return(
    <Box mb={4} pl={2} width="100%" overflow="hidden">
      <Breadcrumb>
        {displayedBreadcrumbs.map(renderBreadcrumbItem)}
      </Breadcrumb>
    </Box>
  )
}