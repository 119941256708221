import useStore from "../../../store"
import formatter from "../../../constants/currencyFormatter"
import { useTranslation } from "react-i18next"

export default function QuickPayoffDetails() {
  const { t } = useTranslation()
  const payment = useStore((state) => state.payment)

  return(
    <div className="flex flex-col justify-center items-center">
      <div>{`${t('Unit')}: ${payment?.attributes.make} ${payment?.attributes.model}`}</div>
      <div>{`${t('Payment Date')}: ${new Date(payment?.attributes['transfer-date']).toLocaleDateString('en-US', {timeZone: "UTC"})}`}</div>
      <div>{`${t('Payment Amount')}: ${formatter.format(payment?.attributes.amount)}`}</div>
      <div>{`${t('Bank Account')}: ${payment?.attributes['account-name-for-dealer']}`}</div>
    </div>
  )
}