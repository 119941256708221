import { FaChevronRight } from "react-icons/fa"

export default function RecentFloorRequestRow({ yearMake, model, vin, rowAction, rowStatus }) {

  return(
    <div 
      className="flex justify-between w-full py-2 px-2 lg:hover:bg-grey-700 hover:cursor-pointer text-grey-300 text-left items-center border-b-2 border-grey-700"
      onClick={rowAction}
      key={vin}
      data-testid='recent-floor-request-row'
    >
      <div className="font-bold">
        <div className={'text-sm text-grey-300'}>
          {yearMake}
        </div>
        <div className={'text-lg text-grey-100'}>
          {model}
        </div>
        <div className="text-xs text-grey-300">
          {vin}
        </div>
      </div>
      <div className="flex items-center text-sm">
        {rowStatus}
        <FaChevronRight className="text-grey-300" />
      </div>
    </div>
  )
}