import { FaCaretDown, FaCaretUp } from "react-icons/fa"

export default function Collapsible({
  children,
  isCollapsed,
  handleToggle,
  titleStyle,
  hideCarets = false,
  title,
  isSticky = false
}) {

  return(
    <div className={`flex flex-col items-center w-full`}>
      <div className={`${isSticky && 'sticky'} top-0 flex justify-between w-full pr-3 bg-grey-900`} onClick={handleToggle}>
        <div className="flex">
          <div className={titleStyle}>
            {title}
          </div>
        </div>
        <div className="flex items-center">
          {isCollapsed ?
            <FaCaretDown className={`text-lg hover:cursor-pointer ${hideCarets && 'hidden'}`} /> :
            <FaCaretUp className={`text-lg hover:cursor-pointer ${hideCarets && 'hidden'}`} />
          }
        </div>
      </div>
      <div className={`${isCollapsed && 'hidden'} w-full`}>
        {children}
      </div>
    </div>
  )
}