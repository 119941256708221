import useStore from "../../../store"
import { useEffect } from "react"
import DealershipInformation from "./DealershipInformation"
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import tailwind from "../../../../tailwind.config"
import Notes from "../Notes/Notes"
import FilesPage from "./Files/FilesPage"
import MissingItems from "./MissingItems"
import AppNameHeader from "./AppNameHeader"
import Spinner from "../../common/Spinner"
import LotPage from "./Lot/LotPage"
import People from "./People/People"

export default function AppPageBody({ dealerId, appId }) {
  const grey700 = tailwind.theme.colors["grey-700"]
  const grey100 = tailwind.theme.colors["grey-100"]
  const application = useStore((state) => state.application)
  const fetchApplication = useStore((state) => state.fetchApplication)
  const applicationFetching = useStore((state) => state.applicationFetching)
  const fetchPeople = useStore((state) => state.fetchPeople)
  const fetchOtherFloorplans = useStore((state) => state.fetchOtherFloorplans)
  const fetchDealerDocuments = useStore((state) => state.fetchDealerDocuments)
  const fetchLotPhotos = useStore((state) => state.fetchLotPhotos)
  const setIsUpdatingApplication = useStore((state) => state.setIsUpdatingApplication)


  useEffect(() => {
    fetchApplication(appId)
    fetchPeople(dealerId)
    fetchOtherFloorplans(dealerId)
    fetchDealerDocuments(dealerId)
    fetchLotPhotos(dealerId)
    setIsUpdatingApplication(false)
  }, [appId, dealerId])

  return(
    <div>
     {!application || applicationFetching ?
        <div className="flex justify-center pt-3 w-full">
          <Spinner/>
        </div>
        :
        <>
          <AppNameHeader name={application?.attributes['dealership-name']}/>
          <MissingItems/>
          <Tabs variant={'soft-rounded'}>
            <TabList>
              <Tab _selected={{ color: 'white', bg: grey700 }} color={grey100}>Information</Tab>
              <Tab _selected={{ color: 'white', bg: grey700 }} color={grey100}>Files</Tab>
              <Tab _selected={{ color: 'white', bg: grey700 }} color={grey100}>Notes</Tab>
              <Tab _selected={{ color: 'white', bg: grey700 }} color={grey100}>Lot</Tab>
              <Tab _selected={{ color: 'white', bg: grey700 }} color={grey100}>People</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <div data-testid='dealership-info'>
                  <DealershipInformation/>
                </div>
              </TabPanel>
              <TabPanel>
                <div data-testid='files'>
                  <FilesPage dealerId={dealerId}/>
                </div>
              </TabPanel>
              <TabPanel>
                <Notes type='dealers' id={application.attributes['dealer-id']} />
              </TabPanel>
              <TabPanel>
                <div data-testid='lot-page'>
                  <LotPage dealerId={dealerId}/>
                </div>
              </TabPanel>
              <TabPanel>
                <div data-testid='people-page'>
                  <People dealerId={dealerId}/>
                </div>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </>
      }
    </div>
  )

}