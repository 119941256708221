import { FaPlusCircle } from "react-icons/fa"

export default function HeaderWithCreateIcon({selectedItem, showForm, onClick, dataTestId}) {
  return(
    <>
    {(!selectedItem && !showForm) &&
      <div className="w-full flex items-center justify-end bg-grey-900 rounded-t">
        <div
          className="flex items-center hover:cursor-pointer"
          onClick={onClick}
          data-testid={dataTestId}
        >
          <FaPlusCircle size={"24px"} />
        </div>
      </div>
    }
    </>
  )
}