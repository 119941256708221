import useStore from "../../../../store"
import { useEffect, useState } from "react"
import TouchesTable from "./TouchesTable"
import TouchForm from "./TouchForm"
import SlideInInfoPage from "../../../common/SlideInInfoPage"
import TouchInfo from "./TouchInfo"
import { FaPlusCircle } from "react-icons/fa"
import HeaderWithCreateIcon from "../../../common/HeaderWithCreateIcon"

export default function Touches({ dealerId }) {
  const fetchTouches = useStore((state) => state.fetchTouches)
  const [showTouchForm, setShowTouchForm] = useState(false)
  const [selectedTouch, setSelectedTouch] = useState(null)

  useEffect(() => {
    fetchTouches(dealerId)
  }, [])

  const onBack = () => {
    setShowTouchForm(false)
    setSelectedTouch(null)
  }

  const handleClick = () => {
    setShowTouchForm(true)
  }

  return(
    <div className="flex flex-col w-full bg-grey-900 p-2">
      <HeaderWithCreateIcon
        selectedItem={selectedTouch}
        onClick={handleClick}
        title={'Touches'}
        dataTestId={'create-touch'}
        showForm={showTouchForm}
      />
      {showTouchForm || selectedTouch ?
        <SlideInInfoPage onBack={onBack}>
          {showTouchForm ?
            <TouchForm dealerId={dealerId} setShowTouchForm={setShowTouchForm} />
            :
            <TouchInfo dealerId={dealerId} setSelectedTouch={setSelectedTouch}/>
          }
        </SlideInInfoPage>
        :
        <TouchesTable setShowTouchForm={setShowTouchForm} setSelectedTouch={setSelectedTouch} dealerId={dealerId} />
      }
    </div>
  )
}