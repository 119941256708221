import useStore from "../../../../store"
import FormattedAddress from "../../../common/FormattedAddress"

export default function Address() {
  const application = useStore((state) => state.application)

  return(
    <div>
      <div className="w-full text-left bg-grey-900 rounded-t">
        <h3 className="font-header font-semibold text-md text-green">{'Dealership Address'}</h3>
      </div>
      <div className="w-full text-left p-2 bg-grey-900 rounded" data-testid="dealership-address">
        <FormattedAddress
          address1={application.attributes["d-address1"]}
          address2={application.attributes["d-address2"]}
          city={application.attributes["d-city"]}
          state={application.attributes["d-state"]}
          zip={application.attributes["d-zip"]}
        />
      </div>
    </div>
  )
}