import { motion } from "framer-motion"
import InfoItem from "../../../common/InfoItem"
import { DateTime } from "luxon"
import { Image } from "@chakra-ui/react"
import useStore from "../../../../store"
import { useEffect, useState } from "react"
import EditableInfoItem from "../../../common/EditableInfoItem"
import Spinner from "../../../common/Spinner"
import { FaEdit } from "react-icons/fa"
import EditDealerInfoButtons from "../../Dealer/EditDealerInfoButtons"
import { Select } from "@chakra-ui/react"
import { FaRegTrashAlt } from "react-icons/fa"
import { useRef } from "react"
import ActionConfirmPopup from "../../../common/ActionConfirmPopup"
import { useDisclosure } from "@chakra-ui/react"
import BackButton from "../../../common/BackButton"

export default function FileDetails({ onBack, fileId, dealerId, setSelectedFile}) {
  const fetchDealerFile = useStore((state) => state.fetchDealerFile)
  const dealerFileFetching = useStore((state) => state.dealerFileFetching)
  const dealerFile = useStore((state) => state.dealerFile)
  const updateDealerFile = useStore((state) => state.updateDealerFile)
  const [isEditingAttributes, setIsEditingAttributes] = useState(false)
  const [inputValues, setInputValues] = useState({})
  const [isUpdatingFile, setIsUpdatingFile] = useState(false)
  const [selectedFolder, setSelectedFolder] = useState("")
  const dealerFolders = useStore((state) => state.dealerFolders)
  const deleteDealerFile = useStore((state) => state.deleteDealerFile)
  const fetchDealerDocuments = useStore((state) => state.fetchDealerDocuments)
  const fetchLotPhotos = useStore((state) => state.fetchLotPhotos)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()

  useEffect(() => {
    fetchDealerFile(fileId, dealerId)
  }, [])

  useEffect(() => {
    if (dealerFile) {
      setInputValues({
        name: dealerFile.attributes.name
      })
      setSelectedFolder(dealerFile.attributes.folder.data?.id)
    }
  }, [dealerFile])

  const handleEditAttributes = () => {
    setIsEditingAttributes(true)
  }

  const handleSubmit = async () => {
    setIsUpdatingFile(true)
    await updateDealerFile(dealerFile.id, dealerId, {
      name: inputValues.name,
      dealer_folder_id: selectedFolder,
    })
    setIsEditingAttributes(false)
    setIsUpdatingFile(false)
  }

  const handleFolderChange = (event) => {
    setSelectedFolder(event.target.value)
  }

  const handleDeleteFile = () => {
    deleteDealerFile(dealerFile.id, dealerId)
    setSelectedFile(null)
    fetchDealerDocuments(dealerId)
    fetchLotPhotos(dealerId)
  }

  return(
    <div className="overflow-x-hidden">
      <motion.div
        initial={{ x: "100%" }}
        animate={{ x: 0 }}
        transition={{ type: 'tween', duration: 0.2 }}
      >
        <BackButton onClick={onBack} />

        <div className="w-full text-left bg-grey-900 rounded-t">
          <h3 className="font-header font-semibold text-md text-green">{`File`}</h3>
        </div>
        { !dealerFileFetching ?
          <div className="ml-1">
            <div className="flex justify-end gap-4">
            <FaRegTrashAlt
              onClick={onOpen}
              className="hover:cursor-pointer"
              data-testid='delete-icon'
            />
            {!isEditingAttributes &&
                  <FaEdit
                    className="hover:cursor-pointer"
                    onClick={() => handleEditAttributes()}
                    data-testid="edit-dealer-info"
                  />
                }
          </div>
            <EditableInfoItem
              data={dealerFile.attributes.name}
              title="File Name"
              isEditing={isEditingAttributes}
              inputValue={inputValues.name}
              onInputChange={(e) => setInputValues({ ...inputValues, name: e.target.value })}
            />
            <InfoItem title="Updated At">
              {DateTime.fromISO(dealerFile.attributes["created-at"]).toLocaleString()}
            </InfoItem>
            {isEditingAttributes ? (
              <Select key={selectedFolder} value={selectedFolder} onChange={handleFolderChange}>
                {dealerFolders.map((folder) => (
                    <option value={folder.id}>{folder.attributes['directory-name']}</option>
                ))}
              </Select>
              )
            :
              (
              <InfoItem title="Folder">
                {dealerFile.attributes.folder.data?.attributes['directory-name'] ?? 'Root Folder'}
              </InfoItem>
            )}
            {isEditingAttributes &&
              <div className="m-4">
              <EditDealerInfoButtons
                setIsEditing={setIsEditingAttributes}
                isUpdating={isUpdatingFile}
                onSubmit={handleSubmit}
              />
              </div>
            }
              <div className="flex justify-center mt-4">
                <Image
                  src={dealerFile.attributes.url}
                  alt={dealerFile.attributes.name}
                  maxW={'500px'}
                  maxH={'800px'}
                />
              </div>
          </div>
          :
          <Spinner/>
        }
        <ActionConfirmPopup
          isOpen={isOpen}
          onClose={onClose}
          onConfirm={() => {
            handleDeleteFile()
            onClose()
            onBack()
          }}
          cancelRef={cancelRef}
          title='Remove File'
          body="Are you sure? You can't undo this action afterwards."
          confirmText='Delete'
        />
      </motion.div>
    </div>
  )
}