import useStore from "../../../store"
import { useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { isAlreadyFloored, isNAPInProgress } from "../../../utilities/floorRequestConditions"
import { SolidGreenButton, NeutralButton } from "../../common/Buttons"
import SwipeAnimation from "../../common/SwipeAnimation"

export default function BBSearchResult() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const blackbook = useStore((state) => state.blackbook)
  const NAPBlackbook = useStore((state) => state.NAPBlackbook)
  const setNAPBlackbook = useStore((state) => state.setNAPBlackbook)
  const resetNAPSlice = useStore((state) => state.resetNAPSlice)
  const units = useStore((state) => state.units)
  const [isLoaded, setIsLoaded] = useState(false)
  const [bbUnitDetails, setBBUnitDetails] = useState(null)

  useEffect(() => {
    setBBUnitDetails(createBBUnitDetails())
  }, [])

  const handleFloorUnit = () => {
    if (!NAPBlackbook || blackbook.vin !== NAPBlackbook.vin) {
      resetNAPSlice()
      setNAPBlackbook(blackbook)
    }
    navigate('/nap')
  }

  const createBBUnitDetails = () => {
    if (isAlreadyFloored(blackbook, units)) {
      return({
        text: t("This unit has already been floored"),
        buttonText: t("View Unit"),
        onClick: () => navigate(`/unit/${isAlreadyFloored(blackbook, units).id}`),
        cancelButtonText: t('Go Back'),
      })
    } else if (isNAPInProgress(blackbook, NAPBlackbook)) {
      return({
        text: t("There is already a floor request in progress for this unit"),
        buttonText: t("Continue Floor Request"),
        onClick: handleFloorUnit,
        cancelButtonText: t('Go Back'),
      })
    } else if (NAPBlackbook && NAPBlackbook.vin !== null) {
      return({
        text: t('blackbook.inProgress', { unitName: `${NAPBlackbook.model_year} ${NAPBlackbook.make} ${NAPBlackbook.model}` }),
        buttonText: t("Yes"),
        onClick: handleFloorUnit,
        cancelButtonText: t('No'),
      })
    } else {
      return({
        text: t("Start a floor request for this vehicle"),
        buttonText: t("Floor Unit"),
        onClick: handleFloorUnit,
        cancelButtonText: t('Go Back'),
      })
    }
  }

  useEffect(() => {
    setIsLoaded(true)
  }, [])

  return(
    <SwipeAnimation>
      <div className="absolute flex flex-col justify-evenly items-center w-full h-full font-bold mb-4 lg:left-1/4 lg:w-1/2">
        <div className="flex flex-col justify-center items-center">
          <div className="text-2xl text-grey-300">
            {`${blackbook.model_year} ${blackbook.make}`}
          </div>
          <div className="text-4xl text-grey-100">
            {`${blackbook.model} ${blackbook.series}`}
          </div>
          <div className="text-grey-300">
            {blackbook.vin}
          </div>
        </div>

        {bbUnitDetails &&
          <>
            <div className="flex flex-col items-center px-2 py-3 w-full">
              <div className="bg-grey-900 py-3 px-5 mb-5 text-center rounded">
                {bbUnitDetails.text}
              </div>
            </div>
            <div className="flex flex-col items-center px-2 py-3 w-full">
              <SolidGreenButton
                text={bbUnitDetails.buttonText}
                onClick={bbUnitDetails.onClick}
                style="w-2/3"
              />
              <NeutralButton
                text={bbUnitDetails.cancelButtonText}
                onClick={() => navigate('/blackbook')}
                style="w-2/3 mt-4"
              />
            </div>
          </>
        }
      </div>
    </SwipeAnimation>
  )
}