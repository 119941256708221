import useStore from "../../../../store"
import tailwind from "../../../../../tailwind.config"
import { MdOutlineStar } from "react-icons/md"
import { MdOutlineStarBorder } from "react-icons/md"

export default function SaveDealerIcon({dealer}) {
  const savedDealers = useStore((state) => state.savedDealers)
  const green = tailwind.theme.colors["green"]
  const createListDealer = useStore((state) => state.createListDealer)
  const isDealerSaved = savedDealers.some((savedDealer) => savedDealer.id === dealer.id)
  const removeListDealer = useStore((state) => state.removeListDealer)

  const handleSaveToList = (event) => {
    event.stopPropagation()
    createListDealer(dealer.id)
  }

  const handleRemoveFromList = (event) => {
    event.stopPropagation()
    removeListDealer(dealer.id)
  }

  return(
    <>
      {isDealerSaved ?
        <MdOutlineStar size={24} className="mr-2" data-testid="checkmark-icon" color={green} onClick={handleRemoveFromList}/>
        :
        <MdOutlineStarBorder size={24} className="mr-2" data-testid="save-icon" onClick={handleSaveToList} />
      }
    </>
  )
}