import useStore from "../../../store"
import TableSortingByHeader from "../../common/TableSortingByHeader"
import { useIsMobile } from "../../../hooks/useIsMobile"
import HeaderWithoutSorting from "../../common/HeaderWithoutSorting"
import AmSearchBar from "../../common/AmSearchBar"

export default function BusinessDevTableHeader({ searchTerm, setSearchTerm, param }) {
  const businessDevTableFilterAttribute = useStore((state) => state.businessDevTableFilterAttribute)
  const businessDevTableFilterDirection = useStore((state) => state.businessDevTableFilterDirection)
  const setBusinessDevTableFilterAttribute = useStore((state) => state.setBusinessDevTableFilterAttribute)
  const setBusinessDevTableFilterDirection = useStore((state) => state.setBusinessDevTableFilterDirection)
  const isMobile = useIsMobile()

  const headerHash = {
    'status=2000': [
      {
        title: 'Dealer',
        attribute: 'name',
        sortable: true
      },
      {
        title: 'Last Touch',
        attribute: 'last_touch',
        sortable: true
      },
      {
        title: '# of Touches',
        attribute: 'num_touches',
        sortable: true
      }
    ],
    'scope=awaiting_first_floor': [
      {
        title: 'Dealer Name',
        attribute: 'dealership_name',
        sortable: true
      },
      {
        title: 'City',
        attribute: 'city',
        sortable: true
      },
      {
        title: 'Status',
        sortable: false
      }
    ],
    'scope=no_stips': [
      {
        title: 'Dealer Name',
        attribute: 'dealership_name',
        sortable: true
      },
      {
        title: 'Assigned',
        attribute: 'assigned_to',
        sortable: true
      },
      {
        title: 'Applied',
        attribute: 'created_at',
        sortable: true
      }
    ],
    'scope=pending_approval': [
      {
        title: 'Dealer Name',
        attribute: 'dealership_name',
        sortable: true
      },
      {
        title: 'Assigned',
        sortable: false
      },
      {
        title: 'Applied',
        sortable: false
      }
    ],
    'scope=first_floored_this_month': [
      {
        title: 'Dealer Name',
        attribute: 'dealership_name',
        sortable: true
      },
      {
        title: '# Floored',
        attribute: 'unit_count',
        sortable: true
      },
      {
        title: 'City',
        attribute: 'city',
        sortable: true
      }
    ]

  }

  return(
    <thead className="sticky top-[72px] bg-grey-900 w-full">
      <tr>
        {
          headerHash[param]?.map((table, i) => {
            if (table.sortable) {
              return(
                <TableSortingByHeader
                  width={isMobile ? 'w-20' : 'w-24'}
                  filterAttribute={businessDevTableFilterAttribute}
                  filterDirection={businessDevTableFilterDirection}
                  setFilterAttribute={setBusinessDevTableFilterAttribute}
                  setFilterDirection={setBusinessDevTableFilterDirection}
                  attribute={table.attribute}
                  title={table.title}
                  titleStyles={i > 0 ? 'justify-center' : ''}
                />
              )
            } else {
              return(
                <HeaderWithoutSorting
                  width={isMobile ? 'w-20' : 'w-24'}
                  title={table.title}
                  attribute={table.attribute}
                />
              )
            }
          })
        }
      </tr>
    </thead>
  )
}