import useStore from "../../../../store"
import InfoItem from "../../../common/InfoItem"
import { DateTime } from "luxon"
import Spinner from "../../../common/Spinner"

export default function TouchInfo({dealerId}) {
  const touch = useStore((state) => state.touch)
  const date = DateTime.fromISO(touch?.attributes['touch-on'])
  const touchFetching = useStore((state) => state.touchFetching)

  if (!touch || touchFetching) {
    return <Spinner />
  }

  return(
    <div>
      <div className="w-full text-left bg-grey-900 rounded-t">
        <h3 className="font-header font-semibold text-md text-green">{`Touch by ${touch.attributes['created-by']} - ${date.toLocaleString()}`}</h3>
      </div>
      <div className="ml-1">
        <InfoItem title="Type">
          {touch.attributes.type}
        </InfoItem>
        <InfoItem title="Purpose">
          {touch.attributes.purpose}
        </InfoItem>
        <InfoItem title="Note">
          {touch.attributes.note}
        </InfoItem>
      </div>
    </div>
  )
}