import { Payoff, Refloor } from "../../common/PayoffRefloorPills"
import HighlightMatch from "../../../utilities/HighlightMatch"
import formatter from "../../../constants/currencyFormatter"
import useStore from "../../../store"

export default function UnitPayoffRefloor({ unit, filter }) {
  const setPaymentType = useStore((state) => state.setPaymentType)

  return(
    <div className="flex justify-evenly items-center w-full">
      <div>
        <Payoff />
        <div onClick={() => setPaymentType(1) }>
          <Refloor />
        </div>
      </div>
      <div className="text-right text-grey-100">
        <div><HighlightMatch str={formatter.format(unit.attributes.payoff)} match={filter} /></div>
        <div><HighlightMatch str={formatter.format(unit.attributes.curtailment)} match={filter} /></div>
      </div>
    </div>
  )
}