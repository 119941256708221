import useStore from "../../../store"
import RiskRow from "./RiskRow"
import Spinner from "../../common/Spinner"
import { useEffect } from "react"
import DashboardUnitInfo from "../../Dealer/UnitPage/DashboardUnitInfo"
import { useIsMobile } from "../../../hooks/useIsMobile"

export default function RiskPage() {
  const riskMitigation = useStore((state) => state.riskMitigation)
  const collapsedSections = useStore((state) => state.collapsedSections)
  const toggleCollapsedSection = useStore((state) => state.toggleCollapsedSection)
  const resetCollapsedSections = useStore((state) => state.resetCollapsedSections)
  const dashboardUnitId = useStore((state) => state.dashboardUnitId)
  const isMobile = useIsMobile()
  const resetUnitsAsAmSlice = useStore((state) => state.resetUnitsAsAmSlice)
  const fieldAudits = useStore((state) => state.fieldAudits)

  const handleToggle = (section) => {
    toggleCollapsedSection(section)
  }

  useEffect(() => {
    resetUnitsAsAmSlice()
  },[])

  useEffect(() => {
    return() => resetCollapsedSections()
  },[])

  return(
    <div className="flex grow justify-center w-full overflow-y-auto transition-all duration-300">
      <div className="flex flex-col items-center overflow-y-auto w-full py-2 w-full lg:w-1/2 lg:pl-2">
        <div className="w-full text-left p-2 bg-grey-900 rounded-t">
          <h3 className="font-header font-semibold text-md text-green">{'Risk Mitigation'}</h3>
        </div>
        {riskMitigation ?
          <div className="w-full pb-1 rounded-md bg-grey-900">
            <RiskRow
              riskMetric={riskMitigation.dealers_with_repos.data}
              title={"Dealers With Repos"}
              isCollapsed={collapsedSections.dealersWithRepos}
              handleToggle={() => handleToggle('dealersWithRepos')}
            />
            <RiskRow
              riskMetric={riskMitigation.past_due_dealer_reviews.data}
              title={"Dealers With Review Due"}
              isCollapsed={collapsedSections.dealersWithReviews}
              handleToggle={() => handleToggle('dealersWithReviews')}
            />
            <RiskRow
              riskMetric={riskMitigation.units_past_due.data}
              title={"Units Past Due"}
              isCollapsed={collapsedSections.unitsPastDue}
              handleToggle={() => handleToggle('unitsPastDue')}
            />
            <RiskRow
              riskMetric={riskMitigation.past_due_fa.data}
              title={"Field Audits Past Due"}
              isCollapsed={collapsedSections.dealersWithFieldAudits}
              handleToggle={() => handleToggle('dealersWithFieldAudits')}
            />
            <RiskRow
              riskMetric={riskMitigation.sold_units.data}
              title={"Sold Units"}
              isCollapsed={collapsedSections.soldUnits}
              handleToggle={() => handleToggle('soldUnits')}
            />
            <RiskRow
              riskMetric={riskMitigation.units_untouched_over_40_days.data}
              title={"Units Untouched Over 40 Days"}
              isCollapsed={collapsedSections.untouchedFortyDays}
              handleToggle={() => handleToggle('untouchedFortyDays')}
            />
            <RiskRow
              riskMetric={fieldAudits}
              title={"Unreconciled Field Audits"}
              isCollapsed={collapsedSections.unreconciledFieldAudits}
              handleToggle={() => handleToggle('unreconciledFieldAudits')}
            />
          </div>
        :
          <Spinner/>
        }
      </div>
      <div className={`hidden lg:block transition-all duration-500 ${dashboardUnitId ? 'w-1/2 opacity-100' : 'w-0 opacity-0'}`}>
        {dashboardUnitId && !isMobile &&
          <div className="flex flex-col w-full hidden lg:block lg:px-2 lg:w-full lg:h-[85vh] rounded">
            <DashboardUnitInfo unitId={dashboardUnitId} />
          </div>
        }
      </div>
    </div>
  )
}