import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react'
import useStore from "../../../store"
import tailwind from '../../../../tailwind.config'
import { BsDot } from "react-icons/bs"

export default function MissingItems() {
  const application = useStore((state) => state.application)
  const grey100 = tailwind.theme.colors["grey-100"]
  const red = tailwind.theme.colors["fpx-red"]
  const missingItems = Array.isArray(application?.attributes['missing-items'])
    ? application.attributes['missing-items']
    : []

  return(
    <div className='m-2 pb-2'>
      {application?.attributes['missing-items'].length > 0 &&
        <Alert status='error' bg={red}>
          <AlertIcon/>
          <AlertTitle color={grey100}>Missing Items</AlertTitle>
          <AlertDescription color={grey100}>
          <div className='flex items-center flex-wrap'>
              {missingItems.map((missingItem, index) => (
                <div key={index} className="flex items-center mr-2">
                  <BsDot />
                  {missingItem}
                </div>
              ))}
            </div>
          </AlertDescription>
        </Alert>
      }
    </div>
  )
}