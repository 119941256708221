import { useTranslation } from "react-i18next"
import { isComingDue, isPastDue, soldUnitDueDate, dueOn } from "../../../utilities/unitUtilities"
import UnitWarningContainer from "./UnitWarningContainer"

export default function UnitWarning({ unit }) {
  const { t } = useTranslation()

  if (unit.attributes['pending-payment-type']) {
    return null
  } else if (isPastDue(unit)) {
    return(
      <UnitWarningContainer colorStyles="text-fpx-red border-fpx-red">
        {t("This unit is past due.")}
      </UnitWarningContainer>
    )
  } else if (unit.attributes.sold) {
    return(
      <UnitWarningContainer colorStyles="text-yellow border-yellow">
        <span>{t("This unit has been marked as sold.")}</span>
        {unit.attributes['sold-on'] &&
          <span>
            {t("Please schedule a payment before")}
            {` ${soldUnitDueDate(unit)}`}
          </span>
        }
      </UnitWarningContainer>
    )
  } else if (isComingDue(unit)) {
    return(
      <UnitWarningContainer colorStyles="text-yellow border-yellow">
        {t("This unit is due on")}
        {` ${dueOn(unit)}`}
      </UnitWarningContainer>
    )
  }
}