import { DateTime } from "luxon"
import { isStringOnlyWhitespace } from "../../../utilities/stringUtilities"
import HighlightMatch from "../../../utilities/HighlightMatch"
import { HappyPill, WarningPill } from "../../common/PayoffRefloorPills"

export default function NotesRow({ note, onClick, filter }) {
  const date = DateTime.fromISO(note.attributes['created-at'])
  const isATouch = note.attributes.subject === "Note From Touch" || note.attributes.touch
  const reminderDate = DateTime.fromISO(note.attributes.touch?.data.attributes.reminder?.data.attributes['due-on']).toLocaleString()
  const hasReminder = note.attributes.touch?.data.attributes.reminder?.data

  const combinedString = `${date.toFormat('M/d/yy')}` +
  (note.attributes['created-by'] ? ` | ${note.attributes['created-by']}` : '') +
  (note.attributes.subject ? ` | ${note.attributes.subject}` : '')

  return(
    <div className={`flex w-full py-2 px-2 lg:hover:bg-grey-700 hover:cursor-pointer text-grey-300 text-left items-center border-b-2 border-grey-700`} onClick={onClick}>
      <div className="flex w-full" data-testid="note-row">
        <div className="flex flex-col w-2/3 truncate">
          <div className="text-sm flex truncate text-ellipsis">
            <HighlightMatch str={combinedString} match={filter} truncate={true} />
          </div>
          <div className="w-full truncate text-ellipsis text-grey-100">
            <HighlightMatch str={note.attributes.note && !isStringOnlyWhitespace(note.attributes.note) ? note.attributes.note : note.attributes.subject} match={filter} truncate={true} />
          </div>
          {isATouch &&
            <div className="flex flex-col items-start w-full text-left ">
              {note.attributes.touch &&
                `${note.attributes.touch?.data.attributes.purpose}, ${note.attributes.touch?.data.attributes.response}`
              }
            </div>
          }
          {hasReminder &&
            <div className="flex flex-col items-start w-full text-left text-xs text-yellow">
              {`Reminder Due ${reminderDate}`}
            </div>
          }
        </div>
        <div className="w-1/3 flex flex-col items-end ml-2 justify-center">
          {isATouch && <HappyPill text={'Touch'}/>}
          {hasReminder && <WarningPill text={'Reminder'}/>}
        </div>
      </div>
    </div>
  )
}