import useStore from "../../../store"
import InfoItem from "../../common/InfoItem"
import { parsePhoneNumber } from "libphonenumber-js"
import People from "./People/People"
import Address from "./Dealership/Address"
import OtherFloorplans from "./Dealership/OtherFloorplans"
import Uccs from "./Dealership/Uccs"
import SecretaryOfStateFilings from "./Dealership/SecretaryOfStateFilings"
import MissingItems from "./MissingItems"

export default function DealershipInformation() {
  const application = useStore((state) => state.application)
  const appPhoneNumber = parsePhoneNumber(application.attributes.phone, 'US')
  const otherFloorplans = useStore((state) => state.otherFloorplans)

  return(
    <div>
      <div className="w-full text-left pl-2 bg-grey-900 rounded-t">
        <h3 className="font-header font-semibold text-md text-green">{'Dealership Information'}</h3>
      </div>
      <div className="grid grid-cols-2 lg:grid-cols-3 w-full text-left p-2 bg-grey-900 rounded" data-testid="dealership-info">
        <InfoItem title={"Owner's Name"}>
          {application.attributes["o1-name"]}
        </InfoItem>
        <InfoItem title={"Owner's Title"}>
          {application.attributes["o1-title"]}
        </InfoItem>
        <InfoItem title={'Entity Name'}>
          {application.attributes["dealership-name"]}
        </InfoItem>
        <InfoItem title={'Doing Business As'}>
          {application.attributes.dba}
        </InfoItem>
        <InfoItem title={'Corporation Type'}>
          {application.attributes["corp-type"]}
        </InfoItem>
        <InfoItem title={'Phone'}>
          {application.attributes.phone !==null ? appPhoneNumber.formatNational() : 'No Phone'}
        </InfoItem>
      </div>
      <div className="w-full text-left p-2 bg-grey-900 rounded">
        <InfoItem title={'Email Address'}>
          {application.attributes.email}
        </InfoItem>
      </div>
      <div className="grid lg:grid-cols-2 gap-2 p-2">
        <Address />
        <SecretaryOfStateFilings/>
      </div>
      <div className="grid lg:grid-cols-2 gap-2 p-2">
        <Uccs/>
        <OtherFloorplans />
      </div>
    </div>
  )
}