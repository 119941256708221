import useStore from "../../../store"
import { useEffect } from "react"
import Spinner from "../../common/Spinner"
import NotesBody from "./NotesBody"
import NoNotesFound from "./NoNotesFound"
import AmSearchBar from "../../common/AmSearchBar"
import { useDisclosure } from "@chakra-ui/react"
import FilterByDrawer from "../../common/FilterByDrawer"
import FilterButtons from "../Dealers/DealerTableFiltering/FilterButtons"
import { useState } from "react"
import NotesFilterOptions from "./NotesFilterOptions"
import NotesTableFilterIndicators from "./NotesTableFilterIndicators"

// Notes type is either "dealers", "units", or "audits"
export default function Notes({ type, id }) {
  const fetchNotes = useStore((state) => state.fetchNotes)
  const notes = useStore((state) => state.notes)
  const notesPage = useStore((state) => state.notesPage)
  const dealer = useStore((state) => state.dealer)
  const isDealerFromNotes = notes?.some((note) => note.attributes.dealer.id == dealer.id)
  const isFetchingNotes = useStore((state) => state.isFetchingNotes)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const setNotesFilterParams = useStore((state) => state.setNotesFilterParams)
  const notesFilterParams = useStore((state) => state.notesFilterParams)
  const [searchTerm, setSearchTerm] = useState('')
  const notesResultsCount = useStore((state) => state.notesPaginationData.dataTotal)
  const setSelectedNotesScopeLabel = useStore((state) => state.setSelectedNotesScopeLabel)
  const [showNoteForm, setShowNoteForm] = useState(false)

  useEffect(() => {
    fetchNotes(dealer?.id, notesFilterParams)
  }, [dealer, notesPage, notesFilterParams])

  const renderNotes = () => {
    if(notes && isDealerFromNotes) {
      return <NotesBody type={type} id={id} searchTerm={searchTerm} showNoteForm={showNoteForm} setShowNoteForm={setShowNoteForm}/>
    } else if (notes?.length === 0) {
      return <NoNotesFound/>
    } else {
      return (
        <div className="flex justify-center w-full">
          <Spinner />
        </div>
      )
    }
  }

  const handleResetFilter = () => {
    setSearchTerm('')
    setNotesFilterParams({})
    fetchNotes(dealer.id, '')
    setSelectedNotesScopeLabel(null)
    onClose()
  }

  const handleSearch = (value) => {
    setNotesFilterParams({
      ...notesFilterParams,
      'filter[search]': value
    })
  }

  return(
    <div className="h-full">
      <div className="relative flex flex-col bg-grey-900 rounded w-full pr-2 pl-1">
        {!showNoteForm &&
          <AmSearchBar
            handleSearch={handleSearch}
            handleReset={handleResetFilter}
            onOpen={onOpen}
            showFilterIcon={true}
            dataFetching={isFetchingNotes}
            searchBarValue={searchTerm}
            setSearchBarValue={setSearchTerm}
          />
          }
        <NotesTableFilterIndicators/>
        <FilterByDrawer
          isOpen={isOpen}
          onClose={onClose}
          title="Filter Notes"
          bodyContent={
            <>
              <NotesFilterOptions/>
            </>
          }
          footerContent={
            <FilterButtons
              handleResetFilter={handleResetFilter}
              handleSubmit={() => onClose()}
              resultsCount={notesResultsCount}
              resultsFetching={isFetchingNotes}
            />
          }
        />
        {renderNotes()}
      </div>
    </div>
  )
}