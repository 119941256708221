import HighlightMatch from "../../../utilities/HighlightMatch"
import dealerColor from "../../../utilities/AccountManagers/dealerColor"
import dealerCityState from "../../../utilities/dealerCityState"


export default function DealerName({ dealer, filter = null, businessDevTableData=null }) {
  const color = dealerColor(dealer)

  const dealerTypeStatusString = (dealer) => {
    if (dealer.attributes.type === 'Salvage') {
      return(
        `${dealer.attributes.type} ${dealer.attributes['active-status']}`
      )
    } else {
      return(
        `${dealer.attributes['active-status']}`
      )
    }
  }

  return(
    <div className="w-full font-bold overflow-hidden text-ellipsis whitespace-nowrap">
    {businessDevTableData ?
      <div className={`w-full text-sm ${color} flex gap-2`}>
        <HighlightMatch str={dealer.attributes['primary-phone']} match={filter} truncate={true}/>
      </div>
      :
      <div className={`w-full text-sm ${color} flex gap-2`}>
        <HighlightMatch str={dealerTypeStatusString(dealer)} match={filter} truncate={true}/>
      </div>
    }
      <div className={'w-full text-lg text-grey-100'} data-testid='dealer-name'>
        <HighlightMatch str={dealer.attributes.name ? (dealer.attributes.name) : 'No Name'} match={filter} truncate={true}/>
      </div>
      <div className={'w-full text-xs text-grey-100'}>
        <HighlightMatch str={dealerCityState(dealer) ? dealerCityState(dealer) : 'No Address'} match={filter} truncate={true}/>
      </div>
    </div>
  )
}