const accountTypes = [
  {
    name: 'Checking',
    id: 1
  },
  {
    name: 'Savings',
    id: 2
  },
  {
    name: 'MoneyMarket',
    id: 3 
  }
]

export default accountTypes