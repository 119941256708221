import Webcam from 'react-webcam'
import { useState, useEffect, useRef } from 'react'
import { MdPhotoCamera } from "react-icons/md"
import { IoMdClose } from "react-icons/io"
import { useMediaQuery } from 'react-responsive'
import { CameraBounds } from './CameraAnimations'

export default function Camera ({
  capture,
  close,
  instructions = null,
  scannerRef = null,
  onMediaCallback = null,
  bounds = false
}) {
  const [needsPermissions, setNeedsPermissions] = useState(false)
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const viewportRef = useRef(null)
  const [videoConstraints, setVideoConstraints] = useState(null)

  useEffect(() => {
    const { offsetWidth, offsetHeight } = viewportRef.current
    const aspectRatio = offsetWidth / offsetHeight
    const reverseAspectRatio = offsetHeight / offsetWidth

    setVideoConstraints({
      width: isPortrait ? offsetHeight : offsetWidth,
      height: isPortrait ? offsetWidth : offsetHeight,
      aspectRatio: isPortrait ? reverseAspectRatio : aspectRatio,
      facingMode: "environment",
      focusMode: 'continuous',
    })
  }, [isPortrait])

  return(
    <div
      className="flex flex-col h-dvh w-screen bg-black justify-center items-center"
      ref={viewportRef}
    >
      {bounds && <CameraBounds />}
      <div
        className="relative h-full w-full rounded"
      >
        <div className="flex justify-center h-full w-full rounded">
          <IoMdClose
            className="absolute text-3xl right-4 top-6 z-50 hover:cursor-pointer"
            onClick={() => close()}
          />
          {instructions &&
            <div className="absolute top-16 text-lg text-center bg-grey-900 bg-opacity-70 px-4">
              {instructions}
            </div>
          }

          {needsPermissions &&
            <div className="absolute top-16 text-lg text-center bg-grey-900 px-4 rounded">
              Camera was not able to be accessed, most likely because permissions were denied.
              Make sure your browser has permissions to use the camera and then try to open
              the camera again. If you aren't being prompted for camera permissions, try closing
              the app and restarting it.
            </div>
          }

          {videoConstraints &&
            <Webcam
              audio={false}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
              ref={scannerRef}
              onUserMediaError={(e) => {
                setNeedsPermissions(true)
              }}
              onUserMedia={(mediastream) => {
                setNeedsPermissions(false)
                onMediaCallback && onMediaCallback()
              }}
              className="rounded"
            >
              {({ getScreenshot }) => (
                <button
                  className={`${!capture && 'hidden'} absolute p-2 ${isPortrait ? 'bottom-20' : 'bottom-10 right-4'} border border-4 rounded-full ${needsPermissions && 'text-grey'}`}
                  onClick={() => {
                    capture(getScreenshot())
                  }}
                  disabled={needsPermissions}
                >
                  <MdPhotoCamera size={45} />
                </button>
              )}
            </Webcam>
          }
        </div>
      </div>
    </div>
  )
}