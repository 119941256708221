import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  TableContainer,
} from '@chakra-ui/react'
import TouchRow from "./TouchRow"
import useStore from '../../../../store'
import tailwind from "../../../../../tailwind.config"
import { FaPlusCircle } from "react-icons/fa"


export default function TouchesTable({ setShowTouchForm, setSelectedTouch, dealerId }) {
  const touches = useStore((state) => state.touches)
  const grey300 = tailwind.theme.colors["grey-300"]
  const fetchTouch = useStore((state) => state.fetchTouch)

  const handleClick = (touch) => {
    setSelectedTouch(touch)
    fetchTouch(dealerId, touch.id)
  }

  return(
    <>
      <TableContainer>
        <Table variant='simple'>
          <Thead>
            <Tr>
              <Th color={grey300}>Type</Th>
              <Th color={grey300}>Date</Th>
              <Th color={grey300}>Touched By</Th>
            </Tr>
          </Thead>
          <Tbody>
            {touches && touches.map((touch) => (
              <TouchRow key={touch.id} touch={touch} onClick={() => handleClick(touch)} />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  )
}