import { DateTime } from "luxon"
import { Td, Tr } from "@chakra-ui/react"
import { useIsMobile } from "../../../hooks/useIsMobile"

export default function AuditRow({item}) {
  const isMobile = useIsMobile()

  return(
    <>
      <Tr className="hover:cursor-pointer lg:hover:bg-grey-700 ">
        <Td className="w-1/4 overflow-hidden text-ellipsis whitespace-nowrap max-w-xs">{item?.attributes.dealer.lotname}</Td>
        <Td className="w-1/4 overflow-hidden text-ellipsis whitespace-nowrap max-w-xs">{item?.attributes['date-completed'] ? DateTime.fromISO(item?.attributes['date-completed']).toLocaleString() : 'No Date'}</Td>
        {!isMobile && (
          <Td className="w-1/4 overflow-hidden text-ellipsis whitespace-nowrap max-w-xs">
            {item?.attributes.status}
          </Td>
        )}
        <Td className="w-1/4 overflow-hidden text-ellipsis whitespace-nowrap max-w-xs">{item?.attributes['total-units']}</Td>
        {!isMobile && (
          <Td className="w-1/4 overflow-hidden text-ellipsis whitespace-nowrap max-w-xs">
            {item?.attributes['3c-units']}
          </Td>
        )}
        {!isMobile && (
          <Td className="w-1/4 overflow-hidden text-ellipsis whitespace-nowrap max-w-xs">
            {item?.attributes['unaccounted-units']}
          </Td>
        )}
        {!isMobile && (
          <Td className="w-1/4 overflow-hidden text-ellipsis whitespace-nowrap max-w-xs">
            {item?.attributes['sold-units']}
          </Td>
        )}
      </Tr>
    </>
  )
}