import { apiUrl, apiUrlV2 } from "../constants/apiUrl"

const initialState = {
  bankAccounts: [],
}

const createBankAccountSlice = (set, get) => ({
  ...initialState,
  fetchBankAccounts: () => fetch(
    `${apiUrl}/bank-accounts`,
    { headers: get().requestHeaders }
  )
    .then(response => response.json())
    .then(data => set((state) => ({ bankAccounts: data.data }))),
  createBankAccount: (body) => fetch(
    `${apiUrl}/bank-accounts`, {
      method: 'POST',
      headers: get().requestHeaders,
      body: JSON.stringify(body)
    }
  ),
  removeBankAccount: (id) => fetch(
    `${apiUrlV2}/bank-accounts/${id}/deactivate`, {
      method: 'PUT',
      headers: get().requestHeaders
    }
  ),
  makeDefaultAccount: (id) => fetch(
    `${apiUrl}/bank-accounts/${id}/make-default`, {
      method: 'PUT',
      headers: get().requestHeaders
    }
  ),
  achAuthorizeBankAccounts: (body) => fetch(
    `${apiUrlV2}/bank-accounts/ach-authorization`, {
      method: 'PUT',
      headers: get().requestHeaders,
      body: JSON.stringify(body)
    }
  ),
  fetchBankAccountsAsAm: (dealerId) => fetch(
    `${apiUrlV2}/dealers/${dealerId}/bank_accounts`,
    { headers: get().requestHeaders }
  )
    .then(response => response.json())
    .then(data => set((state) => ({ bankAccounts: data.data }))),
  resetBankAccountSlice: () => set(initialState)
})

export default createBankAccountSlice