import { AiOutlineFileAdd } from "react-icons/ai"
import UploadFileModal from "../../../common/UploadFileModal"
import { useDisclosure } from "@chakra-ui/react"

export default function FilesTableHeader ({tableTitle, dealerId, modalTitle, fileType}) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return(
    <div className="w-full text-left bg-grey-900 rounded-t pl-2 flex items-center justify-between">
      <h3 className="font-header font-semibold text-md text-green">{tableTitle}</h3>
      <div className="hover:cursor-pointer">
        <AiOutlineFileAdd
          onClick={onOpen}
          size={'30px'}
          data-testid='open-modal'
        />
      </div>
      <UploadFileModal
        isOpen={isOpen}
        onClose={onClose}
        dealerId={dealerId}
        fileType={fileType}
        modalTitle={modalTitle}
      />
    </div>
  )
}