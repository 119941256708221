import FilesTable from "./FilesTable"
import useStore from "../../../../store"

export default function FilesPage({dealerId}) {
  const dealerFiles = useStore((state) => state.dealerFiles)

  return(
    <div>
      <FilesTable dealerId={dealerId} dealerFiles={dealerFiles} tableTitle={'Files'} fileType={'document'}/>
    </div>
  )
}