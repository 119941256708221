import useStore from "../../../store"
import RiskSquare from "./RiskSquare"
import { useNavigate } from "react-router-dom"

export default function MyRiskMetrics() {
  const dailyOps = useStore((state) => state.dailyOps)
  const navigate = useNavigate()
  const toggleCollapsedSection = useStore((state) => state.toggleCollapsedSection)

  const handleClick = (section) => {
    toggleCollapsedSection(section)
    navigate('/risk')
  }

  return(
    <div className="flex justify-center w-full pb-2 pt-3 px-4 lg:px-8 bg-grey-900">
      <div className="flex flex-col w-full">
        <div className='lg:flex lg:flex-wrap md:flex md:flex-wrap gap-3 grid grid-cols-3 w-full justify-evenly'>
          <div onClick={() => {handleClick('untouchedFortyDays')}}>
            <RiskSquare
              text={"Untouched 40+ Days"}
              riskMetric={dailyOps.forty_day_count}
            />
          </div>
          <div onClick={() => {handleClick('soldUnits')}}>
            <RiskSquare
              text={"Sold Units"}
              riskMetric={dailyOps.sold_units}
            />
          </div>
          <div onClick={() => {handleClick('unitsPastDue')}}>
            <RiskSquare
              text={"Past Due Units"}
              riskMetric={dailyOps.past_due_units}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
