import { useEffect } from "react"
import { useParams } from "react-router-dom"
import useStore from "../../../store"
import { useNavigate } from "react-router-dom"
import UnitPageBody from "./UnitPageBody"

export default function UnitPage() {
  const { unitId } = useParams()
  const fetchUnit = useStore((state) => state.fetchUnit)
  const unit = useStore((state) => state.unit)
  const setUnit = useStore((state) => state.setUnit)
  const unitFetching = useStore((state) => state.unitFetching)
  const navigate = useNavigate()

  useEffect(() => {
    fetchUnit(unitId)
  }, [])

  useEffect(() => {
    if (!unitFetching && unit?.errors) {
      setUnit(null)
      navigate('/not-found')
    }
  }, [unitFetching])

  return(
    <div className="flex grow overflow-y-auto w-full justify-center">
      <div className="w-full lg:w-1/2">
        <UnitPageBody unitId={unitId} />
      </div>
    </div>
  )
}