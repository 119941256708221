import { useState } from "react"
import useStore from "../../store"
import { IoMdClose } from "react-icons/io"
import { FaEllipsisV, FaCheck, FaTrash } from "react-icons/fa"
import Spinner from "../common/Spinner"
import Swipeable from "../common/Swipeable"
import BankAccountName from "./BankAccountName"
import BankAccountStatus from "./BankAccountStatus"
import ActionConfirm from "../common/ActionConfirm"
import { useTranslation } from 'react-i18next'
import ExpandableOptionsRow from "../common/ExpandableOptionsRow"

export default function BankAccountRow({ account }) {
  const [optionsOpen, setOptionsOpen] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [confirmRemoval, setConfirmRemoval] = useState(false)
  const [confirmMakeDefault, setConfirmMakeDefault] = useState(false)
  const removeBankAccount = useStore((state) => state.removeBankAccount)
  const makeDefaultAccount = useStore((state) => state.makeDefaultAccount)
  const fetchBankAccounts = useStore((state) => state.fetchBankAccounts)
  const setSuccessMessage = useStore((state) => state.setSuccessMessage)
  const setErrMessage = useStore((state) => state.setErrMessage)
  const { t } = useTranslation()

  const handleRemoveBankAccount = async () => {
    setSubmitting(true)
    setOptionsOpen(false)
    try {
      const response = await removeBankAccount(account.id)
      const data = await response.json()

      if (response.ok) {
        setSuccessMessage('Bank account successfully removed. Check email for details')
      } else if (data.message) {
        setErrMessage(data.message)
      } else {
        setErrMessage('Unable to remove bank account at this time.')
      }
    } catch (err) {
      console.log(err)
      setErrMessage('There was an error processing your request.')
    } finally {
      fetchBankAccounts()
      setSubmitting(false)
    }
  }

  const handleMakeDefault = async () => {
    setSubmitting(true)
    setOptionsOpen(false)
    try {
      const response = await makeDefaultAccount(account.id)
      if (response.ok) {
        setSuccessMessage('Bank account successfully made default.')
      } else {
        setErrMessage('Unable to make default account at this time.')
      }
    } catch (err) {
      setErrMessage('There was an error processing your request.')
    } finally {
      fetchBankAccounts()
      setSubmitting(false)
    }
  }

  const optionsRowItemData = [
    {
      icon: <FaCheck size={25} />,
      label: t('Default'),
      onClick: () => setConfirmMakeDefault(true)
    },
    {
      icon: <FaTrash size={25} />,
      label: t('Remove'),
      onClick: () => setConfirmRemoval(true)
    },
    {
      icon: <IoMdClose size={25} />,
      label: t('Close'),
      onClick: () => setOptionsOpen(false)
    },
  ]

  return(
    <Swipeable
      onSwipeLeft={() => setOptionsOpen(true)}
      onSwipeRight={() => setOptionsOpen(false)}
    >
      <ExpandableOptionsRow
        items={optionsRowItemData}
        optionsOpen={optionsOpen}
      >
        <div className="flex justify-between items-center w-full py-2 border-b-2 border-grey-700">
          <div className="flex items-center justify-between w-9/12 md:w-1/2">
            <div className="w-2/3">
              <BankAccountName account={account} />
            </div>

            <div className="flex justify-center items-center w-1/3">
              <BankAccountStatus account={account} />
            </div>
          </div>

          <div>
            {submitting ?
              <Spinner /> :
              <div
                className={"flex justify-center items-center px-3 py-0.5 text-sm text-300 hover:border-grey-300 hover:cursor-pointer"}
                onClick={() => setOptionsOpen(!optionsOpen)}
              >
                <FaEllipsisV
                  className="text-xl"
                  data-testid="open-options"
                />
              </div>
            }
          </div>

          {confirmRemoval &&
            <ActionConfirm
              body="Are you sure you wish to remove this account?"
              onCancel={() => setConfirmRemoval(false)}
              onConfirm={() => {
                handleRemoveBankAccount()
                setConfirmRemoval(false)
              }}
            />
          }
          {confirmMakeDefault &&
            <ActionConfirm
              body="Are you sure you wish to make this your default account?"
              onCancel={() => setConfirmMakeDefault(false)}
              onConfirm={() => {
                handleMakeDefault()
                setConfirmMakeDefault(false)
              }}
            />
          }
        </div>
      </ExpandableOptionsRow>
    </Swipeable>
  )
}