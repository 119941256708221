import { motion } from "framer-motion"

export function CameraBounds() {
  return(
    <div
      className="fixed h-dvh w-screen py-10 px-12 z-50"
    >
      <motion.div
        className="relative w-full h-full overflow-hidden"
        animate={{
          scale: [1, 0.99, 1.01, 1],
        }}
        transition={{
          duration: 0.8,
          times: [0, 0.3, 0.5, 0.8],
          ease: "easeInOut",
          repeat: Infinity,
          repeatDelay: 2.5
        }}
      >
        <div
          className="absolute h-12 w-12 top-0 left-0 rounded-tl-lg border-t-4 border-l-4"
        />
        <div
          className="absolute h-12 w-12 top-0 right-0 rounded-tr-lg border-t-4 border-r-4"
        />
        <div
          className="absolute h-12 w-12 bottom-0 left-0 rounded-bl-lg border-b-4 border-l-4"
        />
        <div
          className="absolute h-12 w-12 bottom-0 right-0 rounded-br-lg border-b-4 border-r-4"
        />
      </motion.div>
    </div>
  )
}