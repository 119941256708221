import useStore from "../../../store"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import DealerNameHeader from "./DealerNameHeader"
import { SolidGreenButton, NeutralButton } from "../../common/Buttons"
import FormInput from "../../common/FormInput"
import Spinner from "../../common/Spinner"

export default function DealerEdit() {
  const navigate = useNavigate()
  const dealer = useStore((state) => state.dealer)
  const updateDealer = useStore((state) => state.updateDealer)
  const [isUpdating, setIsUpdating] = useState(false)
  const [formValues, setFormValues] = useState({
    licenseNumber: dealer.attributes['license-number'],
    email: dealer.attributes["email"],
    phone: dealer.attributes["primary-phone"].replace(/\D/g, ""),
    fax: dealer.attributes["primary-fax"].replace(/\D/g, ""),
    prefLabel1: dealer.attributes["preference-label-1"],
    prefText1: dealer.attributes["preference-text-1"],
    prefLabel2: dealer.attributes["preference-label-2"],
    prefText2: dealer.attributes["preference-text-2"],
    prefLabel3: dealer.attributes["preference-label-3"],
    prefText3: dealer.attributes["preference-text-3"],
  })

  const handleSubmit = async () => {
    try{
      setIsUpdating(true)
      await updateDealer(dealer.id, {
        license_number: formValues.licenseNumber,
        email: formValues.email,
        primary_place_attributes: {
          phone: formValues.phone,
          fax: formValues.fax
        },
        preference_label_1: formValues.prefLabel1,
        preference_text_1: formValues.prefText1,
        preference_label_2: formValues.prefLabel2,
        preference_text_2: formValues.prefText2,
        preference_label_3: formValues.prefLabel3,
        preference_text_3: formValues.prefText3,
      })
      navigate(`/dealers/${dealer.id}`)
    } catch (err) {
      console.log(err)
    } finally {
      setIsUpdating(false)
    }
  }

  return(
    <div className="flex grow justify-center w-full overflow-y-auto transition-all duration-300">
      <div className="flex flex-col w-full items-center">
        <DealerNameHeader name={dealer.attributes.name} />
        <form className="flex flex-col w-72 mt-2 w-full px-4">
          <div
            className="flex flex-col w-full"
            data-testid='bank-account-form'
          >
            <FormInput
              title="Dealer License Number"
              value={formValues.licenseNumber}
              onChange={(e) => setFormValues({ ...formValues, licenseNumber: e.target.value })}
            />

            <FormInput
              title="Phone"
              value={formValues.phone}
              onChange={(e) => setFormValues({ ...formValues, phone: e.target.value })}
            />

            <FormInput
              title="Fax"
              value={formValues.fax}
              onChange={(e) => setFormValues({ ...formValues, fax: e.target.value })}
            />

            <FormInput
              title="Email"
              value={formValues.email}
              onChange={(e) => setFormValues({ ...formValues, email: e.target.value })}
            />

            <div className="flex flex-col mt-3">
              <div className="font-bold">Dealer Preferences</div>
              <FormInput
                title="Preference 1 Label"
                value={formValues.prefLabel1}
                onChange={(e) => setFormValues({ ...formValues, prefLabel1: e.target.value })}
              />

              <FormInput
                title="Preference 1 Text"
                value={formValues.prefText1}
                onChange={(e) => setFormValues({ ...formValues, prefText1: e.target.value })}
              />

              <FormInput
                title="Preference 2 Label"
                value={formValues.prefLabel2}
                onChange={(e) => setFormValues({ ...formValues, prefLabel2: e.target.value })}
              />

              <FormInput
                title="Preference 2 Text"
                value={formValues.prefText2}
                onChange={(e) => setFormValues({ ...formValues, prefText2: e.target.value })}
              />

              <FormInput
                title="Preference 3 Label"
                value={formValues.prefLabel3}
                onChange={(e) => setFormValues({ ...formValues, prefLabel3: e.target.value })}
              />

              <FormInput
                title="Preference 3 Text"
                value={formValues.prefText3}
                onChange={(e) => setFormValues({ ...formValues, prefText3: e.target.value })}
              />
            </div>

          </div>
          <SolidGreenButton
            text={isUpdating ? <Spinner color="white" /> : "Update Dealer"}
            onClick={handleSubmit}
            style="my-2"
          />
          <NeutralButton text="Cancel" onClick={() => navigate(`/dealers/${dealer.id}`)} style="my-2" />
        </form>
      </div>
    </div>
  )
}