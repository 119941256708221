import { useState, useEffect } from "react"

export default function BottomDrawer({ children, onCancel }) {
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    setIsLoaded(true)
  }, [])

  return (
    <div>
      <div
        className={`fixed z-50 left-0 ${isLoaded ? 'bottom-0' : '-bottom-1/2'}
        h-1/3 w-full pt-3 bg-black rounded transition-all duration-500`}
      >
        {children}
      </div>
      <div
          className={`block ${isLoaded ? 'opacity-50' : 'opacity-0'} fixed top-0 left-0 z-40 h-screen w-screen bg-black transition-all duration-500`}
          onClick={() => onCancel()}
      />
    </div>
  )
}
