import { apiUrl, apiUrlV2 } from "../constants/apiUrl"

const initialState = {
  people: [],
  peopleFetching: false,
  dealerId: null,
  person: null,
  personFetching: false
}

const createPeopleSlice = (set, get) => ({
  ...initialState,
  fetchPeople: async(dealerId) => {
    set((state) => ({ peopleFetching: true}))

    try {
      const response = await fetch(
        `${apiUrlV2}/dealers/${dealerId}/people`,
        {headers: get().requestHeaders}
      )
      if (response.status === 200) {
        const data = await response.json()

        if(get().user) {
          set((state) => ({ people: data.data, peopleFetching: false }))
        }
      }
    } catch (err) {
      get().setErrMessage('Data was unable to be fetched. Please refresh to try again')
    }
  },
  fetchPerson: async(dealerId, personId) => {
    set((state) => ({ personFetching: true}))

    try {
      const response = await fetch(
        `${apiUrlV2}/dealers/${dealerId}/people/${personId}`,
        {headers: get().requestHeaders}
      )
      if (response.status === 200) {
        const data = await response.json()
        if(get().user) {
          set((state) => ({ person: data.data, personFetching: false }))
        }
      }
    } catch (err) {
      get().setErrMessage('Data was unable to be fetched. Please refresh to try again')
    }
  },
  updatePerson: async (dealerId, personId, attributes) => {
    try {
      const response = await fetch(
        `${apiUrlV2}/dealers/${dealerId}/people/${personId}`,
        {
          headers: get().requestHeaders,
          method: 'PATCH',
          body: JSON.stringify({
            person: attributes
          })
        }
      )
      if (response.status === 200) {
        const data = await response.json()

        if(get().user) {
          set((state) => ({ person: data.data }))
        }
      }
    } catch (err) {
      get().setErrMessage('Data was unable to be updated. Please refresh to try again')
    }
  },
  setDealerId: (id) => set(() => ({ dealerId: id })),
  resetPeopleSlice: () => set(initialState)
})

export default createPeopleSlice