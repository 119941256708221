import useStore from "../../store"
import { DateTime } from 'luxon'

export default function StagingBanner () {
  const user = useStore((state) => state.user)

  const formatDateTime = () => {
    return DateTime.fromISO(user.dataCurrentAsOf).toLocaleString({
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZoneName: 'short'
    })
  }

  return(
    <div className='flex items-center justify-center text-center text-black text-xs w-full bg-yellow'>
      Staging
      {user?.dataCurrentAsOf && ` | Current as of ${formatDateTime()}`}
    </div>
  )
}