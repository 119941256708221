import { NeutralButton } from "./Buttons"
import Spinner from "../common/Spinner"
import { useTranslation } from "react-i18next"
import BottomDrawer from "./BottomDrawer"


export default function ActionConfirm({ body, onConfirm, onCancel, showSpinner = false, confirmText = null, cancelText = null, subtext = null }) {
  const { t } = useTranslation()

  return(
    <BottomDrawer onCancel={onCancel}>
      <div className="flex flex-col h-full w-full items-center p-2 text-center text-grey-100">
        {body}
        {showSpinner ?
          <Spinner className="my-4" /> :
          <div className="flex flex-col items-center">
            {subtext}
            <div className="flex my-4">
              <NeutralButton
                text={confirmText || t('Confirm')}
                onClick={() => onConfirm()}
                style="mx-4"
              />
              <NeutralButton
                text={cancelText || t('Cancel')}
                onClick={() => onCancel()}
                style="mx-4"
              />
            </div>
          </div>
        }
      </div>
    </BottomDrawer>
  )
}
