const initialState = {
  autoDismissMessageTimer: null
}

const createInAppMessagingSlice = (set, get) => ({
  ...initialState,
  startAutoDismissMessageTimer: (i) => {
    get().stopAutoDismissMessageTimer()
    set(() => ({
      autoDismissMessageTimer: setTimeout(() => {
        get().resetMessages()
      }, i)
    }))
  },
  stopAutoDismissMessageTimer: () => {
    clearTimeout(get().autoDismissMessageTimer)
  },
  resetInAppMessagingSlice: () => set(initialState)
})

export default createInAppMessagingSlice