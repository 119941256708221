import MobileRiskSquare from "./MobileRiskSquare"
import riskSquareColor from "../../../utilities/riskSquareColor"
import DesktopRiskSquare from "./DesktopRiskSquare"
import { motion } from "framer-motion"

export default function RiskSquare({riskMetric, text}) {
  return (
    <motion.div className={`min-[1400px]:border-0 min-[1400px]:bg-grey-900 md:border-0 md:bg-grey-900 border-2 rounded-md bg-black h-24 min-[1400px]:m-4 min-[1400px]:h-fit md:m-4 md:h-fit flex items-center justify-center relative flex-col ${riskSquareColor(riskMetric)}`}
      whileTap={{ scale: 0.9 }}
    >
      <div className="min-[1400px]:hidden md:hidden" data-testid="risk-mobile-div">
        <MobileRiskSquare
          text={text}
          riskMetric={riskMetric}
        />
      </div>
      <motion.div className="hidden min-[1400px]:block md:block hover:cursor-pointer"
        data-testid="risk-desktop-div"
        whileHover={{ scale: 1.1 }}
      >
        <DesktopRiskSquare
          text={text}
          riskMetric={riskMetric}
        />
      </motion.div>
  </motion.div>)
}
