import useStore from "../../../store"
import { useTranslation } from "react-i18next"
import IssueFreeConfirmationDetails from "./IssueFreeConfirmationDetails"

export default function NAPConfirmationDetails({ issues }) {
  const { t } = useTranslation()
  const blackbook = useStore((state) => state.NAPBlackbook)

  return(
    <div className="flex flex-col items-center text-grey-300 my-2">
      <div className="text-grey-100 text-2xl">{t('Review and Confirm')}</div>
      <div>
        VIN: {blackbook.vin}
      </div>

      {!issues && <IssueFreeConfirmationDetails />}
    </div>
  )
}