import { useTranslation } from "react-i18next"
import useStore from "../../../store"
import TransferRow from "./TransferRow"
import { useNavigate } from "react-router-dom"
import { useIsMobile } from "../../../hooks/useIsMobile"


export default function ProcessingTransfers() {
  const navigate = useNavigate()
  const isMobile = useIsMobile()
  const processingTransfers = useStore((state) => state.processingTransfers)
  const setTransfer = useStore((state) => state.setTransfer)
  const { t } = useTranslation()

  const handleClick = (transfer) => {
    setTransfer(transfer)
    if (isMobile) {
      navigate(`/transfers/${transfer.id}`)
    }
  }

  return(
    <div className="w-full text-left p-2 mb-2 bg-grey-900 rounded">
      <div className="sticky top-0 left-0 z-30 bg-grey-900">
        <h3 className="font-header font-semibold text-md text-green">{t('Processing Transactions')}</h3>
      </div>

      <div className="h-full">
        {processingTransfers.map((transfer) => {
          return (
            <TransferRow
              date={transfer.attributes['actual-transfer-date']}
              type={transfer.attributes['payment-type']}
              unitName={`${transfer.attributes.make} ${transfer.attributes.model}`}
              amount={transfer.attributes.amount}
              vin={transfer.attributes.vin}
              key={transfer.id}
              onClick={() => handleClick(transfer)}
            />
          )
        })}
      </div>
    </div>
  )
}