import useStore from "../../../store"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import napEnum from "../../../constants/napEnum"
import { useIsMobile } from '../../../hooks/useIsMobile'
import UnitWarningContainer from "./UnitWarningContainer"

export default function PendingNAP() {
  const navigate = useNavigate()
  const unit = useStore((state) => state.unit)
  const setNAPIssues = useStore((state) => state.setNAPIssues)
  const setNAPBlackbook = useStore((state) => state.setNAPBlackbook)
  const resetNAPSlice = useStore((state) => state.resetNAPSlice)
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  const handleUpdateNAP = () => {
    resetNAPSlice()
    setNAPBlackbook({
      vin: unit.attributes.vin,
      id: unit.id,
      model_year: unit.attributes.year,
      make: unit.attributes.make,
      model: unit.attributes.model
    })
    setNAPIssues(unit.attributes['nap-issues'])
    navigate('/nap_edit')
  }

  return(
    <UnitWarningContainer colorStyles="text-yellow border-yellow">
      {unit.attributes['nap-issues'].length > 0 ?
        t('This floor request has unresolved issues') :
        t('This unit is pending approval')
      }
      {unit.attributes['nap-issues'].length > 0 &&
        <div className="flex flex-col items-center w-full">
          <ul>
            {unit.attributes['nap-issues'].map((issue) => {
              return(
                <li key={issue.issue_step}>
                  {`\u2022 ${t(napEnum[issue.issue_step])} ${t('Photo')}`}
                </li>
              )
            })}
          </ul>
          {
            isMobile ?
              <div
                className="text-sm mt-1 mb-1 px-1.5 border border-yellow rounded hover:cursor-pointer"
                onClick={handleUpdateNAP}
              >
                {t('Update Floor Request')}
              </div>
            :
              <div className="text-sm mt-1 mb-1 px-1.5">
                Use mobile device to update.
              </div>
          }
        </div>
      }
    </UnitWarningContainer>
  )
}