import useStore from '../../store'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { loginRequest } from '../../configs/msalAuthConfig'
import { useMsal } from '@azure/msal-react'
import Spinner from '../common/Spinner'

export default function EmployeeLogin() {
  const { instance } = useMsal()
  const azureVerifiedAccount = instance.getActiveAccount()
  const loginTasks = useStore((state) => state.loginTasks)
  const setErrMessage = useStore((state) => state.setErrMessage)
  const validateAzureToken = useStore((state) => state.validateAzureToken)
  const navigate = useNavigate()

  useEffect(() => {
    if (azureVerifiedAccount) {
      loginDFPWithAzureToken()
    }
  }, [azureVerifiedAccount])

  const loginDFPWithAzureToken = async () => {
    try{
      await instance.initialize()
      const newToken = await instance.acquireTokenSilent(loginRequest)
      const response = await validateAzureToken(newToken.accessToken)
      const data = await response.json()
      if (response.status === 200) {
        loginTasks(data)
      } else {
        setErrMessage('Unrecognized MS Teams user')
      }
    } catch (err) {
      console.error(err)
      setErrMessage('An error occurred while validating user credentials')
    } finally {
      instance.setActiveAccount(null)
      navigate('/')
    }
  }

  return(
    <div
      className="flex justify-center items-center w-full max-h-dvh min-h-dvh"
    >
      <Spinner />
    </div>
  )
}