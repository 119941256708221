import { useTranslation } from "react-i18next"

export default function UnitTitleUnavailable() {
  const { t } = useTranslation()

  return(
    <div
      className={`flex justify-center items-center text-center text-grey-100 opacity-75 py-1.5 px-8 border border-2 rounded-full my-2 mx-3`}
    >
      {t("Title Unavailable")}
    </div>
  )
}