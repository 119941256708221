import { apiUrl, apiUrlV2 } from "../constants/apiUrl"

const initialState = {
  currentStep: 0,
  NAPImages: {},
  NAPFloorAmount: '.00',
  NAPBlackbook: null,
  napId: null,
  NAPIssues: [],
  NAPBankAccount: null,
  NAPRequiresBankPayoff: false,
  NAPWorkflow: []
}

const createNapSlice = (set, get) => ({
  ...initialState,
  createFloorRequest: async (body) => fetch(
    `${apiUrlV2}/units`, {
      method: 'POST',
      headers: { 'Authorization': get().requestHeaders['Authorization']},
      body: body
    }
  )
  ,
  setNapId: (id) => set((state) => ({ napId: id })),
  setCurrentStep: (currentStep) => set((state) => ({ currentStep: currentStep })),
  nextStep: () => set((state) => ({ currentStep: get().currentStep + 1})),
  setNAPImages: (NAPImages) => set((state) => ({ NAPImages: NAPImages })),
  setNAPFloorAmount: (NAPFloorAmount) => set((state) => ({ NAPFloorAmount: NAPFloorAmount })),
  setNAPBlackbook: (NAPBlackbook) => set((state) => ({ NAPBlackbook: NAPBlackbook })),
  setNAPIssues: (issues) => set((state) => ({ NAPIssues: issues })),
  setNAPBankAccount: (account) => set((state) => ({ NAPBankAccount: account })),
  setNAPRequiresBankPayoff: (boolean) => set((state) => ({ NAPRequiresBankPayoff: boolean })),
  setNAPWorkflow: (workflow) => set((state) => ({ NAPWorkflow: workflow})),
  resetNAPSlice: () => set(initialState)
})

export default createNapSlice