import formatAccountNumber from '../../../utilities/accountNumber'
import BankAccountStatus from "../../AccountManagement/BankAccountStatus"

export default function BankAccountName({ account }) {
  return(
    <div className="flex items-center justify-between w-full">
      <div className='flex flex-col my-0.5'>
        <span>{account.attributes['name-personal']}</span>
        <span className="text-xs text-grey-300">
          {formatAccountNumber(account.attributes['account-number'])}
        </span>
      </div>
        <span>{<BankAccountStatus account={account} />}</span>
    </div>
  )
}