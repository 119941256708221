import tailwind from "../../../tailwind.config"
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  DrawerCloseButton,
} from "@chakra-ui/react"

export default function FilterByDrawer({isOpen, onClose, title, bodyContent, footerContent}) {
  const deepOcean = tailwind.theme.colors["deep-ocean"]

  const handleCancel = () => {
    onClose()
  }

  return(
    <Drawer isOpen={isOpen} placement='right' onClose={handleCancel} trapFocus={false}>
      <DrawerOverlay />
      <DrawerContent bg={deepOcean}>
        <DrawerCloseButton />
        <DrawerHeader>{title}</DrawerHeader>
        <DrawerBody>
          {bodyContent}
        </DrawerBody>
        <DrawerFooter>
          {footerContent}
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}