import { getMessaging, onMessage } from "firebase/messaging"

export const initializeForegroundNotifications = () => {
  const messaging = getMessaging()

  onMessage(messaging, (payload) => {
    console.log("Received foreground message ", payload)

    const notificationTitle = payload.data.title
    const notificationOptions = {
      body: payload.data.body,
      icon: "public/fx-icon-512.png",
    }

    if (Notification.permission === "granted") {
      new Notification(notificationTitle, notificationOptions)
    } else {
      console.log("Notification permission is not granted")
    }
  })
}