import DashboardSidePanel from "./DashboardSidePanel"
import { useState } from "react"
import { FaChevronRight } from "react-icons/fa"

export default function DashboardContainer({ children, sidePanels = null }) {
  const [isSidePanelFullscreen, setIsSidePanelFullscreen] = useState(false)

  const handleExitFullscreen = () => {
    if (isSidePanelFullscreen) {
      setIsSidePanelFullscreen(false)
    }
  }

  return(
    <div className="flex grow justify-center w-full overflow-y-auto transition-all duration-300">
      <div
        className={`flex flex-col w-full items-center lg:px-2 lg:h-[85vh] lg:overflow-y-auto rounded transition-all duration-300
          ${isSidePanelFullscreen ? 'lg:w-9 bg-grey-900 justify-center hover:bg-grey-700 hover:cursor-pointer mr-2' : 'lg:w-1/2'} `}
        onClick={handleExitFullscreen}
      >
        {isSidePanelFullscreen && <FaChevronRight />}
        <div className={`${isSidePanelFullscreen && 'hidden'} w-full`}>{children}</div>
      </div>

      {sidePanels && sidePanels.map((sidePanel) => {
        return(
          <DashboardSidePanel
            isExpanded={sidePanel.isExpanded}
            isSidePanelFullscreen={isSidePanelFullscreen}
            setIsSidePanelFullscreen={setIsSidePanelFullscreen}
            onCloseSidePanel={() => {
              sidePanel.onClose()
              setIsSidePanelFullscreen(false)
            }}
            isFullscreenable={sidePanel.isFullscreenable}
            isClosable={sidePanel.isClosable}
          >
            {sidePanel.component}
          </DashboardSidePanel>
        )
      })}
    </div>
  )
}