import useStore from "../../../store"
import Spinner from "../../common/Spinner"
import UnitTable from "./UnitTable"
import NoCurrentUnits from "./NoCurrentUnits"
import { useState } from "react"
import SearchBar from "../../common/SearchBar"
import UnitTableFilterButtons from "../../AccountManager/Dealer/Units/UnitTableFilterButtons"
import { useTranslation } from "react-i18next"
import { FaUndoAlt } from "react-icons/fa"
import UnitsFilterByDrawer from "../../AccountManager/Dealer/Units/UnitsFilterByDrawer"
import { IoFilterSharp } from "react-icons/io5"
import { useDisclosure } from "@chakra-ui/react"
import TableFilterIndicators from "../../common/TableFilterIndicators"
import { useIsMobile } from "../../../hooks/useIsMobile"
import AmSearchBar from "../../common/AmSearchBar"

export default function CurrentUnits() {
  const units = useStore((state) => state.units)
  const unitsFetching = useStore((state) => state.unitsFetching)
  const user = useStore((state) => state.user)
  const { t } = useTranslation()
  const amUnitsTableParams = useStore((state) => state.amUnitsTableParams)
  const setAmUnitsTableParams = useStore((state) => state.setAmUnitsTableParams)
  const dealerUnitsSearchBarValue = useStore((state) => state.dealerUnitsSearchBarValue)
  const setDealerUnitsSearchBarValue = useStore((state) => state.setDealerUnitsSearchBarValue)
  const dealer = useStore((state) => state.dealer)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const isMobile = useIsMobile()
  const selectedDealerUnitsScopeLabel = useStore((state) => state.selectedDealerUnitsScopeLabel)
  const setSelectedDealerUnitsScopeLabel = useStore((state) => state.setSelectedDealerUnitsScopeLabel)

  const unitsBody = () => {
    if (units?.length > 0) {
      if(units[0]?.attributes['dealer-id'] == dealer?.id) {
        return (<UnitTable searchTerm={dealerUnitsSearchBarValue} setSearchTerm={setDealerUnitsSearchBarValue}/>)
      } else {
        return (<Spinner />)
      }
    } else if (unitsFetching) {
      return (<Spinner />)
    } else {
      return (<NoCurrentUnits />)
    }
  }

  const handleReset = () => {
    setAmUnitsTableParams('')
    setDealerUnitsSearchBarValue('')
  }

  const currentScope = amUnitsTableParams['filter[scope]']

  const stickyStyles = user?.type === 'DealerUser' ? 'sticky top-0 z-30' : ''

  return (
    <div className="h-full">
      <div className={`relative flex flex-col bg-grey-900 rounded w-full pr-2 pl-1 ${stickyStyles}`}>
        <div className="flex w-full items-center sticky top-0 z-30">
          {user.type === "DealerUser" &&
            <div className="font-header font-semibold text-md text-green mr-1  pt-2 pb-3 px-2">
              { t("Current Units")}
            </div>
          }
        </div>
          {user.type === 'AccountManager' ?
            <AmSearchBar
              handleSearch={setDealerUnitsSearchBarValue}
              handleReset={handleReset}
              onOpen={onOpen}
              showFilterIcon={true}
              dataFetching={unitsFetching}
              searchBarValue={dealerUnitsSearchBarValue}
              setSearchBarValue={setDealerUnitsSearchBarValue}
            />
            :
            <div className="grow flex items-center pb-2 px-2 sticky top-0 z-30">
              <SearchBar onChange={setDealerUnitsSearchBarValue} value={dealerUnitsSearchBarValue} />
            </div>
        }
      </div>

      {user?.type === "AccountManager" && <UnitsFilterByDrawer isOpen={isOpen} onClose={onClose}/>}
      {user?.type === "AccountManager" &&
        <TableFilterIndicators
          onClose={onClose}
          filterParams={amUnitsTableParams['filter[scope]']}
          setFilterParams={setAmUnitsTableParams}
          selectedScopeLabel={selectedDealerUnitsScopeLabel}
          resetFilters={['filter[scope]']}
          setSelectedScopeLabel={setSelectedDealerUnitsScopeLabel}
        />
      }

      {unitsBody()}
    </div>
  )
}
