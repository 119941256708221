import HighlightMatch from "../../../utilities/HighlightMatch"
import phoneNumber from "../../../utilities/phoneNumber"

export default function AppName({ app, filter = null }) {

  return(
    <div className="w-full font-bold">
      <div className={`w-full text-sm  flex gap-2`}>
        <HighlightMatch str={app.attributes['dba']} match={filter} truncate={true}/>
      </div>
      <div className={'w-full text-lg text-grey-100'}>
        <HighlightMatch str={(app.attributes['dealership-name'])} match={filter} truncate={true}/>
      </div>
      <div className={'w-full text-xs text-grey-100'}>
        <HighlightMatch str={phoneNumber(app?.attributes['phone'])} match={filter} truncate={true}/>
      </div>
    </div>
  )
}