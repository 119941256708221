import NotesBody from "./NotesBody"
import { useParams } from "react-router-dom"

export default function MobileNotesPage() {
  const { type, id } = useParams()

  return(
    <div className="flex grow flex-col items-center w-full overflow-y-auto">
      <div className="w-full lg:h-full bg-grey-900 mb-2 rounded">
        <NotesBody type={type} id={id}/>
      </div>
    </div>
  )
}