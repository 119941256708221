export default function businessDevCircleColor({number, goal, apps_pending=null, prospects=null}) {
  if(prospects) {
    if(number < 100) {
      return 'bg-fpx-red'
    } else if(number >= 100 && number < goal) {
      return 'bg-yellow'
    } else {
      return 'bg-green'
    }
  } else if(apps_pending) {
    if(number < goal) {
      return 'bg-green'
    } else if(number > goal && number < goal * 2) {
      return 'bg-yellow'
    } else {
      return 'bg-fpx-red'
    }
  } else if(number >= goal) {
    return 'bg-green'
  } else {
    return 'bg-fpx-red'
  }
}

