import dailyOpColor from "../../../utilities/dailyOpColor"
import tailwind from "../../../../tailwind.config"
import {
  CircularProgressbar,
  buildStyles
} from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"
import KpiMetric from "./KpiMetric"

export default function DesktopKpiSquare({display_amount, goal_percentage, text, subtext, subsubtext, maxValue, thisMonthCount, thisWeekCount, todaysCount}) {
  const grey300 = tailwind.theme.colors["grey-300"]
  const yellow = tailwind.theme.colors["yellow"]
  const fpxRed = tailwind.theme.colors["fpx-red"]
  const green = tailwind.theme.colors["green"]

  const color = (goal_percentage) => {
    if (goal_percentage >= 50 && goal_percentage < 100) {
      return(yellow)
    } else if (goal_percentage >= 100) {
      return(green)
    } else {
      return(fpxRed)
    }
  }

  return(
    <div className="relative flex flex-col items-center justify-center max-h-60 max-w-60 w-full h-full p-12 border-2 border-grey-700 rounded-md">
      <CircularProgressbar
        value={goal_percentage}
        text={`${display_amount}`}
        circleRatio={0.75}
        strokeWidth={10}
        maxValue={maxValue}
        styles={buildStyles({
          rotation: 1 / 2 + 1 / 8,
          strokeLinecap: "butt",
          trailColor: grey300,
          pathColor: color(goal_percentage),
          textColor: color(goal_percentage),
        })}
      />
      <div className="absolute bottom-0 right-0 text-grey-100 text-xs p-2 text-right">
        {subtext}
        <div>{subsubtext}</div>
      </div>
        <div className={`absolute bottom-0 left-0 text-xs p-2 whitespace-nowrap`}>
          <KpiMetric
            metric={todaysCount}
            timeInterval={'D'}
          />
          <KpiMetric
            metric={thisWeekCount}
            timeInterval={'W'}
          />
          <KpiMetric
            metric={thisMonthCount}
            timeInterval={'M'}
          />
        </div>
      <h4 className="text-grey-100 font-header font-semibold text-lg absolute top-0 left-0 p-2 text-center">{text}</h4>
    </div>
  )
}