import OCRScanner from "./OCRScanner"
import { useRef, useEffect, useState } from "react"
import useStore from "../../store"
import { BrowserMultiFormatReader, DecodeHintType, BarcodeFormat } from "@zxing/library"
import Camera from "./Camera"
import { useMediaQuery } from 'react-responsive'

const MAX_SCAN_TIME_MS = 20000

export default function BarcodeScanner({ close, setBarcode }) {
  const webcamRef = useRef(null)
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const hints = new Map()
  const setErrMessage = useStore((state) => state.setErrMessage)

  hints.set(DecodeHintType.ENABLE_CODE_39_EXTENDED_MODE, true)
  hints.set(DecodeHintType.POSSIBLE_FORMATS, [BarcodeFormat.CODE_39])

  const reader = new BrowserMultiFormatReader(hints)

  const [cameraLoaded, setCameraLoaded] = useState(false)

  useEffect(() => {
    if (!webcamRef?.current?.video) return
    reader.decodeFromConstraints(
      {
        audio: false,
        video: {
          facingMode: 'environment',
          focusMode: 'continuous',
        },
      },
      webcamRef.current.video,
      (result, error) => {
        if (result) {
          if (result.text.length > 17) {
            // barcode reader sometimes appends extra character in front of vin
            setBarcode(result.text.slice(1))
          } else {
            setBarcode(result.text)
          }
          close()
        }
        if (error) console.log(error)
      }
    )

    return () => {
      reader.reset()
    }
  }, [webcamRef?.current?.video, cameraLoaded, isPortrait])

  useEffect(() => {
    // sets a timer for to close out the scanner if no results found
    const timeoutId = setTimeout(() => {
      setErrMessage('Scanner was unable to find a VIN in the camera view.')
      close()
    }, MAX_SCAN_TIME_MS)

    return () => clearTimeout(timeoutId)
  }, [])

  const onScanSuccess = (result) => {
    setBarcode(result)
    close()
  }

  return (
    <OCRScanner
      webcamRef={webcamRef}
      cameraLoaded={cameraLoaded}
      onScanSuccess={onScanSuccess}
    >
      <Camera
        close={close}
        scannerRef={webcamRef}
        instructions={'Scan VIN text or barcode'}
        onMediaCallback={() => setCameraLoaded(true)}
        bounds={true}
      />
    </OCRScanner>
  )
}