import ClickableRow from '../../common/ClickableRow'
import { DateTime } from 'luxon'
import { useNavigate } from 'react-router-dom'
import useStore from '../../../store'
import { reminderOverdue } from '../../../utilities/reminderUtilities'


export default function ReminderRow({ reminder }) {
  const navigate = useNavigate()
  const setReminder = useStore((state) => state.setReminder)
  const date = DateTime.fromISO(reminder.attributes['due-on'])

  const handleClick = () => {
    setReminder(reminder)
    navigate(reminder.id)
  }

  return(
    <ClickableRow
      onClick={handleClick}
    >
      <div
        className="flex flex-col truncate w-full px-2"
        data-testid="reminder-row"
      >
        <div className={`${reminderOverdue(reminder) && 'text-fpx-red'} text-sm`}>
          {`Due ${date.toLocaleString()}`}
          {reminder.attributes['assigned-to'] && ` | ${reminder.attributes['assigned-to']['full_name']}`}
        </div>
        <div className="w-full truncate text-ellipsis text-grey-100">
          {reminder.attributes.name}
        </div>
      </div>
    </ClickableRow>
  )
}