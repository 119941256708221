import { useState } from "react"
import useStore from "../store"
import { useNavigate, useSearchParams } from "react-router-dom"
import NavBar from "./NavBar/NavBar"
import Spinner from './common/Spinner'
import { apiUrl } from "../constants/apiUrl"
import { FiEye, FiEyeOff } from 'react-icons/fi'
import { NeutralButton } from "./common/Buttons"
import MessageHandling from "./MessageHandling/MessageHandling"
import { useTranslation } from "react-i18next"

export default function ResetPassword() {
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [submitting, setSubmitting] = useState('')
  const setErrMessage = useStore((state) => state.setErrMessage)
  const setSuccessMessage = useStore((state) => state.setSuccessMessage)
  const resetMessages = useStore((state) => state.resetMessages)
  const [type, setType] = useState('password')
  const [iconOff, setIconOff] = useState(true)
  const [searchParams, setSearchParams] = useSearchParams()
  const token = searchParams.get('token')
  const requestHeaders = useStore((state) => state.requestHeaders)
  const user = useStore((state) => state.user)
  const navigate = useNavigate()
  const { t } = useTranslation()

  const requestBody = {
    password: password,
    password_confirmation: passwordConfirm,
    reset_password_token: token
  }

  const handleSubmit = async (e) => {
    try {
      setSubmitting(true)
      resetMessages()
      const response = await fetch(`${apiUrl}/auth/reset-password`, {
        method: 'POST',
        headers: requestHeaders || { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
      })
      if (response.ok) {
        setSuccessMessage('Password has been successfully changed')
      } else {
        setErrMessage('There was an error processing the password change request.')
      }
    } catch (err) {
      setErrMessage('There was an error processing the password change request.')
    } finally {
      setSubmitting(false)
    }
  }

  const handleToggle = () => {
    if (type==='password'){
      setIconOff(false)
      setType('text')
   } else {
      setIconOff(true)
      setType('password')
   }
  }

  return (
    <MessageHandling>
      <NavBar>
        <div className="flex flex-col h-screen items-center mt-4 lg:mt-0">
          <div className="flex flex-col items-center w-2/3 lg:w-1/2">
            <h1 className="text-lg">{t('Reset Your Password')}</h1>
            <div className="flex w-full my-2 border-solid border-2 border-grey-700 focus-within:border-grey-300 rounded">
              <input
                placeholder={t("Password")}
                type={type}
                value={password}
                name="password"
                title="password"
                className="p-3 w-full bg-black border-none focus:border-none focus:ring-0"
                onChange={(e) => setPassword(e.target.value)}
              />
              <button className="flex justify-around items-center" aria-label="view password" type="button" onClick={handleToggle}>
                { iconOff ?
                  <FiEyeOff className="mr-2 text-grey-300" size={25} alt="show password" data-testid="show-password-button" />
                  : <FiEye className="mr-2 text-grey-300" size={25} alt="hide password" data-testid="hide-password-button" />
                }
              </button>
            </div>

            <input
              placeholder={t("Password Confirmation")}
              type={type}
              value={passwordConfirm}
              name="password-confirmation"
              title="password-confirmation"
              className="p-3 w-full bg-black border-2 border-grey-700 focus:border-grey-300 focus:ring-0 rounded"
              onChange={(e) => setPasswordConfirm(e.target.value)}
            />

            {submitting ?
              <Spinner /> :
              <NeutralButton text={t("Change Password")} onClick={handleSubmit} style="my-3" />
            }
            <div
              className="text-fpx-red underline"
              onClick={() => navigate('/')}
            >
              {user ? t('Back to Dashboard') : t('Back to Login')}
            </div>
          </div>
        </div>
      </NavBar>
    </MessageHandling>
  )
}