import useStore from '../../../store'
import KpiSquare from './KpiSquare'
import UnitsFlooredDetails from './UnitsFlooredDetails'
import { motion } from "framer-motion"
import ReusableModal from '../../common/ReusableModal'
import {
  useDisclosure,
} from "@chakra-ui/react"

export default function MyKpis() {
  const dailyOps = useStore((state) => state.dailyOps)
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <div className="w-full py-2 px-2 lg:px-6 bg-grey-900 rounded-t">
      <div className="flex justify-center px-2 pt-1">
        <div className="flex flex-col w-full">
          <div className='lg:flex lg:flex-wrap md:flex md:flex-wrap gap-3 grid grid-cols-3 w-full justify-evenly'>
            <motion.div className='hover:cursor-pointer' onClick={onOpen} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
              <KpiSquare
                display_amount={dailyOps.current_and_repo_units_count}
                goal_percentage={dailyOps.unit_goal_percent}
                text={"Units Floored"}
                subtext={`Unit Goal: ${dailyOps.unit_goal}`}
                subsubtext={`Net ${dailyOps.todays_net_units} units`}
                maxValue={100}
                todaysCount={dailyOps.todays_floored_count}
                thisWeekCount={dailyOps.units_floored_this_week}
                thisMonthCount={dailyOps.units_floored_this_month}
              />
            </motion.div>
            <KpiSquare
              display_amount={dailyOps.naps_floored_this_month}
              goal_percentage={dailyOps.percent_nap_of_goal}
              text={"Monthly NAPs"}
              subtext={`${dailyOps.naps_until_goal} NAP until goal`}
              subsubtext={`Total NAP Count: ${dailyOps.nap_count}`}
              maxValue={100}
              todaysCount={dailyOps.naps_floored_today}
              thisWeekCount={dailyOps.nap_growth_wtd}
              thisMonthCount={dailyOps.nap_growth_mtd}
            />
            <KpiSquare
              display_amount={`${dailyOps.utilization_today}%`}
              goal_percentage={dailyOps.utilization_today}
              text={"Utilization"}
              subtext={`Target: ${dailyOps.utilization_goal}%`}
              maxValue={110}
              todaysCount={`${dailyOps.compare_util_with_yesterday}%`}
              thisWeekCount={`${dailyOps.compare_util_with_last_week}%`}
              thisMonthCount={`${dailyOps.compare_util_with_last_month}%`}
            />
          </div>
        </div>
      </div>
      <ReusableModal
        isOpen={isOpen}
        onClose={onClose}
        headerText="Units Floored-- Details"
        children={<UnitsFlooredDetails />}
      />
    </div>
  )
}
