import useStore from "../store"
import { Outlet } from "react-router-dom"
import MessageHandling from "./MessageHandling/MessageHandling"
import Login from "./Login"
import NavBar from "./NavBar/NavBar"
import tailwind from "../../tailwind.config"
import { ErrorBoundary } from "@rollbar/react"

import { useContext } from "react"
import {
  CometChatThemeContext,
} from "@cometchat/chat-uikit-react"
import ErrorDisplay from "./ErrorDisplay"

export default function Root() {
  const user = useStore((state) => state.user)
  const fpxRed = tailwind.theme.colors["fpx-red"]
  const green = tailwind.theme.colors["green"]
  const grey = tailwind.theme.colors["grey"]
  const grey100 = tailwind.theme.colors["grey-100"]
  const font = tailwind.theme.fontFamily["sans"]

  let { theme } = useContext(CometChatThemeContext)
  theme.palette.setMode("dark")
  theme.palette.setPrimary({ light: "#6851D6", dark: green })
  theme.palette.setSecondary({ light: "#6851D6", dark: grey })
  theme.palette.setAccent({ light: "#6851D6", dark: grey100 })
  theme.palette.setError({ light: "#6851D6", dark: fpxRed })
  theme.typography.setFontFamily(font)

  return(
    <CometChatThemeContext.Provider value={{ theme }}>
      <ErrorBoundary fallbackUI={ErrorDisplay}>
        <MessageHandling>
          <NavBar>
            {user ? <Outlet /> : <Login />}
          </NavBar>
        </MessageHandling>
      </ErrorBoundary>
    </CometChatThemeContext.Provider>
  )
}