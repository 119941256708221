import useStore from "../../../store"
import { useEffect } from "react"
import UnitInfo from "./UnitInfo"
import MakePayment from "../MakePayment/MakePayment"
import Spinner from "../../common/Spinner"
import PendingNAP from "./PendingNAP"
import { useNavigate } from "react-router-dom"
import UnitWarning from "./UnitWarning"
import { FaArrowCircleRight } from "react-icons/fa"
import Breadcrumbs from "../../common/Breadcrumbs"
import { useIsMobile } from "../../../hooks/useIsMobile"
import TransferPageBody from "../TransferPage/TransferPageBody"
import TransferHeader from "../TransferPage/TransferHeader"

export default function UnitPageBody({ unitId }) {
  const unit = useStore((state) => state.unit)
  const user = useStore((state) => state.user)
  const navigate = useNavigate()
  const resetUnitsAsAmSlice = useStore((state) => state.resetUnitsAsAmSlice)
  const resetDealerSlice = useStore((state) => state.resetDealerSlice)
  const dealerInState = useStore((state) => state.dealer)
  const fetchUnit = useStore((state) => state.fetchUnit)
  const isMobile = useIsMobile()

  useEffect(() => {
    fetchUnit(unitId)
  }, [unitId])

  const handleClick = () => {
    if(unit.attributes['dealer-id'] != dealerInState?.id) {
      navigate(`/dealers/${unit.attributes['dealer-id']}`)
      resetUnitsAsAmSlice()
      resetDealerSlice()
    }
  }

  return(
    <>
      {isMobile && user.type === 'AccountManager' &&
        <Breadcrumbs/>
      }
      {unit && unit.id == unitId ?
        <div className="flex flex-col items-start w-full mt-3">
          <div className="font-bold ml-2 mb-4">
            <p className="text-lg text-grey-300">{unit.attributes.year} {unit.attributes.make}</p>
            <p className= 'text-4xl text-grey-100'>{unit.attributes.model}</p>
            {user.type === 'AccountManager' &&
              <div className="flex items-center hover:cursor-pointer" onClick={handleClick}>
                {unit.attributes['dealer-name']}
                <FaArrowCircleRight className="ml-2" />
              </div>
            }
          </div>
          <div className="flex flex-col items-center w-full">
            <UnitWarning unit={unit} />
            {unit.attributes.status === 'Pending' &&
              <PendingNAP />
            }
          </div>
          <UnitInfo />
          {unit.attributes['ach-initiated']?
            <div className="w-full border-t-2 border-grey-700 bg-grey-900">
              <TransferHeader/>
              <TransferPageBody transferId={unit.attributes['pending-transfer-id']} showAltData={false}/>
            </div>
          :
            <MakePayment/>
          }
        </div>
      :
        <div className="flex items-center justify-center h-full">
          <Spinner />
        </div>
      }
    </>
  )
}