import InfoItem from "../../../common/InfoItem"
import { DateTime } from "luxon"
import useStore from "../../../../store"
import Spinner from "../../../common/Spinner"
import phoneNumber from "../../../../utilities/phoneNumber"

export default function PersonData({person, dealerId}) {
  const personFetching = useStore((state) => state.personFetching)

  return (
    <>
    {personFetching ?
      <Spinner/>
      :
      <div className="bg-grey-900 rounded border-b-2 border-grey-700">
        <div className="grid grid-cols-2 lg:grid-cols-3 w-full text-left p-2 " data-testid="people">
          <InfoItem title={"Name"} data={person?.attributes["full-name"]}/>
          <InfoItem title={"Phone"} data={phoneNumber(person?.attributes['cell-phone'])} />
          <InfoItem title={"Address"} data={person?.attributes["address"]}/>
          <InfoItem title={"State"} data={person?.attributes["state"]}/>
          <InfoItem title={"Zip"} data={person?.attributes["zip"]}/>
          <InfoItem title={"Birthday"} data={person?.attributes["birthdate"] !== null ? DateTime.fromISO(person?.attributes["birthdate"]).toLocaleString() : ''}/>
          <InfoItem title={"SSN Last 4"} data={person?.attributes["ssn-last-4"]}/>
          <InfoItem title={"Date Credit Last Pulled"} data={person?.attributes["last-credit-on"] !== null ? DateTime.fromISO(person?.attributes["last-credit-on"]).toLocaleString() : ''}/>
          <InfoItem title={"Credit Score"} data={person?.attributes["credit-score"]}/>
          <InfoItem title={'Title'} data={person?.attributes.title}/>
          <InfoItem title={'Email Address'} data={person?.attributes.email}/>
          <InfoItem title={"Driver's License State"} data={person?.attributes['dl-state']}/>
          <InfoItem title={"Driver's License Number"} data={person?.attributes['dl-number']}/>
          <InfoItem title={"Driver's License Expires"} data={person?.attributes['dl-exp-on'] !==null ? DateTime.fromISO(person?.attributes["dl-exp-on"]).toLocaleString() : ''}/>
          <InfoItem title={'Notes'} data={person?.attributes.notes}/>
        </div>
      </div>
    }
    </>
  )
}