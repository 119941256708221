import { apiUrl } from "../constants/apiUrl"
import {
  get,
  parseRequestOptionsFromJSON
} from "@github/webauthn-json/browser-ponyfill"


export default async function authenticateBiometric(webauthnId) {
  const fetchPasskeyOptions = async () => {
    try {
      const response = await fetch(`${apiUrl}/auth/passkey_options?webauthn_id=${webauthnId}`, {
          headers: { 'Content-Type': 'application/json' },
        })
      const data = await response.json()
      return data
    } catch(err) {
      return null
    }
  }

  const data = await fetchPasskeyOptions()
  
  if (data) {
    const authentication = await get(parseRequestOptionsFromJSON({ publicKey: data.options }))

    try {
      const response = await fetch(`${apiUrl}/auth/authenticate_passkey?webauthn_id=${webauthnId}&challenge_id=${data.challenge_id}`, {
        headers: { 'Content-Type': 'application/json' }, 
        method: 'POST', 
        body: JSON.stringify({ publicKeyCredential: authentication.toJSON() })
      })
      if (response.ok) {
        const data = await response.json()
        return data
      } else {
        const data = await response.json()
        return data
      }
    } catch(err) {
      return { error: err, message: 'Unable to authenticate biometric.'}
    }
  } else {
    return { error: 'error', message: 'Unable to authenticate biometric.'}
  }
}