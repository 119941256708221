import useStore from "../../../../store"
import LotDetailsForm from "./LotDetailsForm"
import { FaEdit } from "react-icons/fa"
import InfoItem from "../../../common/InfoItem"
import WarningMessage from "../../../common/WarningMessage"

export default function LotDetails() {
  const application = useStore((state) => state.application)
  const isUpdatingApplication = useStore((state) => state.isUpdatingApplication)
  const setIsUpdatingApplication = useStore((state) => state.setIsUpdatingApplication)
  const incompleteInfo = !application.attributes['lot-size'] || !application.attributes['current-inventory'] || !application.attributes['lot-ownership']

  const handleEditAttributes = () => {
    setIsUpdatingApplication(true)
  }

  return(
    <div className="h-full pl-2">
      <div className="relative flex flex-col">
      <div className="w-full text-left bg-grey-900 rounded-t pl-2 flex items-center justify-between">
          <h3 className="font-header font-semibold text-md text-green">Lot Details</h3>
          {!isUpdatingApplication && (
            <FaEdit
              className="hover:cursor-pointer text-green"
              onClick={() => handleEditAttributes()}
              data-testid="edit-dealer-info"
              color="white"
            />
          )}
        </div>
        {!isUpdatingApplication ?
          <div>
            {incompleteInfo && <WarningMessage text={"Please complete the dealer's lot details form"} />}
            {!incompleteInfo &&
              <div className="ml-4">
                <InfoItem
                  title={"How many vehicles does the dealer's lot hold?"}
                  data={application.attributes['lot-size']}
                />
                <InfoItem
                  title={'Does the dealer own or lease the lot?'}
                  data={application.attributes['current-inventory']}
                />
                <InfoItem
                  title={"Does the dealer own or lease the lot?"}
                  data={application.attributes['lot-ownership']}
                />
                <InfoItem
                  title={"Are there any other dealerships located at this address (plaza lot)?"}
                  data={ application.attributes['dealers-colocated'] === false ? "false" : (application.attributes['dealers-colocated'] || "")}
                />
              </div>
            }
            </div>
          :
          <LotDetailsForm/>
        }
      </div>
    </div>
  )
}