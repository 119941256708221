import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import useStore from "../../../store"
import Spinner from "../../common/Spinner"
import CustomerSupportBranch from "./CustomerSupportBranch"
import InfoItem from "../../common/InfoItem"
import { liveSupportEnabled } from "../../../utilities/featureFlags"

export default function CustomerSupport() {
  const { t } = useTranslation();
  const localBranch = useStore((state) => state.localBranch);
  const corporateBranch = useStore((state) => state.corporateBranch);
  const fetchLocalBranch = useStore((state) => state.fetchLocalBranch);
  const fetchCorporateBranch = useStore((state) => state.fetchCorporateBranch);

  useEffect(() => {
    fetchLocalBranch();
    fetchCorporateBranch();
  }, []);

  return (
    <div className="flex flex-col grow items-start lg:items-center overflow-y-auto">
      <div className="w-full lg:w-2/3 text-left p-2 mb-2 bg-grey-900 rounded">
        <div className="sticky top-0 left-0 bg-grey-900">
          <h3 className="font-header font-semibold text-md text-green">{t("Support")}</h3>
        </div>
        {liveSupportEnabled && (
          <InfoItem title="Live Support">
            <Link to="/chat" className="underline">
              {t("Click here to chat with someone at Floorplan Xpress")}
            </Link>
          </InfoItem>
        )}
        <InfoItem title={t("Send Email to")}>
          <a href="mailto:dealerportal@floorplanxpress.com" className="underline hover:cursor-pointer hover:opacity-75">
            dealerportal@floorplanxpress.com
          </a>
        </InfoItem>
      </div>

      {localBranch && corporateBranch ? (
        <>
          <CustomerSupportBranch branch={localBranch} header="My Branch" />
          <CustomerSupportBranch branch={corporateBranch} header="Corporate Branch" corporate={true} />
        </>
      ) : (
        <div className="flex items-center justify-center min-h-screen">
          <Spinner />
        </div>
      )}
    </div>
  );
}
