import useStore from "../../../store"
import { useState, useEffect } from "react"
import { NeutralButton } from "../../common/Buttons"
import formatter from "../../../constants/currencyFormatter"
import { useTranslation } from "react-i18next"
import { FaUndo } from "react-icons/fa"
import BankAccountSelect from "../MakePayment/BankAccountSelect"
import SwipeAnimation from "../../common/SwipeAnimation"
import { napBankPayoffEnabled } from "../../../utilities/featureFlags"

export default function FloorAmount() {
  const { t } = useTranslation()
  const blackbook = useStore((state) => state.NAPBlackbook)
  const [inputError, setInputError] = useState(null)
  const [bankAccountError, setBankAccountError] = useState(null)
  const floorAmount = useStore((state) => state.NAPFloorAmount)
  const setFloorAmount = useStore((state) => state.setNAPFloorAmount)
  const bankAccounts = useStore((state) => state.bankAccounts)
  const fetchBankAccounts = useStore((state) => state.fetchBankAccounts)
  const NAPBankAccount = useStore((state) => state.NAPBankAccount)
  const setNAPBankAccount = useStore((state) => state.setNAPBankAccount)
  const NAPRequiresBankPayoff = useStore((state) => state.NAPRequiresBankPayoff)
  const setNAPRequiresBankPayoff = useStore((state) => state.setNAPRequiresBankPayoff)
  const nextStep = useStore((state) => state.nextStep)

  useEffect(() => {
    if (bankAccounts.length === 0) {
      fetchBankAccounts()
    }
  }, [])

  const moveCaret = (e) => {
    if (e.key?.startsWith('Arrow')) {
      e.preventDefault()
    }

    e.target.setSelectionRange(-1, -1)
  }

  const handleChange = (e) => {
    if (e.target.value.length < 14) {
      setInputError(null)
      // Removes anything that isnt a number
      let amount = e.target.value.replace(/[^0-9]/g, '')
      // Adds decimal point
      amount = amount.slice(0, -2) + '.' + amount.slice(-2)
      setFloorAmount(amount)
    } else {
      setInputError(t('Reached maximum number of digits'))
    }
  }

  const handleSubmit = () => {
    if (Number(floorAmount) === 0) {
      setInputError(t('Amount must be greater than 0'))
    } else if (!NAPRequiresBankPayoff && !NAPBankAccount) {
      setBankAccountError(t('Bank account must be selected'))
    } else {
      nextStep()
    }
  }

  const resetFloorAmount = () => {
    setFloorAmount('.00')
    setInputError(null)
  }

  const textStyles = () => {
    if (inputError) {
      return('text-fpx-red')
    } else {
      return('text-grey-300')
    }
  }

  const inputStyles = () => {
    if (inputError) {
      return('text-fpx-red border-fpx-red focus:border-fpx-red focus:ring-fpx-red')
    } else {
      return('text-grey-100 border-grey-900 focus:border-grey-300 focus:ring-grey-300')
    }
  }

  return(
    <SwipeAnimation>
      <div className="flex flex-col items-center justify-between pt-6 rounded w-full h-full">
        <div>
          <div className="text-2xl">{t('Requested Floor Amount')}</div>
          <div className="flex flex-col items-center my-2 text-grey-300">
            <div>
              {`${blackbook.model_year} ${blackbook.make} ${blackbook.model}`}
            </div>
            <div>
              {blackbook.vin}
            </div>
          </div>
        </div>

        <div className="relative flex flex-col items-center w-full">
          <label
            className="relative text-sm text-grey-300"
            onClick={() => setInputError(null)}
          >
            {floorAmount !== '.00' &&
              <FaUndo
                className={`absolute right-3 top-3 hover:cursor-pointer ${textStyles()}`}
                onClick={resetFloorAmount}
                data-testid='reset-floor-amount'
              />
            }

            <input
              value={formatter.format(floorAmount)}
              type="tel"
              name="vin"
              title="vin"
              className={`flex justify-right w-full text-6xl text-center py-24 bg-grey-900
              border-2 rounded ${inputStyles()}`}
              onChange={handleChange}
              onClick={moveCaret}
              onKeyDown={moveCaret}
              data-testid='floor-amount-input'
            />
          </label>

          {napBankPayoffEnabled &&
            <label
              className="flex items-center mt-3 w-3/4"
            >
              <input
                type='checkbox'
                checked={NAPRequiresBankPayoff}
                className='w-4 h-4 mr-2 text-green bg-grey-300 border-grey-300 rounded focus:ring-green dark:focus:ring-blue-600 dark:ring-offset-0 dark:focus:ring-offset-0 focus:ring-0 dark:bg-grey dark:border-grey'
                onChange={(e) => setNAPRequiresBankPayoff(e.target.checked)}
                data-testid="bankpayoff-checkbox"
              />
                {t('This unit requires payment to a bank or third party seller')}
            </label>
          }

            <div
              className={`w-3/4 my-3 ${NAPRequiresBankPayoff && 'invisible'}`}
              onClick={() => setBankAccountError(null)}
              data-testid='account-select-container'
            >
              <BankAccountSelect
                selectedAccount={NAPBankAccount}
                setSelectedAccount={setNAPBankAccount}
                isErrored={bankAccountError}
                isSelectDefaultAccountEnabled={!NAPBankAccount}
              />
            </div>

          <div className="flex flex-col text-center h-10">
            {inputError && <span className={textStyles()}>{inputError}</span>}
            {bankAccountError && <span className='text-fpx-red'>{bankAccountError}</span>}
          </div>
        </div>

        <NeutralButton
          text={t("Confirm")}
          onClick={handleSubmit}
          style="my-4 text-xl"
        />
      </div>
    </SwipeAnimation>
  )
}