import FilterByDrawer from "../../common/FilterByDrawer"
import LocationFilter from "./DealerTableFiltering/LocationFilter"
import FilterOptions from "./DealerTableFiltering/FilterOptions"
import FilterButtons from "./DealerTableFiltering/FilterButtons"
import useStore from "../../../store"

export default function DealerTableFilterDrawer({isOpen, onClose, setSearchTerm, resultsCount, resultsFetching, setFilterParams, filterParams}) {
  const dealerTableFilterParams = useStore((state) => state.dealerTableFilterParams)
  const setDealerTableFilterParams = useStore((state) => state.setDealerTableFilterParams)
  const userLocation = useStore((state) => state.userLocation)
  const setDealerFilterAttribute = useStore((state) => state.setDealerFilterAttribute)
  const setDealerFilterDirection = useStore((state) => state.setDealerFilterDirection)
  const nearbyDealersFetching = useStore((state) => state.nearbyDealersFetching)
  const dealerResultsCount = useStore((state) => state.dealerPaginationData.dealerResultsCount)

  const handleFilterByRadius = (item) => {
    if(item.id && userLocation) {
      setFilterParams({
        ...filterParams,
        "filter[distance]": item.id,
        "filter[lat]": userLocation.latitude,
        "filter[lng]": userLocation.longitude,
      })
    }
  }

  const handleResetFilter = () => {
    setDealerFilterDirection('')
    setSearchTerm('')
    setFilterParams('')
    setDealerFilterAttribute('default')
    onClose()
  }

  return(
    <FilterByDrawer
    isOpen={isOpen}
    onClose={onClose}
    title="Filter Dealers"
    bodyContent={
      <>
        <LocationFilter
          handleFilterByRadius={handleFilterByRadius}
          dealerTableFilterParams={filterParams}
        />
        <FilterOptions
          setFilterParams={setFilterParams}
          filterParams={filterParams}
        />
      </>
    }
    footerContent={
      <FilterButtons
        handleResetFilter={handleResetFilter}
        handleSubmit={() => onClose()}
        resultsCount={dealerResultsCount}
        resultsFetching={nearbyDealersFetching}
      />
    }
  />
  )
}