import useStore from "../../store"

export default function OfflineAlert() {
  const isOnline = useStore((state) => state.isOnline)

  if (isOnline) {
    return(null)
  } else {
    return(
      <div className='flex justify-center items-center px-1 w-12 bg-yellow m-2 text-xs text-black rounded-full'>
        Offline
      </div>
    )
  }
}
