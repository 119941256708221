import { apiUrlV2 } from "../constants/apiUrl"

const initialState = {
  pushNotificationToken: null,
}

const createPushNotificationsSlice = (set, get) => ({
  createPushNotificationDevice: async (body) => {
    try {
      const response = await fetch(
        `${apiUrlV2}/push_notification_devices`,
        {
          headers: get().requestHeaders,
          method: 'POST',
          body: JSON.stringify(body)
        }
      )
      if (response.ok) {
        get().setSuccessMessage('Successfully registered device for push notifications')
      }
    } catch (err) {
      get().setErrMessage('There was an error while registering for push notifications')
      console.log(err)
    }
  },
  setPushNotificationToken: (token) => {
    set((state) => ({ pushNotificationToken: token}))
  },
  resetPushNotificationsSlice: () => set(initialState)
})

export default createPushNotificationsSlice