import { Box } from "@chakra-ui/react"
import SelectDropdown from "../../../common/SelectDropdown"

export default function LocationFilter({dealerTableFilterParams, handleFilterByRadius}) {
  const radiusOptions = [
    {id: '5', name: '5 miles'},
    {id: '10', name: '10 miles'},
    {id: '20', name: '20 miles'},
    {id: '30', name: '30 miles'},
    {id: '50', name: '50 miles'},
    {id: '100', name: '100 miles'},
  ]

  return(
    <Box p={4}>
      <h4 className="font-semibold text-lg mb-2">Near Me</h4>
      <SelectDropdown
        options={radiusOptions}
        defaultText={'Select radius'}
        selectedItem={radiusOptions.find((item) => item.id === dealerTableFilterParams['filter[distance]'] || null)}
        setSelectedItem={(item) => handleFilterByRadius(item)}
        displaySelectedItem={(item) => item?.name }
        isErrored={false}
      />
    </Box>
  )
}