import { apiUrl, apiUrlV2 } from "../constants/apiUrl"

const initialState = {
  businessDevData: null,
  businessDevTableData: [],
  businessDevTableFetching: false,
  businessDevTableFilterAttribute: '',
  businessDevTableFilterDirection: '',
  businessDevTablePage: 1,
  paginationData: {}
}

const createBusinessDevSlice = (set, get) => ({
  ...initialState,
  fetchBusinessDevData: async () => {
    try {
      const response = await fetch(
        `${apiUrlV2}/business_dev`,
        {headers: get().requestHeaders}
      )
      if (response.status === 200) {
        const data = await response.json()

        if(get().user) {
          set((state) => ({ businessDevData: data }))
        }
      }
    } catch (err) {
      get().setErrMessage('Data was unable to be fetched. Please refresh to try again')
    }
  },
  setBusinessDevTableFilterAttribute: (attribute) => {
    set((state) => ({ businessDevTableFilterAttribute: attribute}))
  },
  setBusinessDevTableFilterDirection: (direction) => {
    set((state) => ({ businessDevTableFilterDirection: direction}))
  },
  setBusinessDevTableParam: (tableParam) => {
    set((state) => ({ businessDevTableParam: tableParam}))
  },
  setBusinessDevTableType: (tableType) => {
    set((state) => ({ businessDevTableType: tableType}))
  },
  setBusinessDevTablePage: (page) => {
    set((state) => ({businessDevTablePage: page}))
  },
  setBusinessDevTableName: (name) => {
    set((state) => ({businessDevTableName: name}))
  },
  fetchBusinessDevTableData: async () => {
    set((state) => ({ businessDevTableFetching: true}))
    const currentbusinessDevTableParam = get().businessDevTableParam
    try {
      const response = await fetch(
        `${apiUrlV2}/${get().businessDevTableType}?per_page=20&page=${get().businessDevTablePage}&${get().businessDevTableParam}&${get().businessDevTableFilterAttribute}&${get().businessDevTableFilterDirection}`,
        {headers: get().requestHeaders}
      )
      if (response.status === 200 && get().businessDevTableParam === currentbusinessDevTableParam) {
        const data = await response.json()

        if(get().user) {
          set((state) => ({
            businessDevTableData: data.data,
            businessDevTableFetching: false,
            paginationData: {
              dataTotal: response.headers.get('x-total'),
              lastPage: response.headers.get('x-last-page'),
              totalPages: response.headers.get('x-total-pages'),
              currentPage: Number(response.headers.get('x-page')) - 1
            }
          }))
        }
      }
    } catch (err) {
      get().setErrMessage('Data was unable to be fetched. Please refresh to try again')
    }
  },
  resetBusinessDevSlice: () => set(initialState)
})

export default createBusinessDevSlice