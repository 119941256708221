import useStore from "../../../store"
import { useEffect, useState, useRef } from "react"
import ClickableRow from "../../common/ClickableRow"
import Spinner from "../../common/Spinner"
import { HappyButton } from "../../common/Buttons"
import { useTranslation } from "react-i18next"

export default function UnitTitleDownload({ unit }) {
  const fetchUnitTitle = useStore((state) => state.fetchUnitTitle)
  const setErrMessage = useStore((state) => state.setErrMessage)
  const pdfDownloadRef = useRef(null)
  const [fetchingTitle, setFetchingTitle] = useState(false)
  const [pdfUrl, setPdfUrl] = useState('')
  const { t } = useTranslation()

  useEffect(() => {
    if (pdfDownloadRef && pdfUrl) {
      pdfDownloadRef?.current?.click()
      window.URL.revokeObjectURL(pdfUrl)
      setPdfUrl(null)
    }
  }, [pdfUrl])

  const handleDownloadTitle = async () => {
    setFetchingTitle(true)
    try {
      const response = await fetchUnitTitle(unit.id)
      if (response.status == 200) {
        const data = await response.blob()
        const url = window.URL.createObjectURL(data)
        setPdfUrl(url)
      } else {
        setErrMessage(t('Unable to retrieve title at this time'))
      }
    } catch(err) {
      setErrMessage(t('Unable to retrieve title at this time'))
    } finally {
      setFetchingTitle(false)
    }
  }

  return(
    <>
      <HappyButton
        text={fetchingTitle ? <Spinner color="green" /> : t("Download Title")}
        onClick={handleDownloadTitle}
        style="my-2 mx-3"
      />
      <a
        className="display-none"
        download={`title-${unit.attributes.vin}.pdf`}
        href={pdfUrl}
        ref={pdfDownloadRef}
        data-testid='download-title-link'
      />
    </>
  )
}