import useStore from "../../store"
import { useNavigate } from "react-router-dom"
import { FaChevronRight } from "react-icons/fa"
import { GoDotFill } from "react-icons/go"
import { DateTime } from "luxon"

export default function NotificationRow({ notification, onClick }) {
  const navigate = useNavigate()
  const markNotificationAsRead = useStore((state) => state.markNotificationAsRead)
  const isUnread = notification.attributes['read-at'] === null
  const setNotification = useStore((state) => state.setNotification)

  const handleClick = () => {
    markNotificationAsRead(notification.id)
    setNotification(notification)
    navigate(`/notifications/${notification.id}`)
  }

  return(
    <div
      className={`${isUnread ? "text-grey-100" : "text-grey"} flex w-full items-center justify-between text-sm py-3 px-2 hover:cursor-pointer lg:hover:bg-grey-700 border-b-2 border-grey-700`}
      key={notification.id}
      onClick={() => handleClick()}
      data-testid='notifications-row'
    >
      <div className="flex items-center justify-center w-7">
        {isUnread &&
          <GoDotFill
            className='text-xl text-fpx-red bg-black rounded-full'
            data-testid="notifications-alert"
          />
        }
      </div>
      <div className="flex flex-col truncate w-full pr-1">
        <div className="flex justify-between text-sm text-grey-300 w-full">
          <div className="w-3/4 truncate">{notification.attributes.title}</div>
          {DateTime.fromISO(notification.attributes["created-at"]).toRelative({ style: "short" })}
        </div>
        <div className="mr-10 truncate text-ellipsis">
          {notification.attributes.body}
        </div>
      </div>
      <FaChevronRight />
    </div>
  )
}