import { useTranslation } from "react-i18next"
import BranchAddress from "./BranchAddress"
import InfoItem from "../../common/InfoItem"
import { parsePhoneNumber } from "libphonenumber-js"

export default function CustomerSupportBranch({ branch, header, corporate = false }) {
  const { t } = useTranslation()

  return(
    <div className="w-full lg:w-2/3 text-left p-2 mb-2 bg-grey-900 rounded">
      <div className="sticky top-0 left-0 bg-grey-900">
        <h3 className="font-header font-semibold text-md text-green">{t(header)}</h3>
      </div>
      <div>
        {!corporate &&
          <InfoItem
            title={t('Branch Name')}
            data={branch.attributes.name}
          />
        }

        <BranchAddress branch={branch} />

        {branch.attributes.phone ?
          <InfoItem
            title={t('Phone')}
            data={
              <a
                href={`tel:${branch.attributes.phone}`}
                className="underline"
              >
                {parsePhoneNumber(branch.attributes.phone, 'US').formatNational()}
              </a>
            }
          /> :
          <InfoItem
            title={t('Phone')}
            data={'None'}
          />
        }

        <InfoItem
          title={t('Hours')}
          data={`${t('M-T')}: ${branch.attributes['normal-hours']}`}
          subdata={`${t('F')}: ${branch.attributes['friday-hours']}`}
        />
      </div>
    </div>
  )
}