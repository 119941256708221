import { apiUrl, apiUrlV2 } from "../constants/apiUrl"

const initialState = {
  displayCoupons: true,
  couponInfoPageId: null,
  isFetchingCoupons: false,
  isFetchingCoupon: false,
  coupons: [],
  coupon: null,
  couponRedemptions: [],
  isCreatingCouponRedemption: false,
  isFetchingRedeemedCoupons: false,
  currentSlide: 1
}

const createCouponsSlice = (set, get) => ({
  ...initialState,
  setDisplayCoupons: (boolean) => {
    set((state) => ({displayCoupons: boolean}))
  },
  setCurrentSlide: (slide) => set({ currentSlide: slide }),
  fetchCoupons: async () => {
    set(() => ({ isFetchingCoupons: true }))
    try {
      const response = await fetch(
        `${apiUrlV2}/coupons`,
        { headers: get().requestHeaders }
      )
      const data = await response.json()

      set(() => ({ coupons: data.data }))
    } catch (err) {
      console.log(err)
      get().setErrMessage('Unable to retrieve coupons at this time.')
    } finally {
      set(() => ({ isFetchingCoupons: false }))
    }
  },
  fetchCoupon: async (couponId) => {
    set(() => ({ isFetchingCoupon: true }))
    try {
      const response = await fetch(
        `${apiUrlV2}/coupons/${couponId}`,
        { headers: get().requestHeaders }
      )
      const data = await response.json()

      set(() => ({ coupon: data.data }))
    } catch (err) {
      console.log(err)
      get().setErrMessage('Unable to retrieve coupon at this time.')
    } finally {
      set(() => ({ isFetchingCoupon: false }))
    }
  },
  createCouponRedemption: async (couponId, dealerId, optedIn) => {
    set(() => ({ isCreatingCouponRedemption: true }))

    set((state) => ({
      coupons: state.coupons.filter(coupon => coupon.id !== couponId)
    }))

    try {
      const response = await fetch(
        `${apiUrlV2}/coupon_redemptions`,
        {
          headers: get().requestHeaders,
          method: 'POST',
          body: JSON.stringify({dealer_id: dealerId, coupon_id: couponId, opted_in: optedIn})
         }
      )
      const data = await response.json()

      if (response.status === 200) {
        const successMessage = optedIn ? 'Successfully opted in' : 'Successfully opted out'
        get().setSuccessMessage(successMessage)
      }
    } catch (err) {
      console.log(err)
      get().setErrMessage('Unable to opt in.')
    } finally {
      set(() => ({ isCreatingCouponRedemption: false }))
    }
  },
  fetchCouponRedemptions: async () => {
    set(() => ({ isFetchingRedeemedCoupons: true }))
    try {
      const response = await fetch(
        `${apiUrlV2}/coupon_redemptions`,
        { headers: get().requestHeaders }
      )
      const data = await response.json()
      set(() => ({ couponRedemptions: data.data}))
    } catch (err) {
      console.log(err)
      get().setErrMessage('Unable to fetch redeemed coupons')
    } finally {
      set(() => ({ isFetchingRedeemedCoupons: false }))
    }
  },
  setCouponInfoPageId: (id) => set(() => ({ couponInfoPageId: id })),
  resetCouponsSlice: () => set(initialState)
})

export default createCouponsSlice