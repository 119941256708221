import { useParams } from "react-router-dom"
import { useEffect } from "react"
import useStore from "../../../store"
import { useIsMobile } from "../../../hooks/useIsMobile"
import { Image, Button } from "@chakra-ui/react"
import { useState } from "react"
import tailwind from "../../../../tailwind.config"
import Spinner from "../../common/Spinner"

export default function CouponInfoPage({couponId}) {
  const id = useParams()
  const fetchCoupon = useStore((state) => state.fetchCoupon)
  const coupon = useStore((state) => state.coupon)
  const createCouponRedemption = useStore((state) => state.createCouponRedemption)
  const dealer = useStore((state) => state.dealer)
  const couponWithId = couponId || id.id
  const setCouponInfoPageId = useStore((state) => state.setCouponInfoPageId)
  const isMobile = useIsMobile()
  const fetchCoupons = useStore((state) => state.fetchCoupons)
  const fpxRed = tailwind.theme.colors["fpx-red"]
  const green = tailwind.theme.colors["green"]
  const [disabled, setDisabled] = useState(false)
  const isFetchingCoupon = useStore((state) => state.isFetchingCoupon)

  useEffect(() => {
    if (couponWithId !== coupon?.id) {
      fetchCoupon(couponWithId)
    }
  }, [couponWithId, coupon])

  const handleCouponClick = async (optedInValue) => {
    setDisabled(true)
    setCouponInfoPageId(null)
    if (isMobile) {
      window.history.back()
    }
    await createCouponRedemption(couponWithId, dealer.id, optedInValue)
    fetchCoupons()
  }

  return(
    <div className='flex grow overflow-y-auto w-full justify-center'>
      {!isFetchingCoupon ?
        <div className="flex flex-col items-start w-full mt-3">
          {coupon?.attributes['flyer-photo-url'] && (
            <div className="flex justify-center w-full mt-4">
              <Image
                src={coupon?.attributes['flyer-photo-url']}
                alt="Coupon Banner"
                borderRadius="md"
                maxW={{ base: "100vw", md: "85vw" }}
                maxH={{ base: "100vh", md: "75vh" }}
                objectFit="contain"
                width="100%"
                height="100%"
                pr={2}
                pl={2}
              />
            </div>
          )}
          <div className="flex w-full justify-center my-2 mt-4 pr-2 gap-10">
            <Button
              variant="outline"
              disabled={disabled}
              border={'2px'}
              bg="transparent"
              onClick={() => handleCouponClick(false)}
              sx={{
                borderRadius: 'full',
                borderColor: fpxRed,
                color: 'white'
              }}
            >
              Opt Out
            </Button>
            <Button
              variant="outline"
              disabled={disabled}
              border={'2px'}
              bg="transparent"
              onClick={() => handleCouponClick(true)}
              sx={{
                borderRadius: 'full',
                borderColor: green,
                color: 'white'
              }}
            >
              Opt In
            </Button>
          </div>
        </div>
      :
      <div className="flex flex-col items-center w-full mt-3">
        <Spinner/>
      </div>
      }
    </div>
  )
}