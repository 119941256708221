import {
  extendTheme,
  Tabs, TabList, TabPanels, Tab, TabPanel,
  Table,Thead, Tbody, Tfoot, Tr, Th,
  Td, TableCaption, TableContainer,
  Input, Image, Select, CloseButton,
  AlertDialog, AlertDialogBody, AlertDialogFooter,
  AlertDialogHeader, AlertDialogContent, AlertDialogOverlay,
  AlertDialogCloseButton, Alert, AlertIcon, AlertTitle,
  AlertDescription, VStack,
  Box, FormControl, FormLabel, RadioGroup,
  Radio, Button, Heading, Stack, Breadcrumb,
  Card, CardHeader, CardBody, CardFooter, Flex, Textarea
} from '@chakra-ui/react'

const config = {
  initialColorMode: 'dark',
  useSystemColorMode: true,
}

export const chakraTheme = extendTheme({
  config,
  fonts: {
    body: 'Roboto, sans-serif',
    header: 'Trebuchet MS, sans-serif'
  },
  colors: {
    'green': '#2dc937'
  },
  styles: {
    global: (props) => ({
      body: {
        bg: '#000000',
        color: '#FFFFFF'
      },
    }),
  },
  components: {
    Breadcrumb, Tabs, TabList, TabPanels,
    Tab, TabPanel, Table, Thead, Tbody,
    Tfoot, Tr, Th, Td, TableCaption,
    TableContainer, Flex,
    Image, Button, Select, CloseButton,
    AlertDialog, AlertDialogBody, AlertDialogFooter,
    AlertDialogHeader, AlertDialogContent, AlertDialogOverlay,
    AlertDialogCloseButton, Alert, AlertIcon, AlertTitle,
    AlertDescription,
    Box, FormControl, FormLabel, Input,
    RadioGroup, Radio,
    Heading, Stack, VStack,
    Card, CardHeader, CardBody, CardFooter, Textarea
  },
})