import { useTranslation } from "react-i18next"
import formatAccountNumber from "../../utilities/accountNumber"

export default function BankAccountName({ account, displayBank = false }) {
  const { t } = useTranslation()

  return(
    <div>
      {account.attributes['name-personal']}
      <div className="text-sm text-grey-300">
        {displayBank && account.attributes['bank-name']}
      </div>
      <div className="text-sm text-grey-300">
        <span className="mr-1">
          {t(account.attributes['account-type'])}
        </span>
        {formatAccountNumber(account.attributes['account-number'])}
      </div>
    </div>
  )
}