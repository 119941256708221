import dailyOpColor from "../../../utilities/dailyOpColor"
import MobileKpiSquare from "./MobileKpiSquare"
import DesktopKpiSquare from "./DesktopKpiSquare"

export default function KpiSquare({display_amount, goal_percentage, text, subtext, subsubtext, maxValue, thisMonthCount, thisWeekCount, todaysCount}) {

  return (
    <div className={`min-[1400px]:border-0 min-[1400px]:bg-grey-900 md:border-0 md:bg-grey-900 border-2 rounded-md bg-black h-24 min-[1400px]:m-4 min-[1400px]:h-fit md:m-4 md:h-fit flex items-center justify-center relative flex-col ${dailyOpColor(goal_percentage)}`}>
        <div className="min-[1400px]:hidden md:hidden" data-testid="mobile-div">
          <MobileKpiSquare
            text={text}
            goal_percentage={goal_percentage}
            display_amount={display_amount}
          />
        </div>
      <div className="hidden min-[1400px]:block md:block" data-testid="desktop-div">
        <DesktopKpiSquare
          text={text}
          goal_percentage={goal_percentage}
          display_amount={display_amount}
          subtext={subtext}
          subsubtext={subsubtext}
          maxValue={maxValue}
          thisMonthCount={thisMonthCount}
          thisWeekCount={thisWeekCount}
          todaysCount={todaysCount}
        />
      </div>
    </div>
  )
}
