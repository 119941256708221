import { DateTime } from "luxon"

export const isComingDue = (unit) => unit?.attributes['due-in'] - unit?.attributes['days-floored'] < 7

export const isPastDue = (unit) => unit?.attributes['days-floored'] > unit?.attributes['due-in']

export const soldUnitDueDate = (unit) => DateTime.fromFormat(unit?.attributes['sold-on'], 'MM/dd/yyyy').plus({ days: 10 }).toFormat('ccc M/d')

export const dueOn = (unit) => DateTime.fromISO(unit.attributes['due-on']).toFormat('ccc M/d')

export const isReviewDue = (lastReviewDate) => {
  return lastReviewDate && DateTime.now() > DateTime.fromISO(lastReviewDate).plus({ years: 1 })
}