import { Oval } from 'react-loader-spinner'
import tailwind from '../../../tailwind.config.js'

export default function Spinner({ color = "fpx-red", height = "40" }) {
  return (
    <Oval 
      height={height}
      color={tailwind.theme.colors[color]}
      secondaryColor={tailwind.theme.colors[color]}
      strokeWidth={5}
      strokeWidthSecondary={5}
    /> 
  )
}