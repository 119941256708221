import formatter from "../../../constants/currencyFormatter"

export default function PaymentNumberInput({ amount, setAmount, warning }) {
  const moveCaret = (e) => {
    if (e.key?.startsWith('Arrow')) {
      e.preventDefault()
    }

    e.target.setSelectionRange(-1, -1)
  }

  const handleChange = (e) => {
    // Removes anything that isnt a number
    let amount = e.target.value.replace(/[^0-9]/g, '')
    // Adds decimal point
    amount = amount.slice(0, -2) + '.' + amount.slice(-2)
    setAmount(amount)
  }

  return(
    <>
      <input
        value={formatter.format(amount)}
        type="tel"
        name="payment amount"
        title="payment amount"
        className={`px-1 w-full bg-grey-900 border-2 ${warning ? 'border-yellow focus:border-yellow' : 'border-grey-700 focus:border-grey-300'} focus:ring-0 rounded`}
        onChange={handleChange}
        onClick={moveCaret}
        onKeyDown={moveCaret}
        data-testid='payment-amount-input'
      />
      {warning && <div className="text-yellow">{warning}</div>}
    </>
  )
}