import { Button } from "@chakra-ui/react"
import useStore from "../../../../store"
import FilterIndicator from "../../../common/FilterIndicator"

export default function FilterIndicators({setDealerTableFilterParams, onClose, dealerTableFilterParams, selectedUtilFilterName}) {
  const selectedDealerScopeLabel = useStore((state) => state.selectedDealerScopeLabel)

  const updateFilterParams = (paramsToRemove) => {
    const updatedFilterParams = { ...dealerTableFilterParams }
    paramsToRemove.forEach(param => delete updatedFilterParams[param])
    setDealerTableFilterParams(updatedFilterParams)
  }

  const handleRemoveUtilFilter = () => {
    updateFilterParams(['utilization[min]', 'utilization[max]'])
    onClose()
  }

  const handleResetRadius = () => {
    updateFilterParams(['filter[distance]', 'filter[lat]', 'filter[lng]'])
    onClose()
  }

  const handleResetDealerScope = () => {
    updateFilterParams(['scope'])
    onClose()
  }

  return(
    <div className="flex justify-start w-full gap-2 mb-2 mt-2">
      <FilterIndicator
        filterName={selectedUtilFilterName}
        displayFor={dealerTableFilterParams['utilization[min]'] && dealerTableFilterParams['utilization[max]']}
        onClick={handleRemoveUtilFilter}
      />
      <FilterIndicator
        filterName={`${dealerTableFilterParams['filter[distance]']} miles`}
        displayFor={dealerTableFilterParams['filter[distance]']}
        onClick={handleResetRadius}
      />
      <FilterIndicator
        filterName={selectedDealerScopeLabel}
        displayFor={dealerTableFilterParams['scope']}
        onClick={handleResetDealerScope}
      />
    </div>
  )
}