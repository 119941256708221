import { Td, Tr } from "@chakra-ui/react"
import { DateTime } from "luxon"

export default function FileRow ({file, onClick}) {
  return(
    <>
      <Tr onClick={onClick} className="hover:cursor-pointer lg:hover:bg-grey-700 ">
        <Td className="w-1/4 overflow-hidden text-ellipsis whitespace-nowrap max-w-xs">{file.attributes.name ?? ''}</Td>
        <Td className="w-1/4 overflow-hidden text-ellipsis whitespace-nowrap max-w-xs">{file.attributes.folder.data?.attributes['directory-name'] ?? 'Root Folder'}</Td>
        <Td className="w-1/4 overflow-hidden text-ellipsis whitespace-nowrap max-w-xs">{DateTime.fromISO(file.attributes['created-at']).toLocaleString()}</Td>
        <Td className="w-1/4 overflow-hidden text-ellipsis whitespace-nowrap max-w-xs">{file.attributes['created-by-name'] ?? 'N/A'}</Td>
      </Tr>
    </>
  )
}