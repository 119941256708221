import { DateTime } from "luxon"
import useStore from "../../../store"
import { useNavigate } from "react-router-dom"

export default function RiskAuditRow({fieldAudit}) {
  const resetUnitsAsAmSlice = useStore((state) => state.resetUnitsAsAmSlice)
  const resetDealerSlice = useStore((state) => state.resetDealerSlice)
  const navigate = useNavigate()

  const handleClick = () => {
    resetUnitsAsAmSlice()
    resetDealerSlice()
    navigate(`/dealers/${fieldAudit.attributes.dealer.id}`)
  }

  return(
    <tr key={fieldAudit.id} onClick={handleClick} className={`flex px-2 py-2 border-b-2 border-grey-700 w-full hover:cursor-pointer lg:hover:bg-grey-700`}>
      <td>
        <div className={`w-full text-sm flex gap-2 text-grey-300`}>
          {DateTime.fromISO(fieldAudit.attributes['date-completed']).toLocaleString()}
        </div>
        <div className={'w-full text-lg text-grey-100'}>
          {fieldAudit.attributes.dealer.lotname ? (fieldAudit.attributes.dealer.lotname) : 'No Name'}
        </div>
        <div className={'w-full text-xs text-grey-300'}>
          {fieldAudit.attributes.status}
        </div>
      </td>
    </tr>
  )
}