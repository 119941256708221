import DealerRow from "../Dealers/DealerRow"
import AppRow from "../Apps/AppRow"
import NoDealersFound from "../Dealers/NoDealersFound"
import NoAppsFound from "../Apps/NoAppsFound"
import useStore from "../../../store"

export default function BusinessDevTableBody({ searchTerm, data, tableType, param }) {
  const businessDevTableFetching = useStore((state) => state.businessDevTableFetching)
  const dataType = data?.length > 0 ? data[0].type : null

  if (tableType === 'dealers' && dataType === 'dealers') {
    return (
      <tbody>
        {data.map((dealer) => (
          <DealerRow dealer={dealer} key={dealer.id} filter={searchTerm} metrics={false} businessDevTableData={true} tableType={tableType} param={param}/>
        ))}
      </tbody>
    )
  } else if (tableType === 'apps' && dataType === 'apps') {
    return (
      <tbody>
        {data.map((app) => (
          <AppRow app={app} key={app.id} filter={searchTerm} />
        ))}
      </tbody>
    )
  } else if (businessDevTableFetching) {
    return <div className="h-44" data-testid="businessdev-placeholder" />
  } else {
    return(
      <div className="absolute w-full right-[16px]">
        {tableType === 'dealers' ? <NoDealersFound/> : <NoAppsFound/>}
      </div>
    )
  }
}