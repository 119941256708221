import { useEffect } from "react"
import useStore from "../../../store"
import ReminderRow from "./ReminderRow"

export default function Reminders() {
  const fetchReminders = useStore((state) => state.fetchReminders)
  const reminders = useStore((state) => state.reminders)

  useEffect(() => {
    fetchReminders()
  }, [])

  return(
    <div className="flex flex-col overflow-y-auto grow lg:min-h-0 w-full justify-left items-center">
      <div className="sticky top-0 left-0 z-30 w-full lg:w-1/2 bg-grey-900 rounded-t">
        <div className="flex w-full items-center pt-2 pb-3 px-2">
          <h3 className="font-header font-semibold text-md text-green mr-1">
            Reminders
          </h3>
        </div>
      </div>
      <div className="flex flex-col items-center w-full lg:w-1/2 lg:h-full bg-grey-900 rounded-b">
        {reminders &&
          reminders.map((r) => {
            return(
              <ReminderRow key={r.id} reminder={r} />
            )
          })
        }
      </div>
    </div>
  )
}