import formatter from "../../../constants/currencyFormatter"
import HighlightMatch from "../../../utilities/HighlightMatch"
import { Payoff, Refloor, OtherPayment } from "../../common/PayoffRefloorPills"
import { FaEllipsisV } from "react-icons/fa"
import { DateTime } from "luxon"

export default function TransferRow({ date, type, unitName, vin, amount, filter, isExpandable = false, onExpand = null, onClick = null }) {
  const transferDate = DateTime.fromISO(date)

  const transferType = () => {
    switch(type) {
      case 'Refloor':
        return <Refloor />
      case 'Payoff':
        return <Payoff />
      default:
        return <OtherPayment type={type} />
    }
  }

  const isVinMatched = () => filter && vin?.toLowerCase()?.includes(filter.toLowerCase())

  return(
    <div
      className={`flex w-full items-center justify-between text-sm py-1 px-2 ${!isExpandable && 'lg:hover:bg-grey-700 hover:cursor-pointer'} border-b-2 border-grey-700`}
      onClick={onClick}
      data-testid="transfer-row"
    >
      <div className="flex items-center">
        <div className="mr-2">
          {transferType()}
        </div>
        {<HighlightMatch
          str={transferDate.toFormat('M/d/yy')}
          match={filter}
        />}
      </div>
      <div className="flex justify-center text-center p-2 truncate">
        {isVinMatched() ?
          <HighlightMatch str={vin} match={filter} truncate={true} /> :
          <HighlightMatch str={unitName} match={filter} truncate={true}/>
        }
      </div>
      <div className="flex justify-between items-center pl-4">
        <div className="flex justify-right items-center ">
          {<HighlightMatch str={formatter.format(amount)} match={filter} />}
        </div>
        {isExpandable &&
          <div
            onClick={onExpand}
            className="ml-2"
          >
            <FaEllipsisV
              className="hover:cursor-pointer"
              />
          </div>
        }
      </div>
    </div>
  )
}