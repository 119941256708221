import { useNavigate, useLocation } from "react-router-dom"
import useStore from "../../store"

export default function SideBarItem({link, text, icon}) {
  const navigate = useNavigate()
  const setShowSidebar = useStore((state) => state.setShowSidebar)
  const selected = useLocation().pathname == link

  const handleClick = () => {
    // setTimeout included so animation runs properly
    setTimeout(() => {
      setShowSidebar(false)
    }, 100)
    navigate(link)
  }

  return(
    <div
      onClick={handleClick}
      className={`flex items-center w-full my-1 py-4 pl-4 font-black rounded hover:cursor-pointer 
      ${selected ? 'text-grey-100 bg-grey-700' : 'text-grey-300 hover:bg-grey-900'}`}
    >
      {icon}
      {text}
    </div>
  )
}