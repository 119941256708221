import useStore from "../../store"
import { useEffect, useState } from "react"
import NotificationRow from "./NotificationRow"
import { motion } from "framer-motion"
import { useTranslation } from "react-i18next"
import { Spinner } from "@chakra-ui/react"
import { Button } from "@chakra-ui/react"
import tailwind from "../../../tailwind.config"

export default function Notifications() {
  const { t } = useTranslation()
  const notifications = useStore((state) => state.notifications)
  const fetchNotifications = useStore((state) => state.fetchNotifications)
  const isFetchingNotifications = useStore((state) => state.isFetchingNotifications)
  const [notificationParam, setNotificationParam] = useState('')
  const grey700 = tailwind.theme.colors["grey-700"]
  const grey100 = tailwind.theme.colors["grey-100"]

  useEffect(() => {
    fetchNotifications(notificationParam)
  }, [notificationParam])

  const handleClick = (param) => {
    setNotificationParam(param)
  }

  return(
    <div className="flex grow overflow-y-auto justify-center w-full">
      <div className="flex flex-col overflow-y-auto overflow-x-hidden w-full lg:w-1/2 lg:bg-grey-900 lg:h-[85vh]">
        <div className="sticky flex items-center top-0 left-0 z-30 w-full pt-2 pb-3 px-2 bg-black lg:bg-grey-900 rounded-t">
          <h3 className="font-header font-semibold text-md text-green mr-1">
            {t("Notifications")}
          </h3>
        </div>
        <div className="flex pb-3">
          <div className="pl-2 pr-2">
            <Button
              key={'all-notifications-button'}
              color={notificationParam === '' ? grey700 : grey100}
              bg={notificationParam === '' ? grey100 : grey700}
              onClick={() => handleClick('')}
              data-testid={'all-notifications-button'}
              borderRadius="full"
              sx={{
                _hover: {
                  bg: notificationParam === '' ? grey100 : grey700,
                  color: "white",
                  textColor: notificationParam === '' ? grey700 : grey100,
                  opacity: "75%",
                },
              }}
            >
              {t('All')}
            </Button>
          </div>
          <div className="pr-2">
            <Button
              key={'unread-notifications-button'}
              color={notificationParam === '?scope=unread' ? grey700 : grey100}
              bg={notificationParam === '?scope=unread' ? grey100 : grey700}
              onClick={() => handleClick('?scope=unread')}
              data-testid={'unread-notifications-button'}
              borderRadius="full"
              sx={{
                _hover: {
                  bg: notificationParam === '?scope=unread' ? grey100 : grey700,
                  color: "white",
                  textColor: notificationParam === '?scope=unread' ? grey700 : grey100,
                  opacity: "75%",
                },
              }}
            >
              {t('Unread')}
            </Button>
          </div>
          <div>
            <Button
              key={'read-notifications-button'}
              color={notificationParam === '?scope=read' ? grey700 : grey100}
              bg={notificationParam === '?scope=read' ? grey100 : grey700}
              onClick={() => handleClick('?scope=read')}
              data-testid={'read-notifications-button'}
              borderRadius="full"
              sx={{
                _hover: {
                  bg: notificationParam === '?scope=read' ? grey100 : grey700,
                  color: "white",
                  textColor: notificationParam === '?scope=read' ? grey700 : grey100,
                  opacity: "75%",
                },
              }}
            >
              {t('Read')}
            </Button>
          </div>
        </div>
        
        {!isFetchingNotifications ?
          <motion.div
            initial={{ x: "100%" }}
            animate={{ x: 0 }}
            transition={{ type: 'tween', duration: 0.2 }}
            className="w-full"
          >
            {notifications &&
              notifications.map((n) => {
                return(
                  <NotificationRow notification={n} key={n.id} />
                )
              })
            }
          </motion.div>
        :
          <div className="flex grow justify-center">
            <Spinner/>
          </div>
        }
      </div>
    </div>
  )
}