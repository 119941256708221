import { useTranslation } from 'react-i18next'

export default function LanguageSwitcher() {
  const { i18n } = useTranslation()
  const isCurrentLng = (lng) => i18n.resolvedLanguage === lng

  const lngs = {
    en: { nativeName: 'English' },
    es: { nativeName: 'Español' },
  }

  return(
    <div className="relative my-1 border border-grey rounded">
      <div
        className={`absolute w-1/2 h-full border border-2 rounded bottom-0 ${isCurrentLng('en') ? 'left-0' : 'left-1/2'} transition-all duration-300`}
      />
      {Object.keys(lngs).map((lng) => (
        <button
          key={lng}
          onClick={() => i18n.changeLanguage(lng)}
          disabled={isCurrentLng(lng)}
          className={`px-4 rounded ${isCurrentLng(lng) ? 'text-grey-100 border-grey-100' : 'text-grey border-grey-900'} transition-all duration-500`}
        >
          {lngs[lng]['nativeName']}
        </button >
      ))}
    </div>
  )
}