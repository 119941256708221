import FilterIndicator from "../../common/FilterIndicator"
import useStore from "../../../store"

export default function NotesTableFilterIndicators({}) {
  const setNotesFilterParams = useStore((state) => state.setNotesFilterParams)
  const notesFilterParams = useStore((state) => state.notesFilterParams)
  const setSelectedNotesScopeLabel = useStore((state) => state.setSelectedNotesScopeLabel)
  const selectedNotesScopeLabel = useStore((state) => state.selectedNotesScopeLabel)
  const noteTypeLabel = useStore((state) => state.noteTypeLabel)
  const notePurposeLabel = useStore((state) => state.notePurposeLabel)
  const setNotePurposeLabel = useStore((state) => state.setNotePurposeLabel)
  const setNoteTypeLabel = useStore((state) => state.setNoteTypeLabel)

  const updateFilterParams = (paramToRemove) => {
    const updatedFilterParams = { ...notesFilterParams }
    delete updatedFilterParams[paramToRemove]
    setNotesFilterParams(updatedFilterParams)
  }

  const handleResetScope = (filter) => {
    updateFilterParams(filter)
    if (filter === 'filter[purpose]') {
      setNotePurposeLabel('')
    } else if (filter === 'filter[type]') {
      setNoteTypeLabel('')
    } else if (filter === 'scope') {
      setSelectedNotesScopeLabel('')
    }
  }

  return(
    <div className="flex justify-start w-full gap-2 mb-2 mt-2">
      <FilterIndicator
        filterName={selectedNotesScopeLabel}
        displayFor={notesFilterParams['scope']}
        onClick={() => handleResetScope('scope')}
      />
      <FilterIndicator
        filterName={notePurposeLabel}
        displayFor={notesFilterParams['filter[purpose]']}
        onClick={() => handleResetScope('filter[purpose]')}
      />
      <FilterIndicator
        filterName={noteTypeLabel}
        displayFor={notesFilterParams['filter[type]']}
        onClick={() => handleResetScope('filter[type]')}
      />
    </div>
  )
}