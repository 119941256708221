const cloudVisionBody = (image) => ({
  requests: [{
    features: [{
      type: 'TEXT_DETECTION'
    }],
    image: {
      content: image.split(',')[1]
    }
  }]
})

const createGoogleSlice = (set) => ({
  googleCloudVision: (image) => fetch(
    `https://vision.googleapis.com/v1/images:annotate?key=${import.meta.env.VITE_CLOUD_VISION_KEY}`, 
    { 
      method: 'POST',
      body: JSON.stringify(cloudVisionBody(image))
    }
  )
})

export default createGoogleSlice