import useStore from "../../../store"
import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import ActionConfirm from "../../common/ActionConfirm"
import QuickPayoffDetails from "./QuickPayoffDetails"

export default function QuickPayoff({ unitId, setIsProcessingQuickPayoff }) {
  const { t } = useTranslation()
  const user = useStore((state) => state.user)
  const createQuickPayoff = useStore((state) => state.createQuickPayoff)
  const payment = useStore((state) => state.payment)
  const setPayment = useStore((state) => state.setPayment)
  const cancelPayment = useStore((state) => state.cancelPayment)
  const processPayment = useStore((state) => state.processPayment)
  const setErrMessage = useStore((state) => state.setErrMessage)
  const setSuccessMessage = useStore((state) => state.setSuccessMessage)
  const fetchUnits = useStore((state) => state.fetchUnits)
  const [paymentStatus, setPaymentStatus] = useState('pending')

  useEffect(() => {
    createPayoff()
  }, [])

  const createPayoff = async () => {
    setPayment(null)
    setPaymentStatus('pending')
    try {
      const response = await createQuickPayoff({
        ach: {
          branch_id: user.branchId,
          unit_id: unitId
        }
      })
      const data = await response.json()

      if (response.status === 200) {
        setPayment(data.data)
        setPaymentStatus('completed')
      } else {
        if (data.message) {
          setErrMessage(data.message)
        } else {
          setErrMessage('Unable to create quick payoff at this time. Please try again')
        }
        setIsProcessingQuickPayoff(false)
      }
    } catch (err) {
      setErrMessage('Unable to create quick payoff at this time. Please try again')
      setIsProcessingQuickPayoff(false)
    }
  }

  const onConfirmPayoff = async () => {
    setPaymentStatus('pending')
    const response = await processPayment(payment.id)
    if (response.ok) {
      setSuccessMessage('Your unit payoff was successfully submitted')
      fetchUnits()
    } else {
      setErrMessage('Unable to process payment at this time. Please try again')
    }
    setIsProcessingQuickPayoff(false)
  }

  const onCancelPayoff = async () => {
    setPaymentStatus('pending')
    const response = await cancelPayment(payment.id)
    const data = await response.json()
    setSuccessMessage(data[0].message)
    setIsProcessingQuickPayoff(false)
  }

  return(
    <ActionConfirm
      body={t('Confirm unit payoff?')}
      onConfirm={onConfirmPayoff}
      onCancel={onCancelPayoff}
      showSpinner={paymentStatus === 'pending'}
      subtext={<QuickPayoffDetails />}
    />
  )
}