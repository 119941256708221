import businessDevCircleColor from "../../../utilities/businessDevCircleColor"
import mobileBusinessDevCircleColor from "../../../utilities/mobileBusinessDevCircleColor"
import { motion } from "framer-motion"
import { useIsMobile } from "../../../hooks/useIsMobile"

export default function BusinessDevCircle({number, selected, title, goal, prospects=null, apps_pending=null}) {
  const isMobile = useIsMobile()

  const handleTruncate = (num) => {
    if (num >= 100000) {
      return '100000>'
    }

    return num
  }

  const handleTextResize = (num) => {
    if (num >= 100000) {
      return "text-xs"
    }
    if (num >= 10000) {
      return "text-md"
    }
    if (num >= 1000) {
      return "text-xl"
    }

    return "text-2xl"
  }
  
  const handleCircleResize = (num) => {
    if (num >= 100) {
      return "w-36 h-36"
    } else if (num >= 1) {
      return "w-20 h-20"
    } else {
      return "w-16 h-16"
    }
  }

  return (
    <>
      {isMobile ?
        <motion.div
          className={`flex flex-col items-center justify-center truncate-ellipses ${selected ? 'bg-grey-700 rounded': ''}`}
          whileTap={{scale: 0.9}}
        >
          <div className={`flex items-center relative z-10`}>
            <div className={`w-16 h-16 rounded-full flex items-center justify-center bg-black border-2 ${mobileBusinessDevCircleColor({number, goal, prospects, apps_pending})}`}>
              <span className={`${handleTextResize(number)}`}>{handleTruncate(number)}</span>
            </div>
          </div>
          <div className={`mt-2 text-center line-clamp-1`}>{title}</div>
        </motion.div>
      :
        <motion.div
          className={`flex flex-col items-center justify-center ${selected ? 'bg-grey-700 rounded p-2': ''}`}
          whileHover={{ scale: 1.1 }}
        >
          <div className={`h-36 flex items-center relative z-10`}>
            <div className={`${businessDevCircleColor({number, goal, prospects, apps_pending})} rounded-full flex items-center justify-center ${handleCircleResize(number)}`}>
              <span className="text-2xl text-grey-900">{number}</span>
            </div>
          </div>
          <div className={`mt-2 text-center line-clamp-1`}>{title}</div>
        </motion.div>
      }
    </>
  )
}