import InfoItem from "./InfoItem"

export default function EditableInfoItem({ title, data, isEditing, editableTitle=false, inputValue, onInputChange, onTitleChange=null, titleValue=null }) {
  return(
    <InfoItem
      title={
        isEditing && editableTitle ? (
          <input
            value={titleValue}
            name="title"
            title="Edit title"
            aria-label="Edit title"
            className="w-full text-grey-100 p-0.5 bg-grey-900 border-2 rounded
              border-grey-700 focus:border-grey-300 focus:ring-0"
            onChange={onTitleChange}
          />
        ) : (
          title
        )
      }
      data={
        isEditing ? (
          <input
            value={inputValue}
            name="data"
            title={title}
            aria-label={title}
            className="w-full text-grey-100 p-0.5 bg-grey-900 border-2 rounded
              border-grey-700 focus:border-grey-300 focus:ring-0"
            onChange={onInputChange}
          />
        ) : (
          data
        )
      }
      editableTitle={editableTitle}
    />
  )
}