import useStore from "../../../store"
import Spinner from "../../common/Spinner"
import { useState, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { NeutralButton } from "../../common/Buttons"
import ImagePreview from "./ImagePreview"
import { FaEdit } from "react-icons/fa"
import NAPConfirmationDetails from "./NAPConfirmationDetails"
import NAPCamera from "./NAPCamera"
import SwipeAnimation from "../../common/SwipeAnimation"
import { createFileFromBase64 } from "../../../utilities/createFileFromBase64"

export default function NAPConfirmation({ issues }) {
  const { t } = useTranslation()
  const [previewImage, setPreviewImage] = useState(null)
  const [cameraOpen, setCameraOpen] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const setErrMessage = useStore((state) => state.setErrMessage)
  const blackbook = useStore((state) => state.NAPBlackbook)
  const createFloorRequest = useStore((state) => state.createFloorRequest)
  const floorAmount = useStore((state) => state.NAPFloorAmount)
  const setNapId = useStore((state) => state.setNapId)
  const updateUnit = useStore((state) => state.updateUnit)
  const images = useStore((state) => state.NAPImages)
  const NAPBankAccount = useStore((state) => state.NAPBankAccount)
  const NAPRequiresBankPayoff = useStore((state) => state.NAPRequiresBankPayoff)
  const setCurrentStep = useStore((state) => state.setCurrentStep)
  const nextStep = useStore((state) => state.nextStep)

  const submitFloor = useCallback(async () => {
    setSubmitting(true)
    try {
      const formData = new FormData()
      for(const [key, value] of Object.entries(images)) {
        const file = await createFileFromBase64(key, value)

        formData.append('unit[nap_photos_ary][]', file)
      }

      formData.append('unit[vin]', blackbook.vin)
      formData.append('unit[principal]', floorAmount)
      formData.append('unit[mileage]', blackbook.mileage)
      formData.append('unit[model', `${blackbook.model} ${blackbook.series}`)

      if (NAPRequiresBankPayoff) {
        formData.append('unit[bank_account_id]', null)
      } else {
        formData.append('unit[bank_account_id]', NAPBankAccount.id)
      }

      const response = await createFloorRequest(formData)
      if (response.ok) {
        const data = await response.json()
        setNapId(data.data.id)
        nextStep()
      } else if (response.status === 422) {
        const data = await response.json()
        const error = data?.errors?.message || 'There was an error requesting this floor'
        setErrMessage(error)
      } else {
        setErrMessage('There was an error requesting this floor')
      }
    } catch (err) {
      setErrMessage('Unable to reach server')
    } finally {
      setSubmitting(false)
    }
  }, [floorAmount, blackbook])

  const updateFloor = async () => {
    setSubmitting(true)
    try {
      const formData = new FormData()
      for(const [key, value] of Object.entries(images)) {
        const file = await createFileFromBase64(key, value)

        formData.append('unit[nap_photos_ary][]', file)
      }

      const response = await updateUnit(blackbook.id, formData)

      if (response.ok) {
        const data = await response.json()
        setNapId(data.data.id)
        nextStep()
      } else if (response.status === 422) {
        const data = await response.json()
        const error = data?.errors?.message || 'There was an error requesting this floor'
        setErrMessage(error)
      } else {
        setErrMessage('There was an error requesting this floor')
      }
    } catch (err) {
      setErrMessage('Unable to reach server')
    } finally {
      setSubmitting(false)
    }
  }

  const handleCloseCamera = () => {
    setCameraOpen(false)
    setPreviewImage(null)
  }

  return(
    <SwipeAnimation>
      <div className="relative flex flex-col justify-between items-center pt-6 rounded w-full h-full">

        <NAPConfirmationDetails issues={issues} />

        <div className="grid grid-cols-3 grow w-full px-6">
          {Object.keys(images).map((key, index) => {
            return(
              <div
                className="flex flex-col h-full items-center justify-center"
                key={key}
              >
                <div
                  className="w-[20vw] h-[20vw] rounded mb-1"
                  onClick={() => setPreviewImage([key, images[key]])}
                  data-testid={`photo-${key}`}
                >
                  <img src={images[key]} alt="Preview" className="w-full h-full object-cover rounded" />
                </div>
                <div className="flex flex-col items-center text-xs text-center my-1">
                  {key}
                  <FaEdit
                    className="text-sm my-0.5"
                    onClick={() => {
                      setCurrentStep(index + 1)
                    }}
                    data-testid={`edit-photo-${key}`}
                  />
                </div>
              </div>
            )
          })}
        </div>

        <div className="my-4">
          {submitting ?
            <Spinner height="45" /> :
            <NeutralButton
              text={issues ? t("Update Floor Request") : t("Confirm Request Floor")}
              onClick={issues ? updateFloor : submitFloor}
              style="text-lg"
            />
          }
        </div>
      </div>

      <div className="fixed top-0 left-0 z-50">
        {cameraOpen && <NAPCamera close={handleCloseCamera} title={previewImage[0]} />}
        {previewImage && <ImagePreview
          image={previewImage[1]}
          onClose={() => setPreviewImage(null)}
        />}
      </div>
    </SwipeAnimation>
  )
}