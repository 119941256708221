import { BsDot } from "react-icons/bs"
import { useIsMobile } from "../../../hooks/useIsMobile"

export default function NeedingAttentionItem({needsAttentionUnitCount, testId, text}) {
  const isMobile = useIsMobile()

  return(
    <>
      {needsAttentionUnitCount > 0 && (
        <div className='flex items-center pl-4' data-testid={testId}>
          {!isMobile && <BsDot size={'20px'} />}
          {`${text} ${needsAttentionUnitCount}`}
        </div>
      )}
    </>
  )
}