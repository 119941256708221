import { apiUrl, apiUrlV2 } from "../constants/apiUrl"

const initialState = {
  dailyOps: null,
  dailyOp: undefined,
}

const createDailyOpsSlice = (set, get) => ({
  ...initialState,
  fetchDailyOps: async () => {
    try {
      const response = await fetch(
        `${apiUrlV2}/daily_ops`,
        {headers: get().requestHeaders}
      )
      if (response.status === 200) {
        const data = await response.json()

        if(get().user) {
          set((state) => ({ dailyOps: data }))
        }
      }
    } catch (err) {
      get().setErrMessage('Data was unable to be fetched. Please refresh to try again')
    }

  },
  resetDailyOpsSlice: () => set(initialState)
})

export default createDailyOpsSlice
