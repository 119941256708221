import { useTranslation } from "react-i18next"
import useStore from "../../../store"
import PendingTransferRow from "./PendingTransferRow"


export default function PendingTransfers() {
  const pendingTransfers = useStore((state) => state.pendingTransfers)
  const { t } = useTranslation()

  return(
    <div className="w-full text-left p-2 mb-2 bg-grey-900 rounded">
      <div className="sticky top-0 left-0 z-30 bg-grey-900">
        <h3 className="font-header font-semibold text-md text-green">{t('Pending Transactions')}</h3>
      </div>
      
      <div className="h-full">
        {pendingTransfers.map((transfer) => {
          return (
            <PendingTransferRow 
              transfer={transfer} 
              key={transfer.id}  
            />
          )
        })}
      </div>
    </div>
  )
}