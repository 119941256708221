import { useIsMobile } from "../../../hooks/useIsMobile"
import { useNavigate } from "react-router-dom"
import ClickableRow from "../../common/ClickableRow"
import useStore from "../../../store"

export default function ReserveClickableRow({ onClick, isExpanded }) {
  const isMobile = useIsMobile()
  const navigate = useNavigate()
  const dealer = useStore((state) => state.dealer)

  const handleClick = () => {
    if (isMobile) {
      navigate('/reserve_info')
    }

    onClick()
  }

  return(
    <div className="flex flex-col items-center w-full bg-grey-900" data-testid="reserve-info-page">
      {dealer &&
        <ClickableRow text={'Reserve Information'}
          onClick={handleClick}
          isSelected={isExpanded}
        />
      }
    </div>
  )
}