import dailyOpColor from "../../../utilities/dailyOpColor"

export default function MobileKpiSquare({display_amount, goal_percentage, text}) {

  return(
    <>
      <h4 className="text-white font-extrabold text-xs text-center px-1">{text}</h4>
      <p className={`${dailyOpColor(goal_percentage)} text-3xl font-extrabold text-center`}>
        {display_amount}
      </p>
    </>
  )
}
