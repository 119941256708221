import {apiUrl, apiUrlV2} from "../constants/apiUrl";

const createUserSlice = (set, get) => ({
  graphRange: '1Y',
  isGraphHappy: false,
  isOnline: true,
  showSidebar: false,
  appMessage: null,
  hideGraph: true,
  unitRowStyle: 'sm',
  collapsedSections: {
    dealersWithRepos: true,
    dealersWithReviews: true,
    soldUnits: true,
    unitsPastDue: true,
    dealersWithFieldAudits: true,
    untouchedFortyDays: true
  },
  actionButton: null,
  topNavHidden: false,
  navBarHidden: false,
  pushNotificationEnabled: false,
  userLocation: {latitude: null, longitude: null},
  breadcrumbTrail: [{label: 'Dashboard', path: '/'}],
  setBreadcrumbTrail: (trail) => set({breadcrumbTrail: trail}),
  resetBreadcrumbTrail: () => set({breadcrumbTrail: [{label: 'Dashboard', path: '/'}]}),
  setGraphRange: (range) => set((state) => ({graphRange: range})),
  setIsGraphHappy: (range) => set((state) => ({isGraphHappy: range})),
  setIsOnline: (isOnline) => set((state) => ({isOnline: isOnline})),
  setShowSidebar: (show) => set((state) => ({showSidebar: show})),
  setPushNotificationEnabled: (enabled) => set((state) => ({pushNotificationEnabled: enabled})),
  setErrMessage: (message, buttonOptions = null) => set((state) => ({
    appMessage: {
      message: message,
      buttonOptions: buttonOptions,
      type: 'error'
    }
  })),
  setAlertMessage: (message, buttonOptions = null) => set((state) => ({
    appMessage: {
      message: message,
      buttonOptions: buttonOptions,
      type: 'alert'
    }
  })),
  setSuccessMessage: (message, buttonOptions = null) => set((state) => ({
    appMessage: {
      message: message,
      buttonOptions: buttonOptions,
      type: 'success'
    }
  })),
  resetMessages: () => set((state) => ({appMessage: null})),
  toggleHideGraph: () => set((state) => ({hideGraph: !state.hideGraph})),
  setUnitRowStyle: (style) => set(() => ({unitRowStyle: style})),
  togglePushNotifications: async () => {

    console.log(get().user)
    try {
      const response = await fetch(
        `${apiUrl}/dealer-users/${get().user.dealerUserId}`,
        {
          headers: get().requestHeaders, method: 'PUT',
          body: JSON.stringify({dealer_user: {push_notifications_enabled: !get().pushNotificationEnabled}}),
        }
      )
      const data = await response.json()

    } catch (err) {
      get().setErrMessage('Error updating user push notification preferences.')
    }
    set((state) => ({pushNotificationEnabled: !get().pushNotificationEnabled}))
  },
  toggleCollapsedSection: (section) => set((state) => ({
    collapsedSections: {
      ...state.collapsedSections,
      [section]: !state.collapsedSections[section]
    }
  })),
  resetCollapsedSections: () => set((state) => ({
    collapsedSections: {
      dealersWithRepos: true,
      dealersWithReviews: true,
      soldUnits: true,
      unitsPastDue: true,
      dealersWithFieldAudits: true,
      untouchedFortyDays: true,
      unreconciledFieldAudits: true
    }
  })),
  setActionButton: (actionButton) => set((state) => ({actionButton: actionButton})),
  setTopNavHidden: (topNavHidden) => set((state) => ({topNavHidden: topNavHidden})),
  setNavBarHidden: (navBarHidden) => set((state) => ({navBarHidden: navBarHidden})),
  setUserLocation: (location) => set((state) => ({userLocation: location}))
})

export default createUserSlice