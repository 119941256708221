
export default function FormInput({ title, value, onChange, isErrored = false, styles='' }) {
  return(
    <label
        className={`text-sm my-1
        ${isErrored ? 'text-fpx-red' : 'text-grey-300'}`}
    >
      {title}
      <input
        value={value}
        name={title}
        title={title}
        aria-label={title}
        className={`${styles} w-full text-md text-grey-100 p-0.5 bg-black border-solid border-2 rounded
        ${isErrored ? 'border-fpx-red' : 'border-grey-700'}
        focus:border-grey-300 focus:ring-0`}
        onChange={onChange}
      />
    </label>
  )
}