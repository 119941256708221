export default function FormattedAddress({address1, address2=null, city, state, zip} ) {
  return(
    <div className="w-full text-left p-2 bg-grey-900 rounded-t">
      <div className="text-md text-grey-100">{address1}</div>
      {address2 &&
      <div className="text-md text-grey-100">{address2}</div>
      }
      <div className="text-md text-grey-100">{`${city}, ${state} ${zip}`}</div>
    </div>
  )
}