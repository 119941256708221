import AppPageBody from "./AppPageBody"
import useStore from "../../../store"
import { useParams } from "react-router-dom"

export default function MobileAppPage() {
  const { appId } = useParams()
  const dealerId = useStore((state) => state.dealerId)

  return(
    <div className="flex grow flex-col items-center w-full overflow-y-auto">
      <div className="w-full lg:h-full bg-grey-900 p-2 mb-2 rounded">
        <AppPageBody appId={appId} dealerId={dealerId}/>
      </div>
    </div>
  )
}