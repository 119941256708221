import InfoItem from "../../common/InfoItem"
import formatter from "../../../constants/currencyFormatter"
import useStore from "../../../store"

export default function ReserveInfoBody() {
  const dealer = useStore((state) => state.dealer)

  return(
    <div className="flex flex-col w-full bg-grey-900 p-2" data-testid="reserve-info-page">
      <div className="flex justify-between items-center w-full mb-2">
        <div className="grid grid-cols-2 lg:grid-cols-3 w-full text-left bg-grey-900 rounded" data-testid="reserve-info-body">
          <InfoItem title={'Reserves Available'}>
            {formatter.format(dealer?.attributes["current-reserve"])}
          </InfoItem>
          <InfoItem title={'Min. Reserves Balance'}>
            {formatter.format(dealer?.attributes["reserves-minimum"])}
          </InfoItem>
          <InfoItem title={'Reserves APR'}>
            {dealer?.attributes["reserve-apr"] !== null ? `${dealer?.attributes["reserve-apr"]} %` : 'N/A'}
          </InfoItem>
          <InfoItem title={'Add To Each Unit'}>
            {formatter.format(dealer?.attributes["reserve-unit-add"])}
          </InfoItem>
          <InfoItem title={'% of Contract Line'}>
            {dealer?.attributes["reserves-credit-percent"] !== null ? `${dealer?.attributes["reserves-credit-percent"]} %` : 'N/A'}
          </InfoItem>
          <InfoItem title={'% of Current Owed'}>
            {dealer?.attributes["owed-percent"] !== null ? `${Number(dealer?.attributes["owed-percent"]).toFixed(2)} %` : 'N/A'}
          </InfoItem>
          <InfoItem title={'Min. Reserves Per Unit'}>
            {formatter.format(dealer?.attributes["min-reserves-per-unit"])}
          </InfoItem>
        </div>
      </div>
    </div>
  )
}