import useStore from "../../../store"
import InfoItem from "../../common/InfoItem"
import dealerCityState from "../../../utilities/dealerCityState"
import dealerAddress from "../../../utilities/dealerAddress"

export default function ProspectDealerInformation() {
  const dealer = useStore((state) => state.dealer)

  return(
    <>
      <div className="w-full text-left pl-2 bg-grey-900 rounded-t">
        <h3 className="font-header font-semibold text-md text-green">{'Dealership Information'}</h3>
      </div>
      <div className="grid grid-cols-2 lg:grid-cols-3 w-full text-left p-2 bg-grey-900 rounded">
        <InfoItem
          title="Phone"
          data={dealer.attributes['primary-phone'] ? dealer.attributes['primary-phone'] : 'None'}
        />
        <InfoItem
          title="Fax"
          data={dealer.attributes['primary-fax'] ? dealer.attributes['primary-fax'] : 'None'}
        />
        <InfoItem
          title="Address"
          data={dealerAddress(dealer)}
          subdata={dealerCityState(dealer)}
        />
      </div>
      <div className="grid grid-cols-2 lg:grid-cols-3 w-full text-left p-2 bg-grey-900 rounded">
        <InfoItem
          title="# of Touches"
          data={dealer.attributes['number-of-touches'] ? dealer.attributes['number-of-touches'] : 'None'}
        />
        <InfoItem
          title="Last Touch"
          data={dealer.attributes['last-touch'] ? dealer.attributes['last-touch'] : 'None'}
        />
      </div>
    </>

  )
}