import { GoDotFill } from "react-icons/go"
import { FaRegBell  } from "react-icons/fa"

export default function NotificationsBell({ isOnAlert, onClick }) {

  return(
    <div
      className='relative text-xl hover:cursor-pointer'
      onClick={onClick}
      data-testid="notifications-button"
    >
      {isOnAlert &&
        <GoDotFill
          className='absolute -top-0.5 -right-0.5 text-xs text-fpx-red bg-black rounded-full'
          data-testid="notifications-alert"
        />
      }
      <FaRegBell />
    </div>
  )
}