import { useEffect } from "react"
import useStore from "../../../store"
import { IoMdClose } from "react-icons/io"
import UnitPageBody from "./UnitPageBody"
import { useNavigate } from "react-router-dom"
import SideInfoPanel from "../../common/SideInfoPanel"

export default function DashboardUnitInfo({ unitId }) {
  const fetchUnit = useStore((state) => state.fetchUnit)
  const unitFetching = useStore((state) => state.unitFetching)
  const unit = useStore((state) => state.unit)
  const setUnit = useStore((state) => state.setUnit)
  const setDashboardUnitId = useStore((state) => state.setDashboardUnitId)
  const navigate = useNavigate()

  useEffect(() => {
    fetchUnit(unitId)
  }, [unitId])

  useEffect(() => {
    if (!unitFetching && unit?.errors) {
      setUnit(null)
      setDashboardUnitId(null)
      navigate('/not-found')
    }
  }, [unitFetching])

  return(
    <SideInfoPanel
      body={<UnitPageBody unitId={unitId}/>}
      isLoading={!unit}
      onClose={() => setDashboardUnitId(null)}
    />
  )
}