import useStore from '../../store'
import { useEffect } from 'react'
import { loginRequest } from '../../configs/msalAuthConfig'
import { useMsal } from '@azure/msal-react'


export default function EmployeeLogin() {
  const { instance } = useMsal()
  const setErrMessage = useStore((state) => state.setErrMessage)

  const handleEmployeeLogin = async () => {
    try {
      await instance.initialize()
      await instance.loginRedirect({
        ...loginRequest,
        prompt: 'create',
      })
    } catch (err) {
      setErrMessage('Microsoft single sign-on is currently unavailable')
    }
  }

  return(
    <div 
      className="text-fpx-red hover:opacity-75 hover:cursor-pointer underline"
      onClick={() => handleEmployeeLogin()}
    >
      Employee Login
    </div>
  )
}