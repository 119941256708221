import useStore from "../../../store"
import PaymentSelect from "./PaymentSelect"
import PaymentConfirm from "./PaymentConfirm"
import PaymentSuccess from "./PaymentSuccess"
import Spinner from "../../common/Spinner"

export default function MakePaymentBody() {
  const createPaymentStatus = useStore((state) => state.createPaymentStatus)

  switch(createPaymentStatus) {
    case 'idle':
      return <PaymentSelect />
    case 'pending':
      return <PaymentSelect />
    case 'complete':
      return <PaymentConfirm />
    case 'confirming':
      return <Spinner color="green" />
    case 'confirmed':
      return <PaymentSuccess />
  }
}