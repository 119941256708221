export function defaultBankAccount(bankAccounts) {
  const defaultAccount = bankAccounts?.find((account) => account.attributes['default-account'])
  if (defaultAccount) {
    return defaultAccount
  } else if (bankAccounts.length === 1) {
    return bankAccounts[0]
  } else {
    return null
  }
}

export function filterAuthorizedBankAccounts(bankAccounts) {
  return bankAccounts?.filter((acct) => !acct.attributes["needs-ach-auth"])
}