import { init } from "rollbar"
import { apiUrl, apiUrlV2 } from "../constants/apiUrl"

const initialState = {
  applications: [],
  applicationFilterAttribute: '',
  applicationFilterDirection: '',
  applicationsFetching: false,
  dashboardAppId: null,
  applicationFetching: false,
  application: undefined,
  otherFloorplans: [],
  otherFloorplansFetching: false,
  isUpdatingApplication: false
}

const createApplicationsSlice = (set, get) => ({
  ...initialState,
  setApplicationsTableType: (tableType) => {
    set((state) => ({ applicationsTableType: tableType}))
  },
  setApplicationFilterAttribute: (attribute) => {
    set((state) => ({ applicationFilterAttribute: attribute}))
  },
  setApplicationFilterDirection: (direction) => {
    set((state) => ({ applicationFilterDirection: direction}))
  },
  fetchApplications: async () => {
    set((state) => ({ applicationsFetching: true}))
    try {
      const response = await fetch(
        `${apiUrlV2}/apps?per_page=200&${get().applicationsTableType}&${get().applicationFilterAttribute}&${get().applicationFilterDirection}`,
        {headers: get().requestHeaders}
      )
      if (response.status === 200) {
        const data = await response.json()

        if(get().user) {
          set((state) => ({ applications: data.data, applicationsFetching: false }))
        }
      }
    } catch (err) {
      get().setErrMessage('Data was unable to be fetched. Please refresh to try again')
    }
  },
  setDashboardAppId: (id) => set(() => ({ dashboardAppId: id })),
  fetchApplication: async (appId) => {
    set((state) => ({ applicationFetching: true}))

    try {
      const response = await fetch(
        `${apiUrlV2}/apps/${appId}`,
        {headers: get().requestHeaders}
      )
      if (response.status === 200) {
        const data = await response.json()

        if(get().user) {
          set((state) => ({ application: data.data, applicationFetching: false }))
        }
      }
    } catch (err) {
      get().setErrMessage('Data was unable to be fetched. Please refresh to try again')
    }
  },
  updateApplication: async (appId, attributes) => {
    try {
      const response = await fetch(
        `${apiUrlV2}/apps/${appId}`,
        {
          headers: get().requestHeaders,
          method: 'PATCH',
          body: JSON.stringify({
            app: attributes
          })
        }
      )
      if (response.status === 200) {
        const data = await response.json()

        if(get().user) {
          set((state) => ({ application: data.data }))
        }
      }
    } catch (err) {
      get().setErrMessage('Data was unable to be updated. Please refresh to try again')
    }
  },
  setIsUpdatingApplication: (isUpdating) => set(() => ({ isUpdatingApplication: isUpdating })),
  fetchOtherFloorplans: async (dealerId) => {
    set((state) => ({ otherFloorplansFetching: true}))

    try {
      const response = await fetch(
        `${apiUrlV2}/dealers/${dealerId}/floorplans`,
        {headers: get().requestHeaders}
      )
      if (response.status === 200) {
        const data = await response.json()

        if(get().user) {
          set((state) => ({ otherFloorplans: data.data, otherFloorplansFetching: false }))
        }
      }
    } catch (err) {
      get().setErrMessage('Data was unable to be fetched. Please refresh to try again')
    }
  },
  resetApplicationsSlice: () => set(initialState)
})

export default createApplicationsSlice