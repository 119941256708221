export default function sortUnitsByNapIssues(units) {
  units.sort((a, b) => {
    if (a.attributes['nap-issues'].length > b.attributes['nap-issues'].length) {
      return -1
    } else if (a.attributes['nap-issues'].length < b.attributes['nap-issues'].length) {
      return 1
    }

    return 0
  })
}