import { useIsMobile } from '../../hooks/useIsMobile'

const DesktopExpandableInfo = ({ condition, data, children }) => {
    const isMobile = useIsMobile()

    return (
        <div className={`hidden lg:block transition-all duration-500 ${(condition) ? 'w-1/2 opacity-100' : 'w-0 opacity-0'}`}>
            {(condition && !isMobile) &&
                <>
                    {children &&
                        <div className="flex flex-col w-full hidden lg:block lg:px-2 lg:w-full lg:h-[85vh] overflow-y-auto rounded">
                            {children}
                        </div>
                    }
                    {data &&
                        <div className="flex flex-col w-full hidden lg:block lg:px-2 lg:w-full lg:h-[85vh] overflow-y-auto rounded">
                            {data}
                        </div>
                    }
                </>
            }
        </div>
    )
}

export default DesktopExpandableInfo