import useStore from "../../../store"
import CurrentUnits from "../../Dealer/CurrentUnits/CurrentUnits"
import UnitPageBody from "../../Dealer/UnitPage/UnitPageBody"
import BackButton from "../../common/BackButton"
import { motion } from "framer-motion"

export default function AmDealerUnits({}) {
  const dashboardUnitId = useStore((state) => state.dashboardUnitId)
  const setDashboardUnitId = useStore((state) => state.setDashboardUnitId)

  return(
    <>
      {dashboardUnitId ?
        <div className="overflow-x-hidden pt-2">
          <motion.div
            initial={{ x: "100%" }}
            animate={{ x: 0 }}
            transition={{ type: 'tween', duration: 0.2 }}
          >
            <BackButton onClick={() => setDashboardUnitId(null)} />
            <UnitPageBody unitId={dashboardUnitId} />
          </motion.div>
        </div>
      :
        <CurrentUnits />
      }
    </>
  )
}