import { Button } from "@chakra-ui/react"
import tailwind from "../../../../../tailwind.config"
import useStore from "../../../../store"
import Spinner from "../../../common/Spinner"

export default function FilterButtons({handleResetFilter, handleSubmit, resultsCount, resultsFetching}) {
  const green = tailwind.theme.colors["green"]
  const red = tailwind.theme.colors["fpx-red"]

  return(
    <>
      <Button
        variant="outline"
        borderColor={red}
        bg="transparent"
        color="white"
        borderWidth={'2px'}
        sx={{
          borderRadius: 'full'
        }}
        onClick={handleResetFilter}
        className="mt-2 mr-2"
      >
        Clear Filters
      </Button>
      <Button
        variant="outline"
        borderColor={green}
        bg="transparent"
        borderWidth={'2px'}
        color="white"
        sx={{
          borderRadius: 'full'
        }}
        onClick={handleSubmit}
        className="mt-2"
        minW={'150px'}
      >
        { resultsFetching ?
          <Spinner color={green} height={'25px'}/>
          :
          `Show ${resultsCount} ${resultsCount == 1 ? 'Result' : 'Results'}`
        }
      </Button>
    </>
  )
}