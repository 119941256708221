import { CloseButton } from "@chakra-ui/react"
import { useIsMobile } from '../../hooks/useIsMobile'
import { FaExpandAlt, FaCompressAlt } from "react-icons/fa"
import tailwind from "../../../tailwind.config"

export default function DashboardSidePanel({
  children,
  isExpanded,
  setIsSidePanelFullscreen,
  isSidePanelFullscreen,
  onCloseSidePanel,
  isFullscreenable = true,
  isClosable = true,
}) {
  const red = tailwind.theme.colors["fpx-red"]
  const grey900 = tailwind.theme.colors["grey-900"]
  const isMobile = useIsMobile()

  return(
    <div
      className={`${isMobile ? 'hidden' : 'block'} transition-all duration-300
      ${(isExpanded && isSidePanelFullscreen) ? 'w-full opacity-100' : isExpanded ? 'w-1/2 opacity-100' : 'w-0 opacity-0'}`}
    >
      {(isExpanded && !isMobile) &&
        <>
          {children &&
            <div className="relative flex flex-col w-full hidden lg:block lg:px-2 lg:w-full lg:h-[85vh] overflow-y-auto rounded bg-grey-900">
              {isFullscreenable &&
                <div
                  onClick={() => setIsSidePanelFullscreen(!isSidePanelFullscreen)}
                  className="absolute top-3 left-4 z-40 border-2 border-grey-300 bg-grey-900 text-grey-300 p-1.5 rounded hover:cursor-pointer"
                  data-testid="fullscreen"
                >
                  {isSidePanelFullscreen ?
                    <FaCompressAlt style={{transform: 'rotate(90deg)'}} /> :
                    <FaExpandAlt  style={{transform: 'rotate(90deg)'}} />
                  }
                </div>
              }

              {isClosable &&
                <div>
                  <CloseButton
                    bg={red}
                    color={grey900}
                    fontSize={'lg'}
                    fontWeight={'bold'}
                    className="absolute top-3 right-2 text-2xl hover:cursor-pointer z-[35]"
                    onClick={onCloseSidePanel}
                    aria-label="close button"
                  />
                </div>
              }

              {children}
            </div>
          }
        </>
      }
    </div>
  )
}