import useStore from "../../../../store"
import { useState, useEffect } from "react"
import { Box, FormControl, FormLabel, Input, RadioGroup, Radio, Button, Heading, Stack, Flex } from '@chakra-ui/react'
import tailwind from "../../../../../tailwind.config"
import { NeutralButton } from "../../../common/Buttons"
import { HappyButton } from "../../../common/Buttons"

export default function LotDetailsForm() {
  const application = useStore((state) => state.application)
  const updateApplication = useStore((state) => state.updateApplication)
  const [inputValues, setInputValues] = useState({})
  const fetchApplication = useStore((state) => state.fetchApplication)
  const green = tailwind.theme.colors["green"]
  const grey700 = tailwind.theme.colors["grey-700"]
  const grey300 = tailwind.theme.colors["grey-300"]
  const setIsUpdatingApplication = useStore((state) => state.setIsUpdatingApplication)
  const isUpdatingApplication = useStore((state) => state.isUpdatingApplication)

  useEffect(() => {
    if (application) {
      setInputValues({
        lotSize: application.attributes['lot-size'] || "",
        currentInventory: application.attributes['current-inventory'] || "",
        lotOwnership: application.attributes['lot-ownership'] || "",
        dealersColocated: application.attributes['dealers-colocated'] === false ? "false" : (application.attributes['dealers-colocated'] || "")
      })
    }
  }, [application])


  const handleSubmit = async () => {
    setIsUpdatingApplication(false)

    await updateApplication(application.id, {
      lot_size: inputValues.lotSize,
      current_inventory: inputValues.currentInventory,
      lot_ownership: inputValues.lotOwnership,
      dealers_colocated: inputValues.dealersColocated
    })
  }

  return(
    <Box as="form" mt={2} ml={4} onSubmit={handleSubmit}>
      <FormControl id="lotSize" mb={2}>
        <FormLabel fontSize={'sm'} color={grey300} htmlFor="lotSize">How many vehicles does the dealer’s lot hold?</FormLabel>
        <Input
          type="number"
          value={inputValues.lotSize}
          onChange={(e) => setInputValues({ ...inputValues, lotSize: e.target.value })}
          focusBorderColor="white"
        />
      </FormControl>
      <FormControl id="currentInventory" mb={2}>
        <FormLabel fontSize={'sm'} color={grey300} htmlFor="currentInventory">{'How many vehicles are currently on the dealer’s lot?'}</FormLabel>
        <Input
          type="number"
          value={inputValues.currentInventory}
          onChange={(e) => setInputValues({ ...inputValues, currentInventory: e.target.value })}
          focusBorderColor="white"
        />
      </FormControl>
      <FormControl id="lotOwnership" mb={2}>
        <FormLabel fontSize={'sm'} color={grey300} htmlFor="lotOwnership">Does the dealer own or lease the lot?</FormLabel>
        <RadioGroup
          onChange={(value) => setInputValues({ ...inputValues, lotOwnership: value })}
          value={inputValues.lotOwnership}
        >
          <Stack direction="row">
            <Radio
              _checked={{
                bg: green,
                color: "white",
                borderColor: green,
              }}
              value="Own"
            >
              Own
            </Radio>
            <Radio
              _checked={{
                bg: green,
                color: "white",
                borderColor: green,
              }}
              value="Lease"
            >
              Lease
            </Radio>
          </Stack>
        </RadioGroup>
      </FormControl>
      <FormControl id="dealersColocated" mb={2}>
        <FormLabel fontSize={'sm'} color={grey300} htmlFor="dealersColocated">Are there any other dealerships located at this address (plaza lot)?</FormLabel>
        <Input
          type="text"
          value={inputValues.dealersColocated}
          onChange={(e) => setInputValues({ ...inputValues, dealersColocated: e.target.value })}
          focusBorderColor="white"
        />
      </FormControl>
        <div className="flex justify-center">
          <HappyButton
            text={'Update Lot Details'}
            onClick={handleSubmit}
            style="my-4 w-30 justify-items-center"
          />
        </div>
        <div className="underline flex justify-center hover:cursor-pointer" onClick={()=> setIsUpdatingApplication(false)} >
          Cancel
        </div>
    </Box>
  )
}