import useStore from "../../../store"
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import NAPWorkflow from "./NAPWorkflow"
import { useTranslation } from "react-i18next"
import BackButton from "../../common/BackButton"

export default function NAP({ issues = false }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [progressStyle, setProgressStyle] = useState("w-[9vw]")
  const NAPBlackbook = useStore((state) => state.NAPBlackbook)
  const currentStep = useStore((state) => state.currentStep)
  const setCurrentStep = useStore((state) => state.setCurrentStep)
  const NAPIssues = useStore((state) => state.NAPIssues)
  const NAPRequiresBankPayoff = useStore((state) => state.NAPRequiresBankPayoff)
  const setTopNavHidden = useStore((state) => state.setTopNavHidden)
  const setNavBarHidden = useStore((state) => state.setNavBarHidden)
  const [workflow, setWorkflow] = useState([])

  const progressBarStyles = [
    "w-[8vw]", "w-[16vw]", "w-[24vw]", "w-[32vw]", "w-[40vw]",
    "w-[48vw]", "w-[56vw]", "w-[64vw]", "w-[72vw]", "w-[80vw]"
  ]

  useEffect(() => {
    setWorkflow(NAPWorkflow(NAPIssues, issues, NAPRequiresBankPayoff))
  }, [NAPRequiresBankPayoff])

  useEffect(() => {
    setProgressStyle(progressBarStyles[currentStep])
  }, [currentStep])

  useEffect(() => {
    if (!NAPBlackbook) { navigate('/blackbook') }
  }, [])

  useEffect(() => {
    setTopNavHidden(true)
    if (currentStep === workflow.length - 1) {
      setNavBarHidden(true)
    }

    return () => {
      setTopNavHidden(false)
      setNavBarHidden(false)
    }
  }, [currentStep])

  const handleBack = () => {
    if (currentStep <= 0) {
      window.history.back()
    } else {
      setCurrentStep(currentStep - 1)
    }
  }

  return(
    <div className="flex flex-col grow items-center">
      <div className="flex flex-col items-center mb-4">
        <div className="flex justify-center items-center m-2">
          {currentStep !== workflow.length - 1 &&
            <BackButton
              styles="absolute left-3 text-grey-300 text-lg"
              onClick={handleBack}
            />
          }
          <div className="text-grey-300">{issues ? t('Edit Floor Request') : t('Request Floor')}</div>
        </div>
        {!issues &&
          <div className="relative w-[80vw] bg-grey-900">
            <div className="absolute bottom-1 w-full h-1 bg-grey-700" />
            <div
              className={`absolute bottom-1 left-0 ${progressStyle} h-1 bg-grey-100 transition-all duration-500`}
              data-testid='progress-bar'
            />
          </div>
        }
      </div>

      {workflow[currentStep]}

    </div>
  )
}