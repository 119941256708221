import { apiUrl, apiUrlV2 } from "../constants/apiUrl"
import sortUnitsByNapIssues from "../utilities/sortUnitsByNapIssues"
import createSearchParams from "../utilities/createSearchParams"

const initialState = {
  units: [],
  unit: undefined,
  comingDueUnits: [],
  soldUnits: [],
  amountDueOn: {},
  isFetchingAmountDue: false,
  unitsFetching: false,
  unitFetching: false,
  dashboardUnitId: null,
  amUnitsTableParams: '',
  dealerUnitsSearchBarValue: '',
  dealerUnitsPaginationData: {},
  selectedDealerUnitsScopeLabel: null,
  dealerUnitsPage: 1
}

const createDealerUnitsSlice = (set, get) => ({
  ...initialState,
  setDealerUnitsSearchBarValue: (params) => {
    set((state) => ({dealerUnitsSearchBarValue: params}))
  },
  setAmUnitsTableParams: (params = {}) => {
    set((state) => ({
      amUnitsTableParams: {
        'filter[scope]': 'current',
        ...params,
      }
    }))
  },

  setUnitsAsAmFetching: (boolean) => {
    set((state) => ({ unitsAsAmFetching: boolean }))
  },
  setSelectedDealerUnitsScopeLabel: (params) => {
    set((state) => ({selectedDealerUnitsScopeLabel: params}))
  },
  setDealerUnitsPage: (page) => {
    set((state) => ({dealerUnitsPage: page}))
  },
  fetchUnitsAsAm: async (dealerId, params = {}) => {
    set((state) => ({ unitsFetching: true }))

    const searchParams = {
      'filter[scope]': 'current',
      ...params,
    }

    try {
      const response = await fetch(
        `${apiUrlV2}/dealers/${dealerId}/units?page=${get().dealerUnitsPage}&${createSearchParams(searchParams)}`,
        { headers: get().requestHeaders }
      )
      const data = await response.json()

      if (get().user) {
        const units = data.data
        if (units?.some((u) => u.attributes['nap-issues'].length > 0)) {
          sortUnitsByNapIssues(units)
        }
        set((state) => ({
          units: units,
          dealerUnitsPaginationData: {
            dealerUnitsResultsCount: response.headers.get('x-total'),
            lastPage: response.headers.get('x-last-page'),
            totalPages: response.headers.get('x-total-pages'),
            currentPage: Number(response.headers.get('x-page')) - 1
          }
        }))
      }
    } catch (err) {
      get().setErrMessage('Unit data was not able to be fetched. Please refresh to try again')
    } finally {
      set(() => ({ unitsFetching: false }))
    }
  },
  resetUnitsAsAmSlice: () => set(initialState)
  })

  export default createDealerUnitsSlice
