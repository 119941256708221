import ReactPaginate from "react-paginate"
import { useIsMobile } from "../../hooks/useIsMobile"

export default function Pagination({currentPage, lastPage, dataTotal, handlePageClick, totalPages}) {
  const isMobile = useIsMobile()

  return(
    <>
      {totalPages > 1 &&
        <ReactPaginate
          className="flex justify-center pt-8 pb-8"
          previousLabel={currentPage > 0 ? 'previous' : ''}
          nextLabel={currentPage < lastPage ? 'next' : ''}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={Math.ceil(dataTotal / 20)}
          marginPagesDisplayed={isMobile ? 1 : 2}
          pageRangeDisplayed={isMobile ? 3 : 5}
          onPageChange={handlePageClick}
          forcePage={currentPage}
          pageLinkClassName={"relative inline-flex items-center  bg-grey-900 px-4 py-2 text-sm font-medium text-grey-100 hover:bg-grey-300 hover:text-black hover:rounded cursor-pointer"}
          nextLinkClassName={
            `relative inline-flex items-center  bg-grey-900 px-2 py-2 text-sm font-medium text-grey-100 hover:bg-grey-300 hover:text-black focus:z-20 hover:rounded cursor-pointer ${
              currentPage >= totalPages - 1 ? 'invisible' : ''
            }`
          }
          previousLinkClassName={
            `relative inline-flex items-center  bg-grey-900 px-2 py-2 text-sm font-medium text-grey-100 hover:bg-grey-300 hover:text-black focus:z-20 hover:rounded cursor-pointer ${
              currentPage <= 0 ? 'invisible' : ''
            }`
          }
          activeLinkClassName={
            `relative inline-flex items-center rounded border border-grey-100 bg-grey-300 px-2 py-2 text-sm font-medium text-black focus:z-20`
          }
        />
      }
    </>
  )
}