import { useNavigate } from 'react-router-dom'
import fxLogo from '../../public/fx-header-logo-white.png'

export default function LoginSupport() {
  const navigate = useNavigate()

  return (
    <div className="flex flex-col h-screen justify-center items-center">
        <img src={fxLogo} alt="Floorplan Xpress" className="h-20" />
        <div className="text-center p-4">
          Need help logging in? Please send an email with details on your problem to:
        </div>
        <div className="mb-3 py-2 px-4 bg-grey-900 rounded">
          <a
            href="mailto:dealerportal@floorplanxpress.com"
            className="underline hover:cursor-pointer hover:opacity-75"
          >
            dealerportal@floorplanxpress.com
          </a>
        </div>
        <div
          className="text-fpx-red hover:opacity-75 hover:cursor-pointer underline"
          onClick={() => navigate("/")}
        >
          Return to Login
        </div>
    </div>
  )
}