import useStore from "../../store"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import BankAccountName from "./BankAccountName"
import Spinner from "../common/Spinner"

export default function ACHAuthorization() {
  const bankAccounts = useStore((state) => state.bankAccounts)
  const dealer = useStore((state) => state.dealer )
  const achAuthorizeBankAccounts = useStore((state) => state.achAuthorizeBankAccounts)
  const setErrMessage = useStore((state) => state.setErrMessage)
  const resetMessages = useStore((state) => state.resetMessages)
  const [signature, setSignature] = useState('')
  const [checkedAccounts, setCheckedAccounts] = useState({})
  const [submitting, setSubmitting] = useState(false)
  const [invalidSignature, setInvalidSignature] = useState(false)
  const [noCheckedAccountsError, setNoCheckedAccountsError] = useState(false)
  const today = new Date
  const unauthorizedAccounts = bankAccounts.filter((acct) => acct.attributes["needs-ach-auth"])
  const navigate = useNavigate()

  const handleCheck = (e) => {
    setNoCheckedAccountsError(false)
    resetMessages()
    var obj = {}
    obj[e.target.id] = e.target.checked
    setCheckedAccounts({ ...checkedAccounts, ...obj })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (Object.keys(checkedAccounts).length === 0) {
      setNoCheckedAccountsError(true)
      setErrMessage('At least one account must be selected for authorization')
    } else if (signature == '') {
      setInvalidSignature(true)
      setErrMessage('Signature is required')
    } else {
      setSubmitting(true)
      const authorizedAccounts = Object.keys(checkedAccounts).filter((id) => checkedAccounts[id])
      const body = {
        terms_signature: signature,
        accounts: authorizedAccounts
      }
      try {
        const response = await achAuthorizeBankAccounts(body)
        if (response.ok) {
          navigate('/account')
        } else {
          setErrMessage('Unable to authorize bank accounts at this time')
        }
      } catch (err) {
        setErrMessage('Unable to reach server')
      } finally {
        setSubmitting(false)
      }
    }
  }

  return(
    <div className="flex grow overflow-y-auto justify-center w-full">
      <div className="flex flex-col items-center my-4 mx-2 w-full lg:w-1/2">
        <div className="text-lg underline underline-offset-4 decoration-green decoration-2">
          ACH Account Authorization
        </div>

        <div className="w-full mt-2">
          Company: <span className="underline">{dealer.attributes.name}</span>
        </div>
        <div className="my-4 text-sm">
          The undersigned hereby authorizes Floorplan Xpress, LLC, or its authorized designees (collectively “FX”), and
          the financial institution named below (“Bank”) to electronically debit/charge the Company’s account, as
          specified below, for recurring payments of principal, interest, premiums and fees due to FX under the Revolving
          Line Credit (“Floorplan”) Agreement, Demand Promissory Note Security Agreement, Reserves Agreement,
          And Guaranty Agreement dated <span className="underline">{today.toLocaleDateString([], { month: 'short', day: 'numeric', year: 'numeric' })}</span>. It is acknowledged that the origination of ACH
          transactions to the account must comply with applicable law.
        </div>
        <div className="my-4 text-sm">
          Debits and charges shall be pursuant to ACH (Automated Clearing House) Network guidelines. No prior-
          notification for each charge shall be required or provided. This authorization will remain in effect until
          cancelled in writing. The undersigned agrees to notify FX in writing of any changes in the account information
          or termination of this authorization at least 15 days prior to the next billing date.
          The undersigned represents that he/she is an authorized user and signor of this bank account and will not dispute
          these scheduled transactions so long as the transactions correspond to the terms indicated in this authorization
          form. Electronic versions of executed copies of this agreement shall be binding and enforceable and have the
          same force and effect as if they were original signatures.
        </div>

        <form
          className="flex flex-col items-center w-full"
          onSubmit={handleSubmit}
        >
          {unauthorizedAccounts.map((account) => (
            <div className="flex items-center justify-between w-full border-b border-green py-2 mb-2">
              <BankAccountName account={account} displayBank={true} />
              <label className={`${noCheckedAccountsError && 'text-fpx-red'}`}>
                Agree to Terms
                <input
                  type='checkbox'
                  id={account.id}
                  className='w-4 h-4 m-1 text-green bg-grey-300 border-grey-300 rounded focus:ring-green dark:focus:ring-blue-600 dark:ring-offset-grey-700 dark:focus:ring-offset-grey-700 focus:ring-2 dark:bg-grey dark:border-grey'
                  onChange={handleCheck}
                />
              </label>
            </div>
          ))}

          <label className={`w-full text-sm ${invalidSignature ? 'text-fpx-red' : 'text-grey-300'} my-2`}>
            Signature
            <input
              value={signature}
              aria-label="signature"
              name="signature"
              title="signature"
              className={`w-full text-md text-grey-100 p-0.5 bg-grey-900 border-solid border-2 focus:border-grey-300 focus:ring-0 ${invalidSignature ? 'border-fpx-red' : 'border-grey-700'} rounded`}
              onClick={() => {
                setInvalidSignature(false)
                resetMessages()
              }}
              onChange={(e) => setSignature(e.target.value)}
            />
          </label>

          {submitting ?
            <Spinner color={"green"} /> :
            <button
            type="submit"
            className="text-grey-100 hover:opacity-75 py-1.5 px-8 my-4 border border-2 border-green rounded rounded-full"
            >
              Submit
            </button>
          }
        </form>
        <div
          onClick={() => navigate("/account")}
          className="underline"
        >
          Cancel
        </div>
      </div>
    </div>
  )
}