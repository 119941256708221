import useStore from "../store"

export default function requestInterceptor() {
  const logoutUser = useStore((state) => state.logoutUser)
  const dfpApiUrl = import.meta.env['VITE_API_HOST']

  const { fetch: originalFetch } = window;
  window.fetch = async (...args) => {
    let [resource, config] = args

    let response = await originalFetch(resource, config)
    
    if (response.status == 401 && response.url.includes(dfpApiUrl)) {
      logoutUser()
    }

    return response
  }
}