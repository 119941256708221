import { apiUrlV2 } from "../constants/apiUrl"

const initialState = {
  reminders: null,
  reminder: null,
}

const createRemindersSlice = (set, get) => ({
  ...initialState,
  fetchReminders: async () => {
    try {
      const response = await fetch(`${apiUrlV2}/reminders`, {
        headers: get().requestHeaders
      })
      const data = await response.json()
      set({ reminders: data.data })
    } catch {
      get().setErrMessage('There was a problem while fetching reminders. Please refresh page.')
    }
  },
  setReminder: (reminder) => set(() => ({ reminder: reminder })),
  resetRemindersSlice: () => {
    set(initialState)
  }
})

export default createRemindersSlice