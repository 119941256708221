import useStore from "../../../store"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import TransferRow from "./TransferRow"
import ExpandableOptionsRow from "../../common/ExpandableOptionsRow"
import ActionConfirm from "../../common/ActionConfirm"
import { IoMdClose } from "react-icons/io"
import { FaTrash } from "react-icons/fa"

export default function PendingTransferRow({ transfer }) {
  const { t } = useTranslation()
  const [optionsOpen, setOptionsOpen] = useState(false)
  const [confirmCancel, setConfirmCancel] = useState(false)
  const cancelTransfer = useStore((state) => state.cancelTransfer)
  const setSuccessMessage = useStore((state) => state.setSuccessMessage)
  const setErrMessage = useStore((state) => state.setErrMessage)
  const fetchPendingTransfers = useStore((state) => state.fetchPendingTransfers)

  const handleCancelTransaction = async () => {
    setOptionsOpen(false)
    try {
      const response = await cancelTransfer(transfer.id)
      const data = await response.json()
      
      if (response.ok) {
        setSuccessMessage(data.message)
      } else if (data.message) {
        setErrMessage(data.message)
      } else {
        setErrMessage('There was a problem cancelling this transaction. Try again')
      }
    } catch (err) {
      setErrMessage('There was an error processing your request. Try again')
    } finally {
      fetchPendingTransfers()
    }
  }

  const optionsRowItems = [
    {
      icon: <FaTrash />,
      label: t('Cancel'),
      onClick: () => setConfirmCancel(true)
    },   
    {
      icon: <IoMdClose />,
      label: t('Close'),
      onClick: () => setOptionsOpen(false)
    }
  ]

  return(
    <ExpandableOptionsRow
      items={optionsRowItems}
      optionsOpen={optionsOpen}
    >
      <TransferRow 
        date={transfer.attributes['actual-transfer-date']}
        type={transfer.attributes['payment-type']}
        unitName={`${transfer.attributes.make} ${transfer.attributes.model}`}
        amount={transfer.attributes.amount}
        filter={''}
        key={transfer.id}
        isExpandable={true}
        onExpand={() => setOptionsOpen(true)}
      />

      {confirmCancel && 
        <ActionConfirm 
          body="Are you sure you wish to cancel this pending transaction?" 
          onCancel={() => setConfirmCancel(false)} 
          onConfirm={() => { 
            handleCancelTransaction() 
            setConfirmCancel(false)
          }}
          confirmText={t('Yes')}
          cancelText={t('Never Mind')}
        />
      }
    </ExpandableOptionsRow>
  )
}
