import useStore from "../../../../store"
import PersonData from "./PersonData"

export default function People({dealerId}) {
  const people = useStore((state) => state.people)

  return(
    <div>
      <div className="w-full text-left bg-grey-900 rounded-t">
        <h3 className="font-header font-semibold text-md text-green">{'People'}</h3>
      </div>
      {people.map((person) => (
        <PersonData key={person.id} personId={person.id} dealerId={dealerId} person={person}/>
      ))}
    </div>
  )
}