import TableWithSorting from '../../common/TableWithSorting'
import useStore from '../../../store'
import AuditRow from './AuditRow'

export default function AuditsTable() {
  const fieldAudits = useStore((state) => state.fieldAudits)
  const fieldAuditsFetching = useStore((state) => state.fieldAuditsFetching)
  const fetchUnreconciledFieldAudits = useStore((state) => state.fetchUnreconciledFieldAudits)
  const fieldAuditsTablePage = useStore((state) => state.fieldAuditsTablePage)
  const setFieldAuditsTablePage = useStore((state) => state.setFieldAuditsTablePage)
  const { currentPage, lastPage, dataTotal, totalPages } = useStore((state) => state.auditsPaginationData)

  const columns = [
    { key: "dealer_name", headerText: "Dealer", isSortable: true },
    { key: "date_completed", headerText: "Date", isSortable: true },
    { key: "status", headerText: "Status", isSortable: false },
    { key: "total_units", headerText: "Total Units", isSortable: true },
    { key: "3c_units", headerText: "3Cs", isSortable: true },
    { key: "unaccounted_units", headerText: "Unaccounted Units", isSortable: false },
    { key: "sold_units", headerText: "Sold Units", isSortable: true },
  ]

  return (
    <TableWithSorting
      data={fieldAudits}
      loading={fieldAuditsFetching}
      columns={columns}
      fetchData={fetchUnreconciledFieldAudits}
      paginationData={{
        currentPage,
        lastPage,
        dataTotal,
        totalPages
      }}
      setPage={setFieldAuditsTablePage}
      RowComponent={AuditRow}
      page={fieldAuditsTablePage}
    />
  )
}
