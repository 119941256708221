import useStore from "../../../store"
import { useEffect, useState } from "react"
import NotesRow from "./NotesRow"
import NoteDetails from "./NoteDetails"
import DealerNoteForm from "./DealerNoteForm"
import Pagination from "../../common/Pagination"
import SlideInInfoPage from "../../common/SlideInInfoPage"
import HeaderWithCreateIcon from "../../common/HeaderWithCreateIcon"

// Notes Body type is either "dealers", "units", or "audits"
export default function NotesBody({ type, id, searchTerm, showNoteForm, setShowNoteForm}) {
  const notes = useStore((state) => state.notes)
  const setNotesPage = useStore((state) => state.setNotesPage)
  const { currentPage, lastPage, dataTotal, totalPages } = useStore((state) => state.notesPaginationData)
  const [selectedNote, setSelectedNote] = useState(null)
  const selectedNotesScopeLabel = useStore((state) => state.selectedNotesScopeLabel)

  useEffect(() => {
    setSelectedNote(null)
  }, [notes])

  const handlePageClick = (event) => {
    setNotesPage(event.selected + 1)
  }

  const handleClick = () => {
    setShowNoteForm(true)
  }

  const onBack = () => {
    setSelectedNote(null)
    setShowNoteForm(false)
  }

  return(
    <div className="flex flex-col w-full bg-grey-900 p-2">
      {selectedNote || showNoteForm ?
        <SlideInInfoPage onBack={onBack}>
          {selectedNote ?
            <NoteDetails note={selectedNote} />
           :
            <DealerNoteForm setShowNoteForm={setShowNoteForm} />
          }
        </SlideInInfoPage>
        :
        <>
          {!selectedNotesScopeLabel &&
            <HeaderWithCreateIcon
              selectedItem={selectedNote}
              onClick={handleClick}
              title={'Notes'}
              dataTestId={'create-note'}
              showForm={showNoteForm}
            />
          }
          {notes.map((n) => (
            <NotesRow key={n.id} note={n} onClick={() => setSelectedNote(n)} filter={searchTerm}/>
          ))}
          <Pagination
            currentPage={currentPage}
            lastPage={lastPage}
            dataTotal={dataTotal}
            handlePageClick={handlePageClick}
            totalPages={totalPages}
          />
        </>
      }
    </div>
    )
}