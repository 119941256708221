import { useTranslation } from "react-i18next"

export function Payoff() {
  const { t } = useTranslation()
  return(
    <div 
      className="flex justify-center bg-grey-900 text-green border border-green text-xs font-medium w-14 m-1 py-0.5 rounded"
      data-testid='payoff-pill'
    >
      {t('Payoff')}
    </div>
  )
}

export function Refloor() {
  const { t } = useTranslation()
  return(
    <div
      className="flex justify-center bg-grey-900 text-yellow border border-yellow text-xs font-medium w-14 m-1 py-0.5 rounded"
      data-testid='refloor-pill'
    >
      {t('Refloor')}
    </div>
  )
}

export function OtherPayment({ type }) {
  const { t } = useTranslation()
  return(
    <div className="flex justify-center text-center text-black bg-yellow text-xs font-medium w-14 m-1 py-0.5 rounded">
      {t(type)}
    </div>
  )
}

export function HappyPill({ text }) {
  return(
    <div className="flex justify-center border border-green text-green text-xs font-medium text-center px-1 m-1 py-0.5 rounded">
      {text}
    </div>
  )
}

export function WarningPill({ text }) {
  return(
    <div className="flex justify-center border border-yellow text-yellow text-xs font-medium text-center px-1 m-1 py-0.5 rounded">
      {text}
    </div>
  )
}

export function SadPill({ text }) {
  return(
    <div className="flex justify-center border border-fpx-red text-fpx-red text-xs font-medium text-center px-1 m-1 py-0.5 rounded">
      {text}
    </div>
  )
}