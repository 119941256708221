const isValidVin = (vin) => {
  const fullVinRegex = /^(?!.*[IOQ])[A-HJ-NPR-Z0-9]{17}$/
  const lastSixVinRegex = /^[A-HJ-NPR-Z0-9]{6}$/

  if (fullVinRegex.test(vin)) {
    return true
  }

  if (vin.length === 6 && lastSixVinRegex.test(vin)) {
    return true
  }

  return false
}

export default isValidVin