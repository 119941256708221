import { CometChatUIKit } from "@cometchat/chat-uikit-react"
import { cometChatApiUrl } from "../constants/apiUrl"

const authKey = import.meta.env.VITE_COMETCHAT_AUTH_KEY
const apiKey = import.meta.env.VITE_COMETCHAT_API_KEY

const initialState = {
  chatUser: null,
}

const createCometChatSlice = (set, get) => ({
  ...initialState,
  loginChatUser: async () => {
    const user = get().user

    const cometChatUser = await CometChatUIKit.getLoggedinUser()

    if (cometChatUser) {
      set({ chatUser: cometChatUser })
      return
    }

    let loginUser

    try {
      loginUser = await CometChatUIKit.login(user["comet_chat_id"], authKey)
      set({ chatUser: loginUser })

      console.log("Login Successful:", { loginUser })
    } catch (err) {
      console.error(err)
    }
  },
  logoutChatUser: async () => {
    const user = await CometChatUIKit.getLoggedinUser()
    if (user) {
      try {
        await CometChatUIKit.logout()
        console.log("Logout completed successfully")
      } catch (err) {
        console.error("Logout failed with exception:", { err })
      }
    }
  },
  resetCometChatSlice: () => set(initialState),
})

export default createCometChatSlice
