import { motion } from "framer-motion"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import useStore from "../../store"
import ClickableRow from "../common/ClickableRow"
import { DateTime } from "luxon"
import createNotificationLinks from '../../utilities/createNotificationLinks'
import QuickPayoff from "../Dealer/CurrentUnits/QuickPayoff"

export default function NotificationDetail() {
  const notification = useStore((state) => state.notification)
  const [isProcessingQuickPayoff, setIsProcessingQuickPayoff] = useState(false)
  const date = DateTime.fromISO(notification.attributes['created-at'])
  const navigate = useNavigate()

  return(
    <div className="flex flex-col grow items-center">
      <div className="flex flex-col w-full lg:w-1/2 lg:bg-grey-900 lg:h-[85vh] overflow-x-hidden">
        <div className="flex w-full items-center p-2">
          <h3 className="font-header font-semibold text-md text-green mr-1">
            Notifications
          </h3>
        </div>
        <motion.div
          initial={{ x: "100%" }}
          animate={{ x: 0 }}
          transition={{ type: 'tween', duration: 0.2 }}
          className="flex flex-col h-full grow"
        >
          <div>
            <div className="sticky z-30 p-2 rounded-t">
              <div className="font-black text-2xl">{notification.attributes.title}</div>
              <div className="text-grey-300">{date.toRelative({ style: "short" })}</div>
            </div>

            <div className="ml-2">
              {notification.attributes.body}
            </div>
          </div>

          <div className="p-2">
            {createNotificationLinks(notification.attributes.metadata).map((linkInfo) =>
              <ClickableRow
                text={`Go to ${linkInfo.name}`}
                onClick={() => navigate(linkInfo.link)}
              />
            )}

            {(notification.attributes.title === 'Unit Coming Due' || notification.attributes.title === 'Unit Past Due') &&
              <ClickableRow
                text="Create Quick Payoff"
                onClick={() => setIsProcessingQuickPayoff(true)}
              />
            }

            <ClickableRow
              text="Back to Notifications"
              onClick={() => navigate('/notifications')}
            />
          </div>
        </motion.div>

      </div>

      {isProcessingQuickPayoff &&
        <QuickPayoff
          unitId={notification.attributes.metadata.unit_id}
          setIsProcessingQuickPayoff={setIsProcessingQuickPayoff}
        />
      }
    </div>
  )
}