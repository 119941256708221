import { useState, useEffect } from "react"
import {
  CometChatMessageHeader,
  CometChatMessageComposer,
  CometChatMessageList,
} from "@cometchat/chat-uikit-react"
import { CometChat } from "@cometchat/chat-sdk-javascript"
import {
  MessageHeaderStyle,
  MessageComposerStyle,
  MessageListStyle,
} from "@cometchat/uikit-shared"
import tailwind from "../../../../tailwind.config"
import useStore from "../../../store"

export default function Chat() {
  const user = useStore((state) => state.user)

  const grey900 = tailwind.theme.colors["grey-900"]
  const grey = tailwind.theme.colors["grey"]
  const grey300 = tailwind.theme.colors["grey-300"]
  const grey100 = tailwind.theme.colors["grey-100"]
  const font = tailwind.theme.fontFamily["sans"]
  const [dealerGroup, setDealerGroup] = useState(null)

  useEffect(() => {
    try {
      console.log("fetching group for dealer", user["dealerId"])

      CometChat.getGroup(`dealer-${user["dealerId"]}`).then((group) => {
        setDealerGroup(group)
      })
    } catch (error) {
      console.error(error)
    }
  }, [])

  return (
    <div className="w-full max-h-dvh">
      <div className="w-full">
        {dealerGroup && (
          <>
            <div className="groupMessageHeader w-full">
              <CometChatMessageHeader
                group={dealerGroup}
                hideBackButton={true}
                messageHeaderStyle={
                  new MessageHeaderStyle({
                    borderRadius: "0.25rem",
                    background: grey900,
                    subtitleTextFont: font,
                    subtitleTextColor: grey300,
                    width: "100%",
                  })
                }
              />
            </div>
            <div className="groupBox flex">
              <div className="messageBox w-full">
                <div className="messageList lg:overflow-y-scroll ">
                  <CometChatMessageList
                    group={dealerGroup}
                    messageListStyle={
                      new MessageListStyle({
                        TimestampTextColor: grey300,
                        nameTextColor: grey300,
                        threadReplyTextColor: grey300,
                        loadingIconTint: grey300,
                        emptyStateTextColor: grey300,
                        height: "73dvh",
                      })
                    }
                    scrollToBottomOnNewMessages={true}
                  />
                </div>
                <div className="messageComposer">
                  <CometChatMessageComposer
                    group={dealerGroup}
                    messageComposerStyle={
                      new MessageComposerStyle({
                        background: grey900,
                        borderRadius: "0.25rem",
                        inputBorder: `2px solid ${grey300}`,
                        textColor: grey100,
                        textFont: font,
                        placeHolderTextFont: font,
                        placeHolderTextColor: grey,
                        attachIcontint: grey,
                        emojiIconTint: grey,
                        voiceRecordingIconTint: grey,
                        sendIconTint: grey300,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
