import { apiUrl } from "../constants/apiUrl"
import {
  create,
  parseCreationOptionsFromJSON,
} from "@github/webauthn-json/browser-ponyfill"

export default async function registerBiometric(headers) {
  const fetchRegOptions = async () => {
    try {
      const response = await fetch(`${apiUrl}/auth/registration_options`, {
          headers: headers,
        })
      const data = await response.json()
      return data
    } catch(err) {
      return null
    }
  }

  const data = await fetchRegOptions()

  if (data) {
    const cco = parseCreationOptionsFromJSON({
      publicKey: data.options,
    })
    const registration = await create(cco)

    try {
      const res = await fetch(`${apiUrl}/auth/register_passkey?challenge_id=${data.challenge_id}`, {
        headers: headers,
        method: 'POST',
        body: JSON.stringify({ publicKeyCredential: registration.toJSON() })
      })
      if (res.ok) {
        const data = await res.json()
        return {
          message: 'Credential successfully registered',
          webauthnId: data.webauthn_id,
          status: 'Successful'
        }
      } else {
        const data = await res.json()
        return { message: data.message, status: 'Failed' }
      }
    } catch(err) {
      return { message: 'Unable to reach server', status: 'Failed' }
    }
  } else {
    return { message: 'Unable to reach server', status: 'Failed' }
  }
}