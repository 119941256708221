import { useEffect } from 'react'
import AmDashboard from "./AccountManager/AmDashboard"
import DealerDashboard from "./Dealer/DealerDashboard"
import SupportDashboard from "./Support/SupportDashboard"
import Login from "./Login"
import useStore from "../store"

export default function Dashboard() {
  const user = useStore((state) => state.user)
  const setNavBarHidden = useStore((state) => state.setNavBarHidden)
  const setTopNavHidden = useStore((state) => state.setTopNavHidden)

  useEffect(() => {
    setNavBarHidden(false)
    setTopNavHidden(false)
  })

  if (user) {
    if (user.type === "AccountManager") {
      return <AmDashboard />
    } else if (user.type === 'DealerUser') {
      return <DealerDashboard/>
    } else {
      return <SupportDashboard />
    }
  }

  return <Login />
}
