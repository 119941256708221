import { useTranslation } from "react-i18next"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import useStore from "../../../store"
import SelectDropdown from "../../common/SelectDropdown"
import BankAccountName from "./BankAccountName"
import { defaultBankAccount, filterAuthorizedBankAccounts } from "../../../utilities/bankAccountUtilities"
import InfoItem from "../../common/InfoItem"

export default function BankAccountSelect ({ selectedAccount, setSelectedAccount, isErrored = false, isSelectDefaultAccountEnabled = true }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const bankAccounts = useStore((state) => state.bankAccounts)

  useEffect(() => {
    if (bankAccounts && isSelectDefaultAccountEnabled) {
      setSelectedAccount(defaultBankAccount(bankAccounts))
    }
  }, [bankAccounts])

  if (!bankAccounts || bankAccounts.length === 0) {
    return <InfoItem title='Bank Account' data={t("No Bank Accounts Available")} />
  } else if (filterAuthorizedBankAccounts(bankAccounts).length === 0) {
    return (
      <InfoItem title='Bank Account'>
        <div onClick={() => navigate('/ach_auth')} className="underline hover:cursor-pointer">
          {t('Click here to authorize accounts for ach transfer.')}
        </div>
      </InfoItem>
    )
  } else {
    return (
      <SelectDropdown
        options={filterAuthorizedBankAccounts(bankAccounts)}
        defaultText={t('Select an account')}
        selectedItem={selectedAccount}
        setSelectedItem={setSelectedAccount}
        displaySelectedItem={(item) => <BankAccountName account={item} />}
        label='Bank Account'
        isErrored={isErrored}
      />
    )
  }
}