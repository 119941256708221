import useStore from "../../../store"
import { useEffect, useState } from 'react'
import BusinessDevTable from "./BusinessDevTable"
import ProspectDealerBody from "../Dealers/ProspectDealerBody"
import DashboardContainer from "../../common/DashboardContainer"
import AppPageBody from "../Apps/AppPageBody"
import DashboardAppInfo from "../Apps/DashboardAppInfo"
import DesktopExpandableInfo from "../../common/DesktopExpandableInfo"
import DashboardProspectDealerInfo from "../Dealers/DashboardProspectDealerInfo"
import BusinessDevMenu from "./BusinessDevMenu"

export default function BusinessDevPage() {
  const [searchTerm, setSearchTerm] = useState('')
  const fetchBusinessDevData = useStore((state) => state.fetchBusinessDevData)
  const setBusinessDevTableParam = useStore((state) => state.setBusinessDevTableParam)
  const setBusinessDevTableType = useStore((state) => state.setBusinessDevTableType)
  const businessDevTableParam = useStore((state) => state.businessDevTableParam)
  const businessDevTableType = useStore((state) => state.businessDevTableType)
  const setBusinessDevTablePage = useStore((state) => state.setBusinessDevTablePage)
  const setBusinessDevTableName = useStore((state) => state.setBusinessDevTableName)
  const businessDevTableName = useStore((state) => state.businessDevTableName)
  const setBusinessDevTableFilterAttribute = useStore((state) => state.setBusinessDevTableFilterAttribute)
  const setBusinessDevTableFilterDirection = useStore((state) => state.setBusinessDevTableFilterDirection)
  const dashboardAppId = useStore((state) => state.dashboardAppId)
  const setDashboardAppId = useStore((state) => state.setDashboardAppId)
  const prospectDealerId = useStore((state) => state.prospectDealerId)
  const setProspectDealerId = useStore((state) => state.setProspectDealerId)
  const dealerId = useStore((state) => state.dealerId)
  const setDealerId = useStore((state) => state.setDealerId)

  const handleResetFilter = () => {
    setBusinessDevTableFilterAttribute('')
    setBusinessDevTableFilterDirection('')
    setSearchTerm('')
  }

  useEffect(() => {
    fetchBusinessDevData()
  }, [])

  const resetSidePanel = () => {
    setProspectDealerId(null)
    setDashboardAppId(null)
    setDealerId(null)
  }

  const handleTableClick = (type, name, param) => {
    handleResetFilter()
    resetSidePanel()

    if (param === businessDevTableParam) {
      setBusinessDevTableParam(null)
      setBusinessDevTableType(null)
      setBusinessDevTableName(null)
    } else {
      setBusinessDevTableParam(param)
      setBusinessDevTableType(type)
      setBusinessDevTableName(name)
      setBusinessDevTablePage(1)
      setDashboardAppId(null)
    }
  }

  const sidePanels = [
    {
      isExpanded: dashboardAppId,
      component: <AppPageBody dealerId={dealerId} appId={dashboardAppId} />,
      onClose: resetSidePanel,
      isFullscreenable: true,
      isClosable: true
    },
    {
      isExpanded: prospectDealerId,
      component: <ProspectDealerBody />,
      onClose: resetSidePanel,
      isFullscreenable: true,
      isClosable: true
    }
  ]

  return(
      <DashboardContainer sidePanels={sidePanels}>
        <div className="flex flex-col w-full items-center">
          <div className="sticky top-0 left-0 z-30 w-full bg-grey-900 rounded-t">
            <div className="flex w-full items-center pt-2 pb-3 px-2">
              <h3 className="font-header font-semibold text-md text-green mr-1">
                Business Development
              </h3>
            </div>
          </div>
          <BusinessDevMenu
            handleTableClick={handleTableClick}
          />
        </div>
        <BusinessDevTable
          tableName={businessDevTableName}
          tableType={businessDevTableType}
          param={businessDevTableParam}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
    </DashboardContainer>
  )
}
