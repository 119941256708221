import DealerRow from "../Dealers/DealerRow"
import RiskAuditRow from "./RiskAuditRow"

export default function RiskDealerRow({ dealer }) {
  return(
    <div className="border-b-1 border-grey-700">
    {dealer.type === 'eaudits' ?
      <RiskAuditRow
        fieldAudit={dealer}
      />
      :
      <div className="flex">
        <DealerRow
          dealer={dealer}
          metrics={false}
        />
      </div>
    }
    </div>
  )
}