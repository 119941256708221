import { apiUrlV2 } from "../constants/apiUrl"

const MAX_BLACKBOOK_NUMBER = 5

const initialState = {
  blackbook: null,
  showBlackbook: false,
  recentBlackbooks: [],
}

const createBlackbookSlice = (set, get) => ({
  ...initialState,
  searchBlackbook: (body) => fetch(
    `${apiUrlV2}/blackbooks/search`, { 
      method: 'POST',
      headers: get().requestHeaders,
      body: JSON.stringify(body)
    }
  ),
  setBlackbook: (bb) => set((state) => ({ blackbook: bb })),
  addRecentBlackbook: (bb) => set((state) => ({ recentBlackbooks: [...state.recentBlackbooks, bb].slice(-MAX_BLACKBOOK_NUMBER) })),
  resetBlackbookSlice: () => set(initialState)
})

export default createBlackbookSlice