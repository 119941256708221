import { Button } from "@chakra-ui/react"
import useStore from "../../store"

export default function FilterIndicator({filterName, displayFor, onClick}) {
  return(
    <>
      {displayFor && (
        <Button
          variant="outline"
          borderColor={'white'}
          color={'white'}
          border={'2px'}
          bg="transparent"
          onClick={onClick}
          sx={{
            borderRadius: 'full'
          }}
        >
          {filterName}
        </Button>
      )}
    </>
  )
}