import { Box, Text } from "@chakra-ui/react"
import useStore from "../../../store"
import CouponCard from "./CouponCard"
import { useEffect, useState } from "react"
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { useIsMobile } from "../../../hooks/useIsMobile"
import tailwind from "../../../../tailwind.config"

export default function Coupons() {
  const fetchCoupons = useStore((state) => state.fetchCoupons)
  const coupons = useStore((state) => state.coupons)
  const fetchCoupon = useStore((state) => state.fetchCoupon)
  const isMobile = useIsMobile()
  const grey100 = tailwind.theme.colors["grey-100"]
  const totalSlides = coupons?.length || 1
  const setCouponInfoPageId = useStore((state) => state.setCouponInfoPageId)
  const couponInfoPageId = useStore((state) => state.couponInfoPageId)
  const [swiperKey, setSwiperKey] = useState(0)
  const currentSlide = useStore((state) => state.currentSlide)
  const setCurrentSlide = useStore((state) => state.setCurrentSlide)

  useEffect(() => {
    fetchCoupons().then(() => {
      setSwiperKey((prevKey) => prevKey + 1)
    })  }, [])

  const handleSlideChange = (swiper) => {
    setCurrentSlide(swiper.realIndex + 1)
    const selectedCouponId = coupons[swiper.realIndex]?.id
    if(selectedCouponId) {
      fetchCoupon(selectedCouponId)
      if(couponInfoPageId !== null) {
        setCouponInfoPageId(selectedCouponId)
      }
    }
  }

  return(
    <Box pb={(isMobile && coupons?.length >= 1) && 2} pos={'relative'}>
      {coupons?.length > 1 &&
        <Box
          position="absolute"
          top="5px"
          right={isMobile ? "12px": "50px"}
          zIndex="20"
          color={grey100}
          px="2"
          py="1"
        >
          <Text fontSize="sm">{currentSlide} / {totalSlides}</Text>
        </Box>
      }
      <Swiper
        key={swiperKey}
        modules={[Pagination, Navigation]}
        loop={true}
        pagination={false}
        navigation={!isMobile && true}
        initialSlide={currentSlide - 1}
        style={{
          zIndex: 10,
          '--swiper-navigation-color': grey100,
          '--swiper-pagination-color': grey100,
        }}
        onSlideChange={handleSlideChange}
      >
        {coupons?.length > 0 &&
          coupons.map((coupon, index) => (
            <SwiperSlide key={index}>
                <CouponCard coupon={coupon} key={coupon.id}/>
            </SwiperSlide>
          ))
        }
      </Swiper>
    </Box>
  )
}