import { useState } from "react"
import { useDebouncedCallback } from 'use-debounce'
import { FaUndoAlt } from "react-icons/fa"
import SearchBar from "./SearchBar"
import useStore from "../../store"
import Spinner from "./Spinner"
import { IoFilterSharp } from "react-icons/io5"

export default function AmSearchBar({ handleSearch, handleReset, onOpen, showFilterIcon, dataFetching, searchBarValue, setSearchBarValue }) {
  const debouncedSearch = useDebouncedCallback(
    (value) => {
      handleSearch(value)
    },
    500
  )

const handleChange = (value) => {
    setSearchBarValue(value)
    debouncedSearch(value)
  }


  const handleSearchBarBlur = () => {
    setSearchBarValue('')
  }

  return(
    <div className="flex w-full items-center bg-grey-900 text-left text-md text-grey-300 mx-1 hover:cursor-pointer">
      <div className="flex items-center justify-center mr-2" style={{ width: "25px", height: "25px" }}>
        {dataFetching ? (
          <Spinner color="green" height={"25px"} />
        ) : (
          <FaUndoAlt
            onClick={() => handleReset()}
            className="text-md text-grey-300"
            data-testid="search-reset"
            style={{ fontSize: "25px", width: "25px", height: "25px" }}
          />
        )}
      </div>
      <div className="flex w-full items-center">
        <SearchBar onChange={handleChange} value={searchBarValue} onBlur={handleSearchBarBlur} />
        {showFilterIcon &&
          <IoFilterSharp
            data-testid="filter-button"
            onClick={onOpen}
            style={{ cursor: "pointer", marginLeft: "10px" }}
            className="text-2xl text-grey-300"
          />
        }
      </div>
    </div>
  )
}