import useStore from '../../store'
import { Link } from 'react-router-dom'
import fxLogo from '../../../public/fpx-logo.png'
import MobileNav from './MobileNav'
import MobileTopNav from './MobileTopNav'
import SideBar from './SideBar'
import { FaBars } from 'react-icons/fa'
import OfflineAlert from '../common/OfflineAlert'
import StagingBanner from './StagingBanner'
import environment from '../../constants/environment'
import ActionButton from '../common/ActionButton'
import headerLogo from '../../../public/fx-header-logo-white.png'
import { useNavigate } from 'react-router-dom'
import NotificationsBell from './NotificationsBell'
import NavBarBackButton from './NavBarBackButton'
import { reminderOverdue } from '../../utilities/reminderUtilities'

export default function NavBar({ children }) {
  const navigate = useNavigate()
  const setShowSidebar = useStore((state) => state.setShowSidebar)
  const user = useStore((state) => state.user)
  const notifications = useStore((state) => state.notifications)
  const reminders = useStore((state) => state.reminders)
  const actionButton = useStore((state) => state.actionButton)
  const navBarHidden = useStore((state) => state.navBarHidden)
  const topNavHidden = useStore((state) => state.topNavHidden)

  const hideMobileNav = () => !user || navBarHidden
  const hideMobileTopNav = () => hideMobileNav() || topNavHidden

  return (
    <div className='flex flex-col min-h-dvh max-h-dvh'>
      {environment === 'staging' && <StagingBanner />}
      <div className="bg-black hidden w-full h-full lg:block lg:sticky lg:mb-4 top-0 left-0 z-40">
        <div className='flex flex-col'>
          <div
            className={`relative flex items-center mt-2 ${hideMobileNav() && 'hidden'}`}
            data-testid='desktop-topnav'
          >
            <FaBars
              className="hidden lg:block ml-4 mr-6 hover:cursor-pointer hover:text-grey-100 text-grey-300"
              size={20}
              onClick={() => setShowSidebar(true)}
            />
            <Link to='/'>
              <img src={user?.type === 'AccountManager' ? fxLogo : headerLogo} alt="Floorplan Xpress" className="hidden lg:block h-12" />
            </Link>

            <div className="absolute right-3 flex items-center justify-center h-full">
              <OfflineAlert />
              {user?.type === 'DealerUser' &&
                <NotificationsBell
                  isOnAlert={notifications?.some((n) => n.attributes['read-at'] === null)}
                  onClick={() => navigate('/notifications')}
                />
              }

              {user?.type === 'AccountManager' &&
                <NotificationsBell
                  isOnAlert={reminders?.some((r) => reminderOverdue(r))}
                  onClick={() => navigate('/reminders')}
                />
              }
            </div>

            <SideBar />
          </div>
          {user?.type === "DealerUser" && <NavBarBackButton styles={`${hideMobileNav() && 'hidden'} text-grey-300 hover:text-grey-100 ml-4`} />}
        </div>
      </div>

      <div
        className={`z-40 top-0 left-0 bg-black ${hideMobileTopNav() && 'hidden'} lg:hidden`}
        data-testid='mobile-topnav'
      >
        <MobileTopNav />
      </div>

        {children}

      <div
        className={`bottom-0 left-0 bg-black ${hideMobileNav() && 'hidden'}  lg:hidden`}
        data-testid='mobile-bottomnav'
      >
        {actionButton &&
          <ActionButton
            buttonText={actionButton.buttonText}
            altTextHeader={actionButton.altTextHeader}
            altTextBody={actionButton.altTextBody}
            onClick={actionButton.onClick}
          />
        }
        <MobileNav />
      </div>
    </div>
  )
}