import OptionsRowItem from "./OptionsRowItem"

export default function ExpandableOptionsRow({ children, items, optionsOpen }) {

  return(
    <div className="relative overflow-hidden w-full">
      {children}

      <div 
        className={`absolute top-0 ${optionsOpen ? 'right-0' : '-right-80'} bg-fpx-red px-2 py-0.5 h-full text-sm rounded transition-all duration-500`}
        data-testid="options"
      >
        <div className="flex items-center justify-evenly h-full w-full pr-2">
          {items.map((i, index) => 
            <OptionsRowItem 
              key={index}
              icon={i.icon}
              label={i.label}
              onClick={i.onClick}
            />
          )}
        </div>
      </div>
    </div>
  )
}