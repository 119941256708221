import BBSearchForm from "./BBSearchForm"
import RecentFloorRequests from "./RecentFloorRequests"


export default function Blackbook() {
  return(
    <div className="flex flex-col grow items-center overflow-y-auto w-full">
      <div className="relative w-full lg:w-1/2 grow overflow-x-hidden">
        <BBSearchForm />
        <RecentFloorRequests />
      </div>
    </div>
  )
}