import Spinner from "./Spinner"
import { CloseButton } from "@chakra-ui/react"
import tailwind from "../../../tailwind.config"

export default function SideInfoPanel({title, body, isLoading, onClose}) {
  const red = tailwind.theme.colors["fpx-red"]
  const grey900 = tailwind.theme.colors["grey-900"]

  return (
    <div className="relative flex flex-col items-center w-full">
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="w-full lg:h-full bg-grey-900 p-2 mb-2 rounded">
          <div className="flex justify-between items-center w-full mb-2">
            <span className="font-header font-semibold text-md text-green">
              {title}
            </span>
            <CloseButton
              bg={red}
              color={grey900}
              fontSize={'lg'}
              fontWeight={'bold'}
              className="absolute top-4 right-2 text-2xl hover:cursor-pointer z-[35]"
              onClick={onClose}
            />
          </div>
          <div>
            {body}
          </div>
        </div>
      )}
    </div>
  )
}