import useStore from "../../store"

export default function UnitRowStyleSwitcher() {
  const setUnitRowStyle = useStore((state) => state.setUnitRowStyle)
  const unitRowStyle = useStore((state) => state.unitRowStyle)

  const styles = {
    sm: 'left-0',
    md: 'left-1/3',
    lg: 'left-2/3'
  }

  return(
    <div className="relative my-1 w-1/2 border border-grey rounded">
      <div
        className={`absolute w-1/3 h-full border border-2 rounded bottom-0 ${styles[unitRowStyle]} transition-all duration-300`}
      />
      <div className="flex justify-evenly w-full">
        {Object.keys(styles).map((style) => (
          <button
            key={style}
            onClick={() => setUnitRowStyle(style)}
            disabled={unitRowStyle === style}
            className={`px-4 w-1/3 rounded ${unitRowStyle === style ? 'text-grey-100 border-grey-100' : 'text-grey border-grey-900'} transition-all duration-500`}
          >
            {style}
          </button >
        ))}
      </div>
    </div>
  )
}