import { useEffect, useState } from "react"
import useStore from "../../../store"
import Spinner from "../../common/Spinner"
import { IoMdClose } from "react-icons/io"
import RatesRow from "./RatesRow"


export default function SummaryOfRates({ close }) {
  const fetchBaseRateFees = useStore((state) => state.fetchBaseRateFees)
  const setErrMessage = useStore((state) => state.setErrMessage)
  const dealer = useStore((state) => state.dealer)
  const [baseRates, setBaseRates] = useState(null)
  const date = new Date

  useEffect(() => {
    fetchBaseRates()
  }, [])

  const fetchBaseRates = async () => {
    try {
      const response = await fetchBaseRateFees()
      console.log(response)

      if (response.status === 200) {
        const data = await response.json()
        setBaseRates(data)
      } else {
        setErrMessage('Could not fetch summary of rates. Please try again')
      }
    } catch (err) {
      setErrMessage('There was an error fetching summary of rates. Please try again')
    }
  }


  return(
    <div className="flex flex-col items-center grow overflow-y-auto">
      {baseRates ? 
        <div className="relative flex flex-col items-center lg:w-3/4 bg-grey-900 my-3 rounded">
          <div className="relative w-full h-1">
            <IoMdClose 
              className="absolute right-0 text-3xl hover:cursor-pointer"
              data-testid="close"
              onClick={close} 
            />
          </div>

          <div className='py-2 pl-2 pr-6'>
            {`Not all fees may apply to all purchases. At Floorplan Xpress we want to be clear about 
            any fees which may be applicable. Fees depend on what you floor, purchase price, type of 
            purchase and the time each unit remains on your floor plan. Summary of Rates for 
            ${dealer.attributes.name} are current as of 
            ${date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric'})} 
            ${date.toLocaleTimeString('en-US')}`}
          </div>

          <table className="table-auto w-full">
            <tr className="font-header font-semibold text-md text-green">
              <th className="py-2">Type</th>
              <th className="py-2">Amount</th>
              <th className="py-2">Description</th>
            </tr>
            {baseRates.map((rate) => {
              return(<RatesRow rate={rate} />)
            })}
          </table>
          <div className='my-5 mx-2 text-center text-sm'>
            All other fees charged and assessed by Lender that are not identified above are charges as other fees as set forth in the Agreement. Lender is not required, but may grant Dealer's request for additional services and terms to be provided under the Note. In the event Lender grants Dealer's request, the applicable Fee will be charged.
          </div>
        </div> :
        <Spinner />
      }
    </div>
  )
}