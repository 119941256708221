import { actionCableUrl } from "../constants/apiUrl"
import ActionCable from 'actioncable'
import createNotificationLinks from "../utilities/createNotificationLinks"

const initialState = {
  consumer: null,
}

const createActionCableSlice = (set, get) => ({
  updateConsumer: (consumer) => set(() => ({ consumer: consumer })),
  createActionCableConsumer: () => ActionCable.createConsumer(`${actionCableUrl}?token=${get().user.token}`),
  subscribeToUnit: (id) => get().consumer.subscriptions.create(
    {
      channel: 'UnitChannel',
      id: id
    },
    {
      received: (data) => {
        get().fetchUnits()
        if (data.status === 'approved') {
          get().setSuccessMessage('Your floor request has been approved', { text: 'View', url: `/unit/${id}` })
        } else {
          get().setAlertMessage('Your floor request requires attention', { text: 'View', url: `/unit/${id}` })
        }
      }
    }
  ),
  subscribeToNotifications: () => get().consumer.subscriptions.create(
    {
      channel: 'PortalNotificationChannel',
    },
    {
      received: (data) => {
        get().fetchNotifications()
        const attributes = data.data.attributes
        const linkInfo = createNotificationLinks(attributes.metadata)[0]
        const buttonOptions = linkInfo ? { text: `Go to ${linkInfo.name}`, url: linkInfo.link } : null

        switch(attributes.level) {
          case 1:
            get().setSuccessMessage(attributes.title, buttonOptions)
            break
          case 2:
            get().setAlertMessage(attributes.title, buttonOptions)
            break
          case 3:
            get().setErrMessage(attributes.title, buttonOptions)
            break
        }
      }
    }
  ),
  resetActionCableSlice: () => set(initialState)
})

export default createActionCableSlice