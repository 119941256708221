import useStore from "../../store"
import { useState, useEffect } from "react"
import vinOptimizer from '../../utilities/vinOptimizer'
import vinValidator from 'vin-validator'

export default function OCRScanner({ children, webcamRef, cameraLoaded, onScanSuccess }) {
  const [ocrScannerStatus, setOcrScannerStatus] = useState('idle')
  const googleCloudVision = useStore((state) => state.googleCloudVision)

  useEffect(() => {
    console.log(ocrScannerStatus)
    if (!cameraLoaded) return
    if (ocrScannerStatus === 'scanning') return

    setOcrScannerStatus('scanning')
    scan()
  }, [ocrScannerStatus, cameraLoaded])

  const scan = async () => {
    try {
      if (!webcamRef?.current?.getScreenshot) return

      const response = await googleCloudVision(webcamRef.current.getScreenshot())
      if (!response.ok) return

      const data = await response.json()
      if (!data.responses[0].textAnnotations) return

      const results = data.responses[0].textAnnotations.map((result) => vinOptimizer(result.description))
      const vinMatches = results.filter((text) => vinValidator.validate(text))

      if (vinMatches.length === 0) return

      onScanSuccess(vinMatches[0])
    } catch (err) {
      console.log(err)
    } finally {
      setTimeout(() => {
        setOcrScannerStatus('idle')
      }, 500)
    }
  }

  return(
    <div>
      {children}
    </div>
  )
}