import { useTranslation } from "react-i18next"
import useStore from "../../../store"
import { useEffect } from "react"
import MakePaymentBody from "./MakePaymentBody"
import AlertMessage from "../../common/AlertMessage"
import "react-datepicker/dist/react-datepicker.css"


export default function MakePayment() {
  const user = useStore((state) => state.user)
  const unit = useStore((state) => state.unit)
  const fetchAmountDueOn = useStore((state) => state.fetchAmountDueOn)
  const amountDueOn = useStore((state) => state.amountDueOn)
  const fetchBankAccounts = useStore((state) => state.fetchBankAccounts)
  const fetchBankAccountsAsAm = useStore((state) => state.fetchBankAccountsAsAm)
  const resetPaymentSlice = useStore((state) => state.resetPaymentSlice)
  const paymentMessage = useStore((state) => state.paymentMessage)
  const isOnline = useStore((state) => state.isOnline)
  const unitId = unit.id
  const { t } = useTranslation()

  const isUnitAndDateMatch = () => {
    // Checks if unit id matches the unit id in amountDueOn and the date in amountDueOn is today
    return(
      amountDueOn.id === Number(unitId) &&
      new Date(amountDueOn['amounts_due_on_date']).setHours(0,0,0,0) === new Date().setHours(0,0,0,0)
    )
  }

  useEffect(() => {
    if(user.type === 'AccountManager') {
      fetchBankAccountsAsAm(unit.attributes['dealer-id'])
    } else {
      fetchBankAccounts()
    }

    // Only fetches amountDueOn if the unit and date don't match amountDueOn in state
    if (!isUnitAndDateMatch()) {
      fetchAmountDueOn(unitId, new Date().toLocaleDateString('en-ZA'))
    }

    return () => {
      resetPaymentSlice()
    }
  }, [unit])

  const paymentBody = () => {
    if (unit.attributes['ach-initiated']) {
      return(
        <AlertMessage message={t("A transfer is already pending for this unit. Thank you.")} />
      )
    } else if (!isOnline) {
      return (
        <AlertMessage message={t("Payments are unavailable while offline.")} />
      )
    } else {
      return(
        <MakePaymentBody />
      )
    }
  }

  return(
    <div className="flex flex-col w-full text-left bg-grey-900 rounded mt-2">
      <h3 className="font-header font-semibold text-md text-green m-2">{t('Make A Payment')}</h3>
      {paymentBody()}
      {paymentMessage &&
        <AlertMessage message={paymentMessage} />
      }
    </div>
  )
}