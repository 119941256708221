import AuditsTable from "./AuditsTable"

export default function AuditsPage () {
  return(
    <div className="flex grow justify-center w-full overflow-y-auto transition-all duration-300 bg-grey-900 rounded-t">
      <div className="items-center overflow-y-auto py-2 w-full lg:pl-2">
        <div className="w-full text-left p-2 bg-grey-900 rounded-t">
          <h3 className="font-header font-semibold text-md text-green">{'Unreconciled Field Audits'}</h3>
        </div>
        <AuditsTable/>
      </div>
    </div>
  )
}