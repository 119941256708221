import useStore from "../../../store"
import DealerRow from "../Dealers/DealerRow"
import DealersTableHeader from "../Dealers/DealersTableHeader"
import AmSearchBar from "../../common/AmSearchBar"
import { useDisclosure } from "@chakra-ui/react"
import FilterIndicators from "../Dealers/DealerTableFiltering/FilterIndicators"
import FilterByDrawer from "../../common/FilterByDrawer"
import FilterOptions from "../Dealers/DealerTableFiltering/FilterOptions"
import FilterButtons from "../Dealers/DealerTableFiltering/FilterButtons"
import NoDealersFound from "../Dealers/NoDealersFound"

export default function NearbyDealers() {
  const dealer = useStore((state) => state.dealer)
  const nearbyDealers = useStore((state) => state.nearbyDealers)
  const setNearbyDealerFilterAttribute = useStore((state) => state.setNearbyDealerFilterAttribute)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const nearbyDealersFetching = useStore((state) => state.nearbyDealersFetching)
  const nearbyDealerResultsCount = useStore((state) => state.nearbyDealerPaginationData.dealerResultsCount)
  const nearbyDealerTableFilterParams = useStore((state) => state.nearbyDealerTableFilterParams)
  const setNearbyDealerTableFilterParams = useStore((state) => state.setNearbyDealerTableFilterParams)
  const fetchNearbyDealers = useStore((state) => state.fetchNearbyDealers)
  const setNearbyDealersSearchBarValue = useStore((state) => state.setNearbyDealersSearchBarValue)
  const nearbyDealerFilterAttribute = useStore((state) => state.nearbyDealerFilterAttribute)
  const nearbyDealersSearchBarValue = useStore((state) => state.nearbyDealersSearchBarValue)

  const handleResetFilter = () => {
    setNearbyDealerTableFilterParams('')
    fetchNearbyDealers(dealer?.attributes.lat, dealer?.attributes.lng, '100', '')
    setNearbyDealersSearchBarValue('')
    onClose()
    setNearbyDealerFilterAttribute(`default`)
  }

  const handleSearch = (value) => {
    setNearbyDealerFilterAttribute(`search=${value}`)
  }

  const getSelectedUtilFilterName = (params) => {
    const min = params['utilization[min]']
    const max = params['utilization[max]']

    if (min === undefined || max === undefined) {
      return 'No filter selected'
    } else if (max === '1000') {
      return 'Over Credit Limit'
    }

    return `Util ${min}% - ${max}%`
  }

  const selectedUtilFilterName = getSelectedUtilFilterName(nearbyDealerTableFilterParams)

  return(
    <div className="flex flex-col items-center sm:mt-0 w-full bg-grey-900">
      <div className="w-full pr-2 pl-1">
        <AmSearchBar
          handleSearch={handleSearch}
          handleReset={handleResetFilter}
          onOpen={onOpen}
          showFilterIcon={true}
          dataFetching={nearbyDealersFetching}
          searchBarValue={nearbyDealersSearchBarValue}
          setSearchBarValue={setNearbyDealersSearchBarValue}
        />
        {nearbyDealerTableFilterParams &&
          <FilterIndicators
            dealerTableFilterParams={nearbyDealerTableFilterParams}
            selectedUtilFilterName={selectedUtilFilterName}
            setDealerTableFilterParams={setNearbyDealerTableFilterParams}
            onClose={onClose}
          />
        }
      </div>
      <table className="table-fixed w-full">
        <DealersTableHeader isNearbyDealersRow={true} style={"top-[44px]"} />
        <tbody>
          {nearbyDealers.map((dealer) => (
            <DealerRow dealer={dealer} key={dealer.id} filter={nearbyDealersSearchBarValue} metrics={true} isNearbyDealersRow={true}/>
          ))}
        </tbody>
      </table>
      {!nearbyDealersFetching && nearbyDealers.length === 0 && <NoDealersFound />}
      <FilterByDrawer
        isOpen={isOpen}
        onClose={onClose}
        title="Filter Dealers"
        bodyContent={
          <>
            <FilterOptions
              setFilterParams={setNearbyDealerTableFilterParams}
              filterParams={nearbyDealerTableFilterParams}
            />
          </>
        }
        footerContent={
          <FilterButtons
            handleResetFilter={handleResetFilter}
            handleSubmit={() => onClose()}
            resultsCount={nearbyDealerResultsCount}
            resultsFetching={nearbyDealersFetching}
          />
        }
      />
    </div>
  )
}