import { FaChevronRight, FaChevronLeft } from "react-icons/fa"
import { useIsMobile } from "../../hooks/useIsMobile"

export default function ClickableRow({ text, onClick, isSelected = false, children }) {
  const isMobile = useIsMobile()

  return(
    <div
      className={`flex justify-between w-full py-2 px-2 lg:hover:bg-grey-700 hover:cursor-pointer
      ${isSelected && 'bg-grey-700'} text-grey-300 text-left items-center border-b-2 border-grey-700`}
      onClick={onClick}
      data-testid="clickable-row"
    >
      {children ?
        children :
        <div className="font-bold">
          <div className={'text-grey-100'}>
            {text}
          </div>
        </div>
      }
      <div className="flex items-center text-sm">
        {(!isSelected || isMobile) ?
          <FaChevronRight className="text-grey-300" />
        :
          <FaChevronLeft className="text-grey-300" />
        }
      </div>
    </div>
  )
}