import useStore from "../../../store"
import Spinner from "../../common/Spinner"
import ProspectDealerBody from "./ProspectDealerBody"

export default function MobileProspectiveDealerPage() {
  const dealer = useStore((state) => state.dealer)

  return (
    <div className="flex grow overflow-y-auto w-full">
      {dealer ?
        <ProspectDealerBody/>
      :
        <Spinner/>
      }
    </div>
  )
}