import { useState, useCallback } from "react"
import useStore from "../../store"
import rtnValidator from '../../utilities/rtnValidator'
import { HappyButton } from "../common/Buttons"
import accountTypes from "../../utilities/accountTypes"
import SelectDropdown from "../common/SelectDropdown"
import Spinner from "../common/Spinner"
import FormInput from "../common/FormInput"

export default function BankAccountForm({ setShowAccountForm }) {
  const dealer = useStore((state) => state.dealer)
  const setErrMessage = useStore((state) => state.setErrMessage)
  const setSuccessMessage = useStore((state) => state.setSuccessMessage)
  const createBankAccount = useStore((state) => state.createBankAccount)
  const fetchBankAccounts = useStore((state) => state.fetchBankAccounts)
  const [formValues, setFormValues] = useState({
    bank_name: '',
    name_business: dealer?.attributes.name,
    dealer_nick_name: '',
    account_number: '',
    routing_number: '',
    account_type: null
  })
  const [invalidValues, setInvalidValues] = useState([])
  const [accountNumberConfirmation, setAccountNumberConfirmation] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const emptyValues = useCallback(() => {
    return Object.keys(formValues).filter((key) => formValues[key] === '' || formValues[key] === undefined || formValues[key] === null)
  }, [formValues])

  const handleSubmit = async () => {
    if (emptyValues().length > 0) {
      setInvalidValues(emptyValues())
      setErrMessage('Required fields were left blank')
      return
    }

    if (accountNumberConfirmation !== formValues.account_number) {
      setInvalidValues('account_number')
      setErrMessage('Account numbers do not match')
      return
    }

    if (!rtnValidator(formValues.routing_number)) {
      setInvalidValues(['routing_number'])
      setErrMessage('Routing number is invalid')
      return
    }

    try {
      setIsSubmitting(true)
      const response = await createBankAccount({ data: { attributes: formValues }})
      if (response.ok) {
        fetchBankAccounts()
        setSuccessMessage('Bank account was successfully added')
      } else if (response.status === 422) {
        setErrMessage('Bank account could not be processed as entered. Please check account information.')
      } else {
        setErrMessage('Request could not be processed at this time.')
      }
    } catch (err) {
      setErrMessage('Request could not be processed at this time.')
    } finally {
      setShowAccountForm(false)
      setIsSubmitting(false)
    }
  }

  return(
    <div>
      <div onClick={() => setInvalidValues([])}>
        <SelectDropdown
          options={accountTypes}
          defaultText='Select an account type'
          selectedItem={accountTypes.find((acct) => acct.id === formValues.account_type) || null}
          setSelectedItem={(accountType) => setFormValues({ ...formValues, account_type: accountType.id })}
          displaySelectedItem={(accountType) => accountType.name}
          label='Account Type'
          isErrored={invalidValues.includes('account_type')}
        />
      </div>
      <form className="flex flex-col w-72 mt-2">
        <div
          onClick={() => setInvalidValues([])}
          className="flex flex-col w-full"
          data-testid='bank-account-form'
        >
          <FormInput
            title="Bank Name"
            value={formValues.bank_name}
            onChange={(e) => setFormValues({ ...formValues, bank_name: e.target.value })}
            isErrored={invalidValues.includes('bank_name')}
            styles="bg-grey-900"
          />
          <FormInput
            title="Business Name"
            value={formValues.name_business}
            onChange={(e) => setFormValues({ ...formValues, name_business: e.target.value })}
            isErrored={invalidValues.includes('name_business')}
            styles="bg-grey-900"
          />
          <FormInput
            title="Account Nickname"
            value={formValues.dealer_nick_name}
            onChange={(e) => setFormValues({ ...formValues, dealer_nick_name: e.target.value })}
            isErrored={invalidValues.includes('dealer_nick_name')}
            styles="bg-grey-900"
          />
          <FormInput
            title="Account Number"
            value={formValues.account_number}
            onChange={(e) => setFormValues({ ...formValues, account_number: e.target.value })}
            isErrored={invalidValues.includes('account_number')}
            styles="bg-grey-900"
          />
          <FormInput
            title="Confirm Account Number"
            value={accountNumberConfirmation}
            onChange={(e) => setAccountNumberConfirmation(e.target.value)}
            isErrored={invalidValues.includes('account_number')}
            styles="bg-grey-900"
          />
          <FormInput
            title="Routing Number"
            value={formValues.routing_number}
            onChange={(e) => setFormValues({ ...formValues, routing_number: e.target.value })}
            isErrored={invalidValues.includes('routing_number')}
            styles="bg-grey-900"
          />
        </div>
        <HappyButton text={isSubmitting ? <Spinner color="green" /> : "Add Account"} onClick={handleSubmit} style="my-2" />
      </form>
    </div>
  )
}