import { HappyPill, WarningPill } from "../common/PayoffRefloorPills"
import { useTranslation } from 'react-i18next'

export default function BankAccountStatus({ account }) {
  const { t } = useTranslation()

  if (account.attributes['needs-ach-auth']) {
    return(
      <WarningPill text={t('Needs Auth')} />
    )
  } else if (account.attributes['default-account']) {
    return(
      <HappyPill text={t('Default')} />
    )
  } else {
    return null
  }
}