import useStore from '../../../store'
import unitStatus from "../../../utilities/unitStatus"
import HighlightMatch from "../../../utilities/HighlightMatch"

export default function UnitStatus({ unit, matchedAttribute, filter }) {
  const user = useStore((state) => state.user)
  const status = unitStatus(unit, matchedAttribute, user?.type)
  const unitRowStyle = useStore((state) => state.unitRowStyle)

  return(
    <div className={`text-xs ${unitRowStyle !== 'lg' && 'text-right'} ${status.style}`}><HighlightMatch str={status.text} match={filter} /> </div>
  )
}
