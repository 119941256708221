import tailwind from '../../../tailwind.config'
import { RiErrorWarningFill } from "react-icons/ri"

export default function InfoItem({title, data = null, subdata = null, editableTitle, children = null, alert=null}) {
  const yellow = tailwind.theme.colors["yellow"]

  return (
    <div className="w-full p-1">
      <p className={`text-sm ${editableTitle ? 'text-grey-100' : 'text-grey-300'}`}>{title}</p>
      <div className="w-full flex items-center">
        {data &&
          <p className={`w-full text-md text-grey-100 flex items-center gap-2`}>
            {alert && <RiErrorWarningFill color={yellow}/>}
            {data || ''}
          </p>
        }
      </div>
      {children && children}
      {subdata && <p className="text-md text-grey-100">{subdata !== null ? subdata : ''}</p>}
    </div>
  )
}