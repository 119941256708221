import useStore from "../../../store"
import DealerRow from "./DealerRow"
import Pagination from "../../common/Pagination"

export default function DealersTableBody({ searchTerm }) {
  const dealers = useStore((state) => state.dealers)
  const dealersFetching = useStore((state) => state.dealersFetching)
  const { currentPage, lastPage, dealerResultsCount, totalPages } = useStore((state) => state.dealerPaginationData)
  const setDealersPage = useStore((state) => state.setDealersPage)

  const handlePageClick = (event) => {
    setDealersPage(event.selected + 1)
  }

  if (dealers.length > 0 ) {
    return (
      <tbody>
        {dealers.map((dealer) => (
          <DealerRow dealer={dealer} key={dealer.id} filter={searchTerm} metrics={true}/>
        ))}
        <td colSpan="100%">
          <div className="flex justify-center">
            <Pagination
              currentPage={currentPage}
              lastPage={lastPage}
              dataTotal={dealerResultsCount}
              handlePageClick={handlePageClick}
              totalPages={totalPages}
            />
          </div>
        </td>
      </tbody>
    )
  }

  return null
}