import InfoItem from "../../common/InfoItem"
import { DateTime } from "luxon"

export default function NoteDetails({ note }) {
  const date = DateTime.fromISO(note.attributes['created-at'])

  return(
    <div>
        <div className="w-full text-left bg-grey-900 rounded-t">
          <h3 className="font-header font-semibold text-md text-green">{`Note by ${note.attributes['created-by']} - ${date.toLocaleString()}`}</h3>
        </div>
        <div className="ml-1">
          <InfoItem title="Subject">
            {note.attributes.subject}
          </InfoItem>
          <InfoItem title="Note">
            {note.attributes.note}
          </InfoItem>
        </div>
    </div>
  )
}