export const typeEnum = Object.freeze({
  1: 'Phone Call',
  2: 'FPX Office',
  3: 'Dealer Lot Visit',
  4: 'Auction Meeting',
  5: 'Fax',
  6: 'Email',
  7: 'Mail',
  8: 'Thank You Card',
  10: 'Meal / Coffee',
  999: 'Updating Dealer',
})

export const purposeEnum = Object.freeze({
  1: 'Cold Call',
  2: 'First Meeting',
  3: 'Application',
  4: 'Collect Stips',
  5: 'Application Follow Up',
  6: 'Contract Signing',
  7: 'Utilization',
  8: 'Field Audit',
  9: 'General Visit',
  10: 'Hard Conversation',
  11: 'Repossession',
  12: 'Office Call (Not Sales or Collections)',
  13: 'New Business Development',
  999: 'Updating Dealer',
})

export const responseEnum = Object.freeze({
  1: 'Not Interested - Own Money',
  2: 'Not Interested - Other Floor Plan',
  3: 'Interested',
  4: 'Filled out Application',
  5: 'Provided Stips',
  6: 'Provided Reference',
  7: 'Requested Meeting',
  8: 'Emailed',
  9: 'Faxed',
  10: 'Left Message',
  11: 'Mailed Application',
  12: 'Duplicate Dealer',
  20: 'Talked about service, no specific response',
  50: 'No Answer',
  51: 'No English',
  52: 'Wrong Number',
  53: 'No One At Lot',
  54: 'Dealer Principal Not at Lot',
  60: 'Bad Address',
  61: 'Not a Used Car Dealer',
  62: 'Out of Business',
  63: 'Outside of Branch Area',
  64: 'Not a Dealer We Want To Do Business With',
  99: 'Other'
})