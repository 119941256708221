import useStore from "../../../../store"
import InfoItem from "../../../common/InfoItem"
import formatter from "../../../../constants/currencyFormatter"

export default function OtherFloorplans() {
  const otherFloorplans = useStore((state) => state.otherFloorplans)

  return(
    <div>
      <div className="w-full text-left bg-grey-900 rounded-t">
        <h3 className="font-header font-semibold text-md text-green">{'Other Floorplans'}</h3>
      </div>
      <div className="grid grid-cols-1  w-full text-left p-2 bg-grey-900 rounded" data-testid="dealership-other-floorplans">
        {otherFloorplans.length > 0 && otherFloorplans.map((floorplan) => (
          <div>
            <InfoItem title={floorplan.attributes.company}>
              {formatter.format(floorplan.attributes.line)}
            </InfoItem>
          </div>
        ))}
      </div>
    </div>
  )
}