import { Link, useLocation } from 'react-router-dom'

export default function MobileNavItem({ path, icon }) {
  const selected = useLocation().pathname == path

  return(
    <Link 
      to={path}
      className={`${selected ? 'text-grey-100' : 'text-grey'} font-bold py-1.5 px-6 rounded`}
    >
      {icon}
    </Link>
  )
}