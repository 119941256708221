import { useEffect } from 'react'
import useStore from '../../../store'
import { useParams, useLocation } from 'react-router-dom'
import { useIsMobile } from '../../../hooks/useIsMobile'
import DealerInfo from './DealerInfo'
import DealerNameHeader from './DealerNameHeader'
import Spinner from '../../common/Spinner'
import DashboardContainer from '../../common/DashboardContainer'
import AmDealerDashboardSidePanel from './AmDealerDashboardSidePanel'
import UnitsNeedingAttention from './UnitsNeedingAttention'
import WarningMessage from '../../common/WarningMessage'
import { isReviewDue } from '../../../utilities/unitUtilities'
import Breadcrumbs from '../../common/Breadcrumbs'
import SaveDealerIcon from '../Dealers/SavedDealers/SaveDealerIcon'


export default function AmDealerDashboard() {
  const isMobile = useIsMobile()
  const { dealerId } = useParams()
  const dealer = useStore((state) => state.dealer)
  const fetchUnitsAsAm = useStore((state) => state.fetchUnitsAsAm)
  const dealerUnitsPage = useStore((state) => state.dealerUnitsPage)

  const fetchDealer = useStore((state) => state.fetchDealer)
  const fetchBankAccountsAsAm = useStore((state) => state.fetchBankAccountsAsAm)
  const amUnitsTableParams = useStore((state) => state.amUnitsTableParams)
  const lastReviewDate = dealer?.attributes["last-review-date"]
  const unitsNeedingAttention = Object.values({
    untouched: dealer?.attributes['untouched-unit-count'],
    sold: dealer?.attributes['sold-unit-count'],
    repo: dealer?.attributes['repo-unit-count'],
    pastDue: dealer?.attributes['past-due-unit-count'],
  }).some(count => count > 0)

  useEffect(() => {
    fetchDealer(dealerId)
    fetchBankAccountsAsAm(dealerId)
  }, [dealerId])

  useEffect(() => {
    fetchUnitsAsAm(dealerId, amUnitsTableParams)
  }, [amUnitsTableParams, dealerId, dealerUnitsPage])

  const sidePanels = [
    {
      isExpanded: true,
      component: <AmDealerDashboardSidePanel dealerId={dealerId} />,
      isFullscreenable: false,
      isClosable: false,
    }
  ]

  return (
    <DashboardContainer sidePanels={sidePanels}>
      <Breadcrumbs/>
      {dealer?.id !== dealerId ?
        <div className="flex w-full justify-center">
          <Spinner />
        </div>
      :
        <>
          <div className="relative w-full flex items-center justify-center">
            <div className="absolute left-0 ml-2">
              <SaveDealerIcon dealer={dealer} />
            </div>
            <div className="text-center">
              <DealerNameHeader
                name={dealer?.attributes.name ? dealer.attributes.name : 'No Name'}
              />
            </div>
          </div>
            {isReviewDue(lastReviewDate) &&
              <div className='pb-2'>
                <WarningMessage text={"Dealer Review Due"} />
              </div>
            }
            {unitsNeedingAttention && <UnitsNeedingAttention/>}
        </>

      }
      {isMobile ? <AmDealerDashboardSidePanel dealerId={dealerId} /> : <DealerInfo dealerId={dealerId}/>}
    </DashboardContainer>
  )
}
