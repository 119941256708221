import { init } from "rollbar"
import { apiUrl, apiUrlV2 } from "../constants/apiUrl"

const initialState = {
  dealerFiles: [],
  dealerFilesFetching: false,
  dealerFile: null,
  dealerFileFetching: false,
  dealerFolders: [],
  lotPhotos: []
}

const createDealerDocumentsSlice = (set, get) => ({
  ...initialState,
  fetchDealerDocuments: async (dealerId) => {
    set((state) => ({ dealerFilesFetching: true}))

    try {
      const response = await fetch(
        `${apiUrlV2}/dealers/${dealerId}/dealer_files`,
        {headers: get().requestHeaders}
      )
      if (response.status === 200) {
        const data = await response.json()

        if(get().user) {
          set((state) => ({ dealerFiles: data.data, dealerFilesFetching: false }))
        }
      }
    } catch (err) {
      get().setErrMessage('Data was unable to be fetched. Please refresh to try again')
    }
  },
  createDealerFile: (body, dealerId) => fetch(
    `${apiUrlV2}/dealers/${dealerId}/dealer_files`, {
      method: 'POST',
      headers: { 'Authorization': get().requestHeaders['Authorization']},
      body: body
    }
  ),
  fetchDealerFile: async (fileId, dealerId) => {
    set((state) => ({ dealerFileFetching: true}))

    try {
      const response = await fetch(
        `${apiUrlV2}/dealers/${dealerId}/dealer_files/${fileId}`, {
          headers: get().requestHeaders}
      )
      if (response.status === 200) {
        const data = await response.json()
        set((state) => ({ dealerFile: data.data, dealerFileFetching: false }))
      }
    } catch (err) {
      get().setErrMessage('Unable to fetch data. Please try again')
    }
  },
  updateDealerFile: async (fileId, dealerId, attributes) => {
    try {
      const response = await fetch(
        `${apiUrlV2}/dealers/${dealerId}/dealer_files/${fileId}`,
        {
          method: 'PATCH',
          headers: get().requestHeaders,
          body: JSON.stringify({
            dealer_file: attributes
          })
        }
      )
      if (response.status === 200) {
        const data = await response.json()
        set((state) => ({ dealerFile: data.data }))
      }
    } catch (err) {
      get().setErrMessage('Unable to update file. Please try again')
    }
  },
  deleteDealerFile: (fileId, dealerId) => fetch(
    `${apiUrlV2}/dealers/${dealerId}/dealer_files/${fileId}`, {
      method: 'DELETE',
      headers: { 'Authorization': get().requestHeaders['Authorization']},
    }
  ),
  fetchFolderList: async (dealerId) => {
    try {
      const response = await fetch(
        `${apiUrlV2}/dealers/${dealerId}/dealer_folders`,
        {headers: get().requestHeaders}
      )
      if (response.status === 200) {
        const data = await response.json()
        set((state) => ({ dealerFolders: data.data }))
      }
    } catch (err) {
      get().setErrMessage('Unable to update file. Please try again')
    }
  },
  fetchLotPhotos: async (dealerId) => {
    try {
      const response = await fetch(
        `${apiUrlV2}/dealers/${dealerId}/dealer_files?scope=lot_photos`,
        {headers: get().requestHeaders}
      )
      if (response.status === 200) {
        const data = await response.json()
        set((state) => ({ lotPhotos: data.data }))
      }
    } catch (err) {
      get().setErrMessage('Unable to update file. Please try again')
    }
  },
  resetDealerDocumentsSlice: () => set(initialState)
})

export default createDealerDocumentsSlice