import useStore from "../../../store"
import { useTranslation } from "react-i18next"
import Camera from "../../common/Camera"

export default function NAPCamera({ close, title }) {
  const { t }= useTranslation()
  const setNAPImages = useStore((state) => state.setNAPImages)
  const NAPImages = useStore((state) => state.NAPImages)

  const handleCapture = (image) => {
    setNAPImages({ ...NAPImages, [title]: image })
    close()
  }

  return(
    <Camera
      close={close}
      capture={handleCapture}
      instructions={t('nap.cameraInstructions', { pictureTitle: title })}
    />
  )
}