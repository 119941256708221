import useStore from '../../../store'
import UnitsMetric from './UnitsMetric'

export default function Today() {
  const dailyOps = useStore((state) => state.dailyOps)
  const user = useStore((state) => state.user)

  const color = (stat) => {
    if (stat > 0) {
      return 'text-white'
    } else {
      return 'text-fpx-red'
    }
  }

  return (
    <div className="w-full py-2 px-2 bg-grey-900 rounded-t">
      <h3 className="text-green font-semibold font-header">
        {`Welcome, ${user.userFirstName}`}
      </h3>
        <div className="flex w-full justify-evenly">
          <UnitsMetric
            metricColor={color(dailyOps.todays_floored_count)}
            text={"Units Floored Today"}
            metric={dailyOps.todays_floored_count}
          />
          <UnitsMetric
            metricColor={color(dailyOps.payoff_count)}
            text={"Units Paid-Off Today"}
            metric={dailyOps.payoff_count}
          />
        </div>
    </div>
  )
}