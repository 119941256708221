import useStore from "../../../../store"
import InfoItem from "../../../common/InfoItem"
import { DateTime } from "luxon"

export default function SecretaryOfStateFilings() {
  const application = useStore((state) => state.application)

  return(
    <div>
    <div className="w-full text-left bg-grey-900 rounded-t">
      <h3 className="font-header font-semibold text-md text-green">{'Secretary Of State Filings'}</h3>
    </div>
    <div className="grid grid-cols-1 w-full text-left p-2 bg-grey-900 rounded" data-testid="dealership-uccs">
      <InfoItem title={'Comments'}>
        {application.attributes["sos-notes"]}
      </InfoItem>
      <InfoItem title={'Filed with State'}>
        {application.attributes["sos-added"] !==null ? DateTime.fromISO(application.attributes["sos-added"]).toLocaleString() : 'N/A'}
      </InfoItem>
      <InfoItem title={'Added By'}>
        {application.attributes["sos-by"]}
      </InfoItem>
    </div>
    </div>
  )
}