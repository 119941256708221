export default function mobileBusinessDevCircleColor({number, goal, apps_pending=null, prospects=null}) {
  if(prospects) {
    if(number < 100) {
      return 'border-fpx-red text-fpx'
    } else if(number >= 100 && number < goal) {
      return 'border-yellow text-yellow'
    } else {
      return 'border-green text-green'
    }
  } else if(apps_pending) {
    if(number < goal) {
      return 'border-green text-green'
    } else if(number > goal && number < goal * 2) {
      return 'border-yellow text-yellow'
    } else {
      return 'border-fpx-red text-fpx-red'
    }
  } else if(number >= goal) {
    return 'border-green text-green'
  } else {
    return 'border-fpx-red text-fpx-red'
  }
}

