import formatter from "../constants/currencyFormatter"

const transferFilter = (transfers, filter) => {
  return(
    transfers.filter((transfer) =>
    `${transfer.attributes.make} ${transfer.attributes.model}`.toLowerCase().includes(filter.toLowerCase()) ||
      formatter.format(transfer.attributes.amount).includes(filter) ||
      transfer.attributes['payment-type'].toLowerCase().includes(filter.toLowerCase()) ||
      transfer.attributes.vin.toLowerCase().includes(filter.toLowerCase()) ||
      new Date(transfer.attributes['actual-transfer-date'])
        .toLocaleDateString('en-US', { year: '2-digit', month: 'numeric', day: 'numeric'})
        .includes(filter.toLowerCase())
    )
  )
}

export default transferFilter