export function isAlreadyFloored(blackbook, units) { 
  return(
    units.find((unit) => unit.attributes.vin === blackbook.vin)
  )
}

export function isNAPInProgress(blackbook, NAPBlackbook) { 
  return(
    NAPBlackbook?.vin === blackbook.vin 
  )
}