import {
  Divider,
  Stack,
  Checkbox,
  Box,
} from "@chakra-ui/react"
import useStore from "../../../../store"
import tailwind from "../../../../../tailwind.config"
import FilterButtons from "../../Dealers/DealerTableFiltering/FilterButtons"
import FilterByDrawer from "../../../common/FilterByDrawer"

export default function UnitsFilterByDrawer({onClose, isOpen}) {
  const setAmUnitsTableParams = useStore((state) => state.setAmUnitsTableParams)
  const amUnitsTableParams = useStore((state) => state.amUnitsTableParams)
  const unitsFetching = useStore((state) => state.unitsFetching)
  const dealerUnitsResultsCount = useStore((state) => state.dealerUnitsPaginationData.dealerUnitsResultsCount)
  const setSelectedDealerUnitsScopeLabel = useStore((state) => state.setSelectedDealerUnitsScopeLabel)

  const handleResetFilter = () => {
    setAmUnitsTableParams('')
    onClose()
  }

  const handleFilterByScope = (scope, label) => {
    const currentScope = amUnitsTableParams['filter[scope]']

    if (currentScope === scope) {
      setAmUnitsTableParams('')
    } else {
      setSelectedDealerUnitsScopeLabel(label)
      setAmUnitsTableParams({
        [`filter[scope]`]: scope
      })
    }
  }

  return(
    <FilterByDrawer
    isOpen={isOpen}
    onClose={onClose}
    title="Filter Units"
    bodyContent={
        <Box p={4}>
          <Divider my={4} />
          <Stack>
            <Checkbox
              isChecked={amUnitsTableParams['filter[scope]'] === "untouched_40_plus_days"}
              onChange={() => handleFilterByScope("untouched_40_plus_days", "Untouched 40+ Days")}
            >
              Units Untouched 40+ Days
            </Checkbox>
            <Checkbox
              isChecked={amUnitsTableParams['filter[scope]'] === "past_due"}
              onChange={() => handleFilterByScope("past_due", "Past Due")}
            >
              Past Due Units
            </Checkbox>
            <Checkbox
              isChecked={amUnitsTableParams['filter[scope]'] === "repo"}
              onChange={() => handleFilterByScope("repo", "Repo Units")}
            >
              Repo Units
            </Checkbox>
            <Checkbox
              isChecked={amUnitsTableParams['filter[scope]'] === "sold"}
              onChange={() => handleFilterByScope("sold", "Sold Units")}
            >
              Sold Units
            </Checkbox>
          </Stack>
        </Box>
    }
    footerContent={
      <FilterButtons
        handleResetFilter={handleResetFilter}
        handleSubmit={() => onClose()}
        resultsCount={dealerUnitsResultsCount}
        resultsFetching={unitsFetching}
      />
    }
  />
  )
}