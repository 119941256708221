import { apiUrl } from "../constants/apiUrl"

const initialState = {
  localBranch: null,
  corporateBranch: null,
}

const createBranchSlice = (set, get) => ({
  ...initialState,
  fetchLocalBranch: async () => {
    try {
      const response = await fetch(
        `${apiUrl}/branches/?no_includes=true&id=mine`, 
        { headers: get().requestHeaders}
      )
      const data = await response.json()
      set((state) => ({ localBranch: data.data[0] }))
    } catch (err) {
      get().setErrMessage('There was a problem fetching branch information. Please try  again')
    }
  },
  fetchCorporateBranch: async () => {
    try {
      const response = await fetch(
        `${apiUrl}/branches/?no_includes=true&filter[scope]=corporate`, 
        { headers: get().requestHeaders}
      )
      const data = await response.json()
      set((state) => ({ corporateBranch: data.data[0] }))
    } catch (err) {
      get().setErrMessage('There was a problem fetching branch information. Please try  again')
    }
  },
  resetBranchSlice: () => set(initialState)
})

export default createBranchSlice