import { useTranslation } from "react-i18next"
import InfoItem from "../../common/InfoItem"

export default function BranchAddress({ branch }) {
  const { t } = useTranslation()
  const address1 = branch.attributes.address1

  if (address1) {
    return(
      <div className="my-2">
        <InfoItem
          title={t('Address')}
          data={`${branch.attributes.address1} ${branch.attributes.address2}`}
          subdata={`${branch.attributes.city}, ${branch.attributes.state} ${branch.attributes.zip}`}
        />
      </div>
    )
  }

  return null
}