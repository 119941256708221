const napEnum = {
  0: 'Floor Amount',
  1: 'Title Front',
  2: 'Title Back',
  3: 'Bill of Sale',
  4: 'Dash VIN',
  5: 'Driver Side Front',
  6: 'Odometer',
  7: 'Passenger Side Rear',
  8: 'Dealer Reassignment',
  9: 'Lien Release',
  10: 'Payoff Authorization',
  11: 'VIN Plate',
  12: 'Interior',
  13: 'Damage',
  14: 'Engine Bay',
  15: 'Dash Panel',
  16: 'Other'
}

export default napEnum