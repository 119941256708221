const resetAllSlices = (set, get) => ({
  resetAllSlices: () => {
    get().resetActionCableSlice()
    get().resetAuthSlice()
    get().resetBankAccountSlice()
    get().resetBlackbookSlice()
    get().resetBranchSlice()
    get().resetCometChatSlice()
    get().resetDailyOpsSlice()
    get().resetDealerSlice()
    get().resetDealersSlice()
    get().resetNAPSlice()
    get().resetPaymentSlice()
    get().resetRiskMitigationSlice()
    get().resetTransferSlice()
    get().resetUnitsSlice()
    get().resetRemindersSlice()
    get().resetBusinessDevSlice()
    get().resetApplicationsSlice()
    get().resetNotesSlice()
    get().resetPeopleSlice()
    get().resetNotesSlice()
    get().resetNotificationsSlice()
    get().resetDealerDocumentsSlice()
    get().resetTouchesSlice()
    get().resetInAppMessagingSlice()
    get().resetCouponsSlice()
  },
})

export default resetAllSlices