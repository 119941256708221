import useStore from "../../store"
import { useState } from "react"
import registerBiometric from "../../utilities/registerBiometric"
import ActionConfirm from "../common/ActionConfirm"

export default function RegisterBiometric() {
  const [message, setMessage] = useState('')
  const [regStatus, setRegStatus] = useState('Pending')
  const setAskRegisterBiometric = useStore((state) => state.setAskRegisterBiometric)
  const setWebauthnId = useStore((state) => state.setWebauthnId)
  const requestHeaders = useStore((state) => state.requestHeaders)
  const setSuccessMessage = useStore((state) => state.setSuccessMessage)

  const register = async () => {
    const biometric = await registerBiometric(requestHeaders)
    setWebauthnId(biometric.webauthnId)

    if (biometric.status === 'Successful') {
      setSuccessMessage(biometric.message)
      setRegStatus(biometric.status)
      cancel()
    } else {
      setMessage(biometric.message)
      setRegStatus(biometric.status)
    }
  }

  const cancel = () => setAskRegisterBiometric(false)

  if (regStatus === 'Failed') {
    return <ActionConfirm 
      body={message} 
      onCancel={cancel} 
      onConfirm={register} 
      confirmText='Try Again' 
    />
  } else {
    return <ActionConfirm 
      body={'Would you like to enable device authentication?'} 
      onConfirm={register} 
      confirmText='Enable' 
      onCancel={cancel} 
    /> 
  }
}