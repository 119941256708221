import FormsPlugin from "@tailwindcss/forms"

/** @type {import('tailwindcss').Config} */
export default {
  content: [
    "./index.html",
    "./src/**/*.{js,ts,jsx,tsx}",
  ],
  theme: {
    extend: {
      screens: {
        'lg': '1025px'
      }
    },
    fontFamily: {
      // 'body': ['roboto', 'sans-serif'],
      'sans': ['roboto', 'sans-serif'],
      'header': ['Trebuchet MS', 'sans-serif'],
    },
    colors: {
      'fpx-red': '#B10838',
      'yellow': '#F7B500',
      'opaque-yellow': 'rgba(247, 181, 0, 0.4)',
      'green': '#2dc937',
      'white': '#FFFFFF',
      'black': '#000000',
      'grey-100': '#F8F9FA',
      'grey-300': '#BFBFBF',
      'grey': '#4A4A4A',
      'grey-700': '#333333',
      'grey-900': '#101010',
      'deep-ocean': '#2D3748',
    }
  },
  plugins: [FormsPlugin],
}

