import { FaPlus, FaCarAlt } from "react-icons/fa"

export default function FloorCarIcon({ size = 22, styles }) {

  return(
    <div className={`relative ${styles}`}>
      <div
        className="absolute -top-0.5 -right-0.5 flex justify-center items-center bg-black rounded-full"
      >
        <FaPlus 
          size={10}
        />
      </div>

      <FaCarAlt size={size} />
    </div>
  )
}
