import useStore from "../../store"
import {getMessaging, getToken} from "firebase/messaging";
import firebaseApp from "../../configs/firebase";

export default function PushNotificationToggleSwitch() {
  const togglePushNotifications = useStore((state) => state.togglePushNotifications)
  const createPushNotificationDevice = useStore((state) => state.createPushNotificationDevice)
  const pushNotificationEnabled = useStore((state) => state.pushNotificationEnabled)
  const pushNotificationToken = useStore((state) => state.pushNotificationToken)
  const setPushNotificationToken = useStore((state) => state.setPushNotificationToken)
  const setErrMessage = useStore((state) => state.setErrMessage)

  const apiKey = import.meta.env.VITE_PUSH_NOTIFICATIONS_API_KEY

  const handleClick = (enable) => {
    const messaging = getMessaging(firebaseApp)
    togglePushNotifications()

    if (!enable) {
      return
    }

    getToken(messaging, { vapidKey: apiKey })
      .then((currentToken) => {
        if (currentToken) {
          console.log("current token for client: ", currentToken)
          setPushNotificationToken(currentToken)
          createPushNotificationDevice({device_token: currentToken})
        } else {
          console.log("No registration token available. Request permission to generate one.")
        }
      })
      .catch((err) => {
        if (err.code === "messaging/permission-blocked") {
          setErrMessage("Notifications are blocked. Please enable them in your browser settings.")
          console.log("Notifications are blocked. Please enable them in your browser settings.")
        } else {
          console.log("An error occurred while retrieving token: ", err)
        }
      })

  }

  return (
    <div className="relative my-1 w-1/2 border border-grey rounded">
      <div
        className={`absolute w-1/2 h-full border border-2 rounded bottom-0 ${pushNotificationEnabled && pushNotificationToken ? 'left-0' : 'left-1/2'} transition-all duration-300`}
      />
      <div className="flex justify-evenly w-full">
        <button
          key={'On'}
          value={pushNotificationToken}
          onClick={() => handleClick(true)}
          disabled={pushNotificationEnabled && pushNotificationToken}
          className={`px-4 w-1/2 rounded ${pushNotificationEnabled && pushNotificationToken ? 'text-grey-100 border-grey-100' : 'text-grey border-grey-900'} transition-all duration-500`}
        >
          ON
        </button>
        <button
          key={'Off'}
          value={!pushNotificationToken}
          onClick={() => handleClick(false)}
          disabled={!pushNotificationEnabled || !pushNotificationToken}
          className={`px-4 w-1/2 rounded ${!pushNotificationEnabled || !pushNotificationToken ? 'text-grey-100 border-grey-100' : 'text-grey border-grey-900'} transition-all duration-500`}
        >
          OFF
        </button>
      </div>
    </div>
  )
}