import {
  CometChatConversationsWithMessages,
  ConversationsConfiguration,
  ConversationsStyle,
  MessagesConfiguration,
  MessagesStyle,
  CometChatUIEvents,
} from "@cometchat/chat-uikit-react"
import { useEffect, useState } from "react"
import { apiUrlV2 } from "../../constants/apiUrl"
import ActionButton from "../common/ActionButton"
import useStore from "../../store"

export default function SupportChat() {
  const [currentChat, setCurrentChat] = useState(null)
  const dealer = useStore((state) => state.dealer)
  const fetchDealer = useStore((state) => state.fetchDealer)
  const setSuccessMessage = useStore((state) => state.setSuccessMessage)
  const setErrMessage = useStore((state) => state.setErrMessage)
  const requestHeaders = useStore((state) => state.requestHeaders)

  const conversationsStyle = new ConversationsStyle({
    width: "100%",
    height: "90vh",
    background: "",
  })

  useEffect(() => {
    CometChatUIEvents.ccActiveChatChanged.subscribe((data) => {
      setCurrentChat(data.group)
    })
  }, [])

  useEffect(() => {
    if (!currentChat) {
      return
    }

    const dealerId = currentChat.guid.split("-")[1]

    fetchDealer(dealerId)
  }, [currentChat])

  const messagesStyle = new MessagesStyle({
    width: "100%",
    height: "90vh",
    background: "",
  })

  const resolveConversation = async (conversationId) => {
    try {
      await fetch(
        `${apiUrlV2}/dealers/${dealer.id}/dealer_conversations/${conversationId}`,
        {
          method: "PATCH",
          headers: requestHeaders,
          body: JSON.stringify({
            resolved_at: new Date().toISOString(),
          }),
        }
      )

      fetchDealer(dealer.id)
      setSuccessMessage("Conversation has been marked as resolved")
    } catch (err) {
      console.log("error", err)
      setErrMessage("Unable to resolve conversation. Please try again")
    }
  }

  return (
    <div className="w-full h-full grow">
      <CometChatConversationsWithMessages
        conversationsConfiguration={
          new ConversationsConfiguration({
            conversationsStyle: conversationsStyle,
          })
        }
        messagesConfiguration={
          new MessagesConfiguration({
            messagesStyle: messagesStyle,
          })
        }
        messageText="Click on Chat"
      />
      <div className="flex justify-center my-1">
        {dealer &&
          (dealer.attributes["active-conversation-id"] ? (
            <ActionButton
              buttonText="Mark Conversation Resolved"
              onClick={() => {
                resolveConversation(dealer.attributes["active-conversation-id"])
              }}
            />
          ) : (
            "No Unresolved Conversations Found"
          ))}
      </div>
    </div>
  )
}
