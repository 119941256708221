import { apiUrl, apiUrlV2 } from "../constants/apiUrl"
import createSearchParams from "../utilities/createSearchParams"

const initialState = {
  riskMitigations: null,
  riskMitigation: null,
  fieldAudits: [],
  fieldAuditsFetching: false,
  fieldAuditsTablePage: 1,
  auditsPaginationData: {}
}

const createRiskMitigationSlice = (set, get) => ({
  ...initialState,
  fetchRiskMitigation: async () => {
    try {
      const response = await fetch(
        `${apiUrlV2}/risk_mitigation`,
        {headers: get().requestHeaders}
      )
      if (response.status === 200) {
        const data = await response.json()

        if(get().user) {
          set((state) => ({ riskMitigation: data }))
        }
      }
    } catch (err) {
      get().setErrMessage('Data was unable to be fetched. Please refresh to try again')
    }

  },
  setFieldAuditsTablePage: (page) => {
    set((state) => ({fieldAuditsTablePage: page}))
  },
  fetchUnreconciledFieldAudits: async (params) => {
    set((state) => ({ fieldAuditsFetching: true}))

    try {
      const response = await fetch(
        `${apiUrlV2}/field_audits?per_page=20&page=${get().fieldAuditsTablePage}&filter[scope]=unreconciled&${createSearchParams(params)}`,
        {headers: get().requestHeaders}
      )
      if (response.status === 200) {
        const data = await response.json()

        if(get().user) {
          set((state) => ({
            fieldAudits: data.data,
            fieldAuditsFetching: false,
            auditsPaginationData: {
              dataTotal: response.headers.get('x-total'),
              lastPage: response.headers.get('x-last-page'),
              totalPages: response.headers.get('x-total-pages'),
              currentPage: Number(response.headers.get('x-page')) - 1
            }
          }))
        }
      }
    } catch (err) {
      get().setErrMessage('Data was unable to be fetched. Please refresh to try again')
    }

  },
  resetRiskMitigationSlice: () => set(initialState)
})

export default createRiskMitigationSlice
