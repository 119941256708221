import useStore from "../../../store"
import { Box, FormControl, FormLabel, Input, Textarea, Radio, RadioGroup, Stack } from '@chakra-ui/react'
import { useState } from "react"
import tailwind from "../../../../tailwind.config"
import { HappyButton, NeutralButton } from "../../common/Buttons"
import Spinner from "../../common/Spinner"
import SelectDropdown from "../../common/SelectDropdown"
import { typeEnum, purposeEnum, responseEnum } from "../../../constants/touchEnums"

export default function DealerNoteForm({ setShowNoteForm }) {
  const createDealerNote = useStore((state) => state.createDealerNote)
  const isCreatingDealerNote = useStore((state) => state.isCreatingDealerNote)
  const grey300 = tailwind.theme.colors["grey-300"]
  const dealer = useStore((state) => state.dealer)
  const user = useStore((state) => state.user)
  const [formErrors, setFormErrors] = useState([])
  const green = tailwind.theme.colors["green"]
  const [isATouch, setIsATouch] = useState(null)
  const [addReminder, setAddReminder] = useState(null)
  const [inputValues, setInputValues] = useState({
    touch: {
      touch_type: null,
      touch_purpose: null,
      touch_response: null,
    },
    subject: '',
    note: '',
    reminder: {
      reminder_due_on: null
    },
  })

  const handleSubmit = async () => {
    await createDealerNote(dealer.id, inputValues)
    setShowNoteForm(false)
  }

  const handleTouchChange = (value) => {
    setIsATouch(value)
    setInputValues((prevState) => ({
      ...prevState,
      touch: value === 'true' ? {touch_type: null, touch_purpose: null, touch_response: null} : null
    }))
  }

  return(
    <Box as="form" mt={2} ml={4} onSubmit={handleSubmit} dataTestId='dealer-note-form'>
      <FormControl id="interaction" mb={2}>
        <FormLabel fontSize={'sm'} color={grey300} htmlFor="interaction">
          Did you attempt to interact with the dealer?
        </FormLabel>
        <RadioGroup onChange={handleTouchChange} value={isATouch}>
        <Stack direction="row" spacing={5}>
            <Radio
              value="true"
              _checked={{
                bg: green,
                color: "white",
                borderColor: green,
              }}
              >
                Yes
              </Radio>
            <Radio
              value="false"
              _checked={{
                bg: green,
                color: "white",
                borderColor: green,
              }}
            >
              No
            </Radio>
          </Stack>
        </RadioGroup>
      </FormControl>

      {isATouch === 'true' &&
        <>
          <FormControl id="type" mb={2} onClick={() => setFormErrors([])}>
            <SelectDropdown
              label="How did you interact with the dealer?"
              options={Object.keys(typeEnum)}
              defaultText={'Select type'}
              selectedItem={inputValues.touch.touch_type}
              setSelectedItem={(item) =>
                setInputValues((prevState) => ({
                  ...prevState,
                  touch: {
                    ...prevState.touch,
                    touch_type: item
                  }
                }))
              }
              displaySelectedItem={(item) => typeEnum[item]}
              isErrored={formErrors.includes('type')}
              testId="type-select"
            />
          </FormControl>
          <FormControl id="purpose" mb={2} onClick={() => setFormErrors([])}>
            <SelectDropdown
              label="What was the purpose of this interaction?"
              options={Object.keys(purposeEnum)}
              defaultText={'Select purpose'}
              selectedItem={inputValues.touch.touch_purpose}
              setSelectedItem={(item) =>
                setInputValues((prevState) => ({
                  ...prevState,
                  touch: {
                    ...prevState.touch,
                    touch_purpose: item
                  }
                }))
              }
              displaySelectedItem={(item) => purposeEnum[item]}
              isErrored={formErrors.includes('purpose')}
              testId="purpose-select"
            />
          </FormControl>
          <FormControl id="response" mb={2}>
            <SelectDropdown
              label="What was the resolution?"
              options={Object.keys(responseEnum)}
              defaultText={'Select response'}
              selectedItem={inputValues.touch.touch_response}
              setSelectedItem={(item) =>
                setInputValues((prevState) => ({
                  ...prevState,
                  touch: {
                    ...prevState.touch,
                    touch_response: item
                  }
                }))
              }
              displaySelectedItem={(item) => responseEnum[item]}
              isErrored={false}
              testId="response-select"
            />
          </FormControl>
        </>
      }
      {isATouch &&
        <>
          <FormControl id="subject" mb={2}>
            <FormLabel fontSize={'sm'} color={grey300} htmlFor="subject">Subject</FormLabel>
            <Input
              value={inputValues.subject}
              onChange={(e) => setInputValues({ ...inputValues, subject: e.target.value })}
              focusBorderColor="white"
            />
          </FormControl>
          <FormControl id="note" mb={2}>
            <FormLabel fontSize={'sm'} color={grey300} htmlFor="note">Note</FormLabel>
            <Textarea
              value={inputValues.touch?.note}
              onChange={(e) => setInputValues({ ...inputValues, note: e.target.value })}
              focusBorderColor="white"
            />
          </FormControl>
        </>
      }
      {isATouch === 'true' &&
      <>
        <FormControl id="add-reminder" mb={2} aria-labelledby="add-reminder">
          <FormLabel fontSize={'sm'} color={grey300} htmlFor="add-reminder" id="add-reminder">
            Do you want to add a reminder?
          </FormLabel>
          <RadioGroup onChange={setAddReminder} value={addReminder} data-testid='add-reminder-radio'>
            <Stack direction="row" spacing={5}>
              <Radio
                value="true"
                _checked={{
                  bg: green,
                  color: "white",
                  borderColor: green,
                }}
              >
                Yes
              </Radio>
              <Radio
                value="false"
                _checked={{
                  bg: green,
                  color: "white",
                  borderColor: green,
                }}
              >
                No
              </Radio>
            </Stack>
          </RadioGroup>
        </FormControl>
        {addReminder === 'true' && (
          <>
            <FormControl id="remind-me-on" mb={2}>
              <FormLabel fontSize={'sm'} color={grey300} htmlFor="remind-me-on">
                Remind me on
              </FormLabel>
              <Input
                type="date"
                value={inputValues.reminder.reminder_due_on}
                onChange={(e) => setInputValues((prevState) => ({
                  ...prevState,
                  reminder: {
                    ...prevState.reminder,
                    reminder_due_on: e.target.value
                  }
                }))}
                focusBorderColor="white"
              />
            </FormControl>
          </>
        )}
      </>
      }
      <div className="flex justify-evenly items-center">
        {isCreatingDealerNote ?
          <Spinner color="green" /> :
          <>
            <HappyButton
              text={'Submit'}
              onClick={handleSubmit}
              style="my-4 w-30 justify-items-center"
            />
            <NeutralButton
              text='Cancel'
              onClick={() => setShowNoteForm(false)}
              style="my-4 w-30 justify-items-center"
            />
          </>
        }
      </div>
    </Box>
  )
}