import { useEffect, useState } from 'react'
import useStore from '../../store'
import DealerTable from './Dealers/DealerTable'
import MyKpis from './Kpis/MyKpis'
import Today from './TodaysDetails/Today'
import Spinner from '../common/Spinner'
import MyRiskMetrics from './Risk/MyRiskMetrics'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import tailwind from '../../../tailwind.config'

export default function AmDashboard() {
  const fetchDealers = useStore((state) => state.fetchDealers)
  const dailyOps = useStore((state) => state.dailyOps)
  const fetchDailyOps = useStore((state) => state.fetchDailyOps)
  const dealers = useStore((state) => state.dealers)
  const fetchRiskMitigation = useStore((state) => state.fetchRiskMitigation)
  const dealerFilterAttribute = useStore((state) => state.dealerFilterAttribute)
  const dealerFilterDirection = useStore((state) => state.dealerFilterDirection)
  const fetchUnreconciledFieldAudits = useStore((state) => state.fetchUnreconciledFieldAudits)
  const dealerTableFilterParams = useStore((state) => state.dealerTableFilterParams)
  const setUserLocation = useStore((state) => state.setUserLocation)
  const dealersPage = useStore((state) => state.dealersPage)
  const resetBreadcrumbTrail = useStore((state) => state.resetBreadcrumbTrail)
  const grey700 = tailwind.theme.colors["grey-700"]
  const grey100 = tailwind.theme.colors["grey-100"]
  const fetchListDealers = useStore((state) => state.fetchListDealers)
  const savedDealers = useStore((state) => state.savedDealers)
  const fetchNearbyDealers = useStore((state) => state.fetchNearbyDealers)
  const userLocation = useStore((state) => state.userLocation)

  useEffect(() => {
    fetchDailyOps()
    fetchRiskMitigation()
    fetchUnreconciledFieldAudits()
    fetchListDealers()
    if(navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setUserLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        })
      })
    }
  }, [])

  useEffect(() => {
    resetBreadcrumbTrail()
  }, [])

  useEffect(() =>{
    fetchDealers(dealerTableFilterParams)
  }, [dealerFilterAttribute, dealerFilterDirection, dealerTableFilterParams, dealersPage, savedDealers])

  return (
    <div className="flex flex-col overflow-y-auto grow lg:min-h-0 w-full justify-left items-center">
      <div className="flex flex-wrap lg:h-[85vh] mb-2 w-full">
          <div className='w-full lg:w-1/2 lg:h-full lg:overflow-y-auto mb-2 lg:px-2'>
            <div className='bg-grey-900 pt-2'>
              {dailyOps ?
                <>
                  <div className="w-full flex justify-center sticky top-0 z-30">
                    <Today/>
                  </div>
                  <div className="w-full flex justify-center">
                    <MyKpis/>
                  </div>
                  <div className="w-full flex justify-center">
                    <MyRiskMetrics/>
                  </div>
                </>
                :
                <Spinner/>
              }
            </div>
          </div>
        {dealers ?
          <div className="w-full lg:w-1/2 lg:h-full lg:pr-2 lg:overflow-y-auto">
            <DealerTable/>
          </div> :
          <div className="flex w-full justify-center my-2">
            <Spinner/>
          </div>
        }
      </div>
    </div>
  )
}
