export default function ClickableDiv({ onClick, testid=null, children }) {
  return (
    <div
      onClick={onClick}
      data-testid={testid}
      className="cursor-pointer w-1/3"
    >
      {children}
    </div>
  )
}