import { useTranslation } from "react-i18next"
import { useSelect } from 'downshift'
import { FaCaretDown, FaCaretUp } from "react-icons/fa"

export default function SelectDropdown({
  options,
  defaultText,
  selectedItem,
  setSelectedItem,
  displaySelectedItem,
  label = null,
  isErrored = false,
  testId = "select-open"
}) {
  const { t } = useTranslation()

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getItemProps,
    highlightedIndex,
  } = useSelect({
    selectedItem,
    items: options,
    itemToString: item => (item ? String(item) : ''),
    onSelectedItemChange: changes => {
      setSelectedItem(changes.selectedItem);
    }
  })

  return (
    <div className="relative w-full">
      <div className="w-full flex flex-col gap-1">
        {label &&
          <label
            {...getLabelProps()}
            className={`text-sm ${isErrored ? 'text-fpx-red' : 'text-grey-300'}`}
          >
            {t(label)}
          </label>
        }

        <div
          {...getToggleButtonProps()}
          className={`px-1 flex justify-between items-center border-2 rounded cursor-pointer focus:ring-0
          ${isErrored ? 'border-fpx-red focus:border-fpx-red' : 'border-grey-700 focus:border-grey-300'}`}
          data-testid={testId}
        >
          <span className="w-11/12">
            {selectedItem ? displaySelectedItem(selectedItem) : t(defaultText)}
          </span>
          <span>
            {isOpen ?
              <FaCaretUp data-testid="caret-up" /> :
              <FaCaretDown data-testid="caret-down" />
            }
          </span>
        </div>
      </div>

      <ul
        {...getMenuProps()}
        className={`absolute w-full bg-grey-900 border-2 border-grey-700 rounded mt-1 max-h-80 overflow-auto p-0 z-10 rounded ${
          !isOpen && 'hidden'
        }`}
        data-testid="select-menu"
      >
        {isOpen &&
          options.map((item, index) => (
            <li
              className={
                `${highlightedIndex === index && 'bg-grey-700'}
                ${selectedItem === item && 'font-black'}
                py-2 px-3 flex flex-col border-b-2 border-grey-700`
              }
              key={item.id}
              {...getItemProps({item, index})}
            >
              {displaySelectedItem(item)}
            </li>
          ))}
      </ul>
    </div>
  )
}
