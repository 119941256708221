export default function rtnValidator(rtn) {
  if (rtn.length != 9) {
    return false
  }

  const checksum = 3 * (Number(rtn[0]) + Number(rtn[3]) + Number(rtn[6])) +
    7 * (Number(rtn[1]) + Number(rtn[4]) + Number(rtn[7])) +
    (Number(rtn[2]) + Number(rtn[5]) + Number(rtn[8]))

  return checksum % 10 == 0
}