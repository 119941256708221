import {
  Alert,
  AlertIcon,
} from '@chakra-ui/react'
import tailwind from '../../../tailwind.config'

export default function WarningMessage({ text }) {
  const yellow = tailwind.theme.colors["yellow"]
  const yellowWithOpacity = tailwind.theme.colors['opaque-yellow']

  return (
    <div className="mt-2">
      <Alert bg={yellowWithOpacity} status='warning'>
        <AlertIcon color={yellow}/>
        {text}
      </Alert>
    </div>
  )
}