import { useNavigate } from "react-router-dom"
import useStore from "../../../store"
import { DateTime } from "luxon"
import { FaArrowCircleRight } from "react-icons/fa"
import { reminderOverdue } from "../../../utilities/reminderUtilities"
import SwipeAnimation from "../../common/SwipeAnimation"
import InfoItem from '../../common/InfoItem'

export default function ReminderPage() {
  const navigate  = useNavigate()
  const reminder = useStore((state) => state.reminder)
  const resetUnitsAsAmSlice = useStore((state) => state.resetUnitsAsAmSlice)
  const resetDealerSlice = useStore((state) => state.resetDealerSlice)
  const date = DateTime.fromISO(reminder.attributes['due-on'])

  const handleClick = () => {
    resetUnitsAsAmSlice()
    resetDealerSlice()
    navigate(`/dealers/${reminder.attributes.dealer.id}`)
  }

  return(
    <SwipeAnimation>
      <div className="flex w-full grow lg:justify-center px-2">
        <div className="flex flex-col overflow-y-auto lg:w-1/2">
          {reminderOverdue(reminder) &&
            <div className="flex flex-col items-center w-full text-fpx-red border border-fpx-red rounded">
              This reminder is past due.
            </div>
          }

          <div className= 'text-4xl text-grey-100 mt-2'>
            {date.toLocaleString()}
          </div>
          <div className="text-lg text-grey-100 mb-2">
            {reminder.attributes['assigned-to'] && reminder.attributes['assigned-to']['full_name']}
          </div>

          <InfoItem
            title={'Reminder'}
            data={reminder.attributes.name}
          />

          {reminder.attributes['assigned-by'] &&
            <InfoItem
              title='Created By'
              data={reminder.attributes['assigned-by']['full_name']}
            />
          }

          {reminder.attributes.dealer &&
            <InfoItem
              title="Dealer"
              data={
                <div
                  className="flex items-center hover:cursor-pointer"
                  onClick={handleClick}
                >
                  {reminder.attributes.dealer.lotname}
                  <FaArrowCircleRight className="ml-2" />
                </div>
              }
            />
          }
        </div>
      </div>
    </SwipeAnimation>
  )
}