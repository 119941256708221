import useStore from "../../../store"
import formatter from "../../../constants/currencyFormatter"
import UnitsFlooredDetailsItem from "./UnitsFlooredDetailsItem"

export default function UnitsFlooredDetails() {
  const dailyOps = useStore((state) => state.dailyOps)

  const metricColor = (metric) => {
    if (metric <= 0) {
      return 'text-fpx-red text-lg'
    } else {
      return 'text-white'
    }
  }

  return(
    <div className="relative p-2 flex-auto text-grey-100 text-sm">
      <UnitsFlooredDetailsItem
        text="Units Grown MTD"
        metric={dailyOps.units_floored_this_month}
        metricColor={metricColor(dailyOps.units_floored_this_month)}
      />
      <UnitsFlooredDetailsItem
        text="Change in AR MTD"
        metric={formatter.format(dailyOps.mtd_ar_change)}
        metricColor=""
        dataTestId="mtd-ar-change"
      />
      <UnitsFlooredDetailsItem
        text="Units Grown YTD"
        metric={dailyOps.ytd_unit_growth}
        metricColor={metricColor(dailyOps.ytd_unit_growth)}
      />
      <UnitsFlooredDetailsItem
        text="Change in AR YTD"
        metric={`${formatter.format(dailyOps.ytd_ar_change)} Change in AR YTD`}
        metricColor=""
        dataTestId="ytd-ar-change"
      />
      <hr className="my-2 border-gray-300" />
      <UnitsFlooredDetailsItem
        text="Net Units Today"
        metric={dailyOps.todays_net_units}
        metricColor={metricColor(dailyOps.todays_net_units)}
      />
      <div className="mb-2 ml-2">
        {`On average your branch floors ${dailyOps.floored_avg} ${
          dailyOps.floored_avg === 1 ? 'unit' : 'units'
        } per day`}
      </div>
      <div className="mb-2 ml-2">
        {`On average you pay-off ${dailyOps.payoff_avg} ${
          dailyOps.payoff_avg === 1 ? 'unit' : 'units'
        } per day`}
      </div>
      <div className="mb-2 ml-2">{`You have ${dailyOps.ach_payoff_count} pending ACH payoffs`}</div>
    </div>
  )
}