import { motion } from "framer-motion"
import BackButton from "./BackButton"

export default function SlideInInfoPage({onBack, children }) {
  return (
    <div className="overflow-x-hidden">
      <motion.div
        initial={{ x: "100%" }}
        animate={{ x: 0 }}
        transition={{ type: "tween", duration: 0.2 }}
      >
        <BackButton onClick={onBack} />
        {children}
      </motion.div>
    </div>
  )
}
