import FloorAmount from "./FloorAmount"
import NAPPicture from "./NAPPicture"
import NAPConfirmation from "./NAPConfirmation"
import NAPSuccess from "./NAPSuccess"
import napEnum from "../../../constants/napEnum"

const NAPWorkflow = (NAPIssues, issues = false, requiresBankPayoff = false) => {
  const workflow = [
    <FloorAmount />,
    <NAPPicture key={1} title={napEnum[1]} />,
    <NAPPicture key={2} title={napEnum[2]} />,
    <NAPPicture key={3} title={napEnum[3]} />,
    <NAPPicture key={4} title={napEnum[4]} />,
    <NAPPicture key={5} title={napEnum[5]} />,
    <NAPPicture key={6} title={napEnum[6]} />,
    <NAPPicture key={7} title={napEnum[7]} />,
    <NAPConfirmation issues={issues} />,
    <NAPSuccess issues={issues} />
  ]

  if (issues) {
    const issuesWorkflow = []

    NAPIssues.forEach((i) => {
      issuesWorkflow.push(<NAPPicture key={i.issue_step} title={napEnum[i.issue_step]} />)
    })
    issuesWorkflow.push(workflow[8], workflow[9])

    return issuesWorkflow
  } else if (requiresBankPayoff) {
    workflow.splice(1, 2, <NAPPicture key={2} title='Payoff Authorization' />)

    return(workflow)
  } else {
    return(workflow)
  }
}
export default NAPWorkflow