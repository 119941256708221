import { Box, Text, Flex, Image } from "@chakra-ui/react"
import tailwind from "../../../../tailwind.config"
import useStore from "../../../store"
import { useIsMobile } from "../../../hooks/useIsMobile"
import { useNavigate } from "react-router-dom"

export default function CouponCard({coupon}) {
  const green = tailwind.theme.colors["green"]
  const grey100 = tailwind.theme.colors["grey-100"]
  const deepOcean = tailwind.theme.colors["deep-ocean"]
  const isMobile = useIsMobile()
  const navigate = useNavigate()
  const setCouponInfoPageId = useStore((state) => state.setCouponInfoPageId)
  const setDealerDashboardSidePanel = useStore((state) => state.setDealerDashboardSidePanel)
  const setDashboardUnitId = useStore((state) => state.setDashboardUnitId)
  const fetchCoupon = useStore((state) => state.fetchCoupon)
  const couponInState = useStore((state) => state.coupon)
  const coupons = useStore((state) => state.coupons)

  const handleClick = () => {
    if(coupon.id !== couponInState?.id) {
      fetchCoupon(coupon.id)
    }
    if (isMobile) {
      navigate(`/coupons/${coupon.id}`)
    } else {
      setDashboardUnitId(null)
      setCouponInfoPageId(coupon.id)
      setDealerDashboardSidePanel(null)
    }
  }

  const marginValue = isMobile || coupons?.length === 1 ? 0 : 12

  return(
    <Box bg={deepOcean} pb={2} px={2} pt={2} mb={!isMobile && 2} ml={marginValue} mr={marginValue} mx={isMobile ? 2 : ''} borderRadius="md" position="relative" onClick={handleClick} cursor={'pointer'}>
      <Flex alignItems="start" gap={4}>
        {coupon?.attributes['banner-photo-url'] &&
          <Image
            src={coupon?.attributes['banner-photo-url']}
            alt="Coupon Photo"
            boxSize="60px"
            borderRadius="md"
            objectFit="cover"
          />
        }
        <Flex direction="column">
          <Text color={grey100} fontSize="md">{coupon?.attributes['banner-header']}</Text>
          <Text color={grey100} fontSize="md">{coupon?.attributes['banner-body']}</Text>
        </Flex>
        <Flex justifyContent={'flex-end'}>
      </Flex>
      </Flex>
      <Text
          color={green}
          borderBottom="2px solid"
          borderColor={green}
          pb="1px"
          position={'absolute'}
          bottom={'8px'}
          right={"10px"}
        >
          Get started
        </Text>
    </Box>
  )
}