
export default function OptionsRowItem({ icon, label, onClick }) {

  return(
    <div
    className="flex flex-col items-center text-xs px-5 py-2 rounded hover:cursor-pointer hover:bg-grey-100 hover:bg-opacity-20"
    onClick={onClick}
    >
      {icon}
      {label}
    </div>
  )
}