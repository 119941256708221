import { useState } from "react"
import useStore from "../store"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import fxLogo from "../../public/fx-header-logo-white.png"
import Spinner from "./common/Spinner"
import { FiEye, FiEyeOff } from "react-icons/fi"
import { NeutralButton } from "./common/Buttons"
import MessageHandling from "./MessageHandling/MessageHandling"

export default function AcceptInvitation() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const token = searchParams.get("token")
  const [formValues, setFormValues] = useState({
    password: "",
    passwordConfirm: "",
  })
  const [isFormErrored, setIsFormErrored] = useState(false)
  const [submitting, setSubmitting] = useState("")
  const [type, setType] = useState("password")
  const setErrMessage = useStore((state) => state.setErrMessage)
  const acceptInvitation = useStore((state) => state.acceptInvitation)
  const loginTasks = useStore((state) => state.loginTasks)

  const requestBody = {
    password: formValues.password,
    invitation_token: token,
  }

  const handleSubmit = async (e) => {
    if (formValues.password !== formValues.passwordConfirm) {
      setIsFormErrored(true)
      setErrMessage("Password and password confirmation do not match")
    } else if (formValues.password.length === 0) {
      setIsFormErrored(true)
      setErrMessage("No password has been set")
    } else {
      try {
        setSubmitting(true)

        const response = await acceptInvitation(requestBody)
        const data = await response.json()
        if (response.ok) {
          loginTasks(data)
          navigate("/")
        } else {
          setErrMessage("Unable to process this request")
        }
      } catch (err) {
        setErrMessage("There was an error processing this invitation request.")
      } finally {
        setSubmitting(false)
      }
    }
  }

  const handleToggle = () => {
    if (type === "password") {
      setType("text")
    } else {
      setType("password")
    }
  }

  return (
    <MessageHandling>
      <div className="flex flex-col h-screen items-center justify-center mt-4 lg:mt-0">
        <div
          className="flex flex-col items-center w-2/3 lg:w-1/2"
          onClick={() => setIsFormErrored(false)}
        >
          <img src={fxLogo} alt="Floorplan Xpress" className="h-20" />
          <h1 className="text-lg">{t("Set Password to Accept Invitation")}</h1>
          <div
            className={`flex w-full my-2 border-solid border-2 rounded
            ${
              isFormErrored
                ? "border-fpx-red focus-within:border-fpx-red"
                : "border-grey-700 focus-within:border-grey-300"
            }`}
          >
            <input
              placeholder={t("Password")}
              type={type}
              value={formValues.password}
              name="password"
              title="password"
              className="p-3 w-full bg-black border-none focus:border-none focus:ring-0"
              onChange={(e) =>
                setFormValues({ ...formValues, password: e.target.value })
              }
            />
            <button
              className="flex justify-around items-center"
              aria-label="view password"
              type="button"
              onClick={handleToggle}
            >
              {type === "password" ? (
                <FiEyeOff
                  className="mr-2 text-grey-300"
                  size={25}
                  alt="show password"
                  data-testid="show-password-button"
                />
              ) : (
                <FiEye
                  className="mr-2 text-grey-300"
                  size={25}
                  alt="hide password"
                  data-testid="hide-password-button"
                />
              )}
            </button>
          </div>

          <input
            placeholder={t("Password Confirmation")}
            type={type}
            value={formValues.passwordConfirm}
            name="password-confirmation"
            title="password-confirmation"
            className={`p-3 w-full bg-black border-2 focus:ring-0 rounded
            ${
              isFormErrored
                ? "border-fpx-red focus-within:border-fpx-red"
                : "border-grey-700 focus-within:border-grey-300"
            }`}
            onChange={(e) =>
              setFormValues({ ...formValues, passwordConfirm: e.target.value })
            }
          />

          {submitting ? (
            <Spinner />
          ) : (
            <NeutralButton
              text={t("Accept Invitation")}
              onClick={handleSubmit}
              style="my-3"
            />
          )}
          <div className="text-fpx-red underline" onClick={() => navigate("/")}>
            {t("Go To Login")}
          </div>
        </div>
      </div>
    </MessageHandling>
  )
}
