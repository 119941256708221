import Collapsible from "../../common/Collapsible"
import RiskDealerRow from "./RiskDealerRow"
import UnitRow from "../../Dealer/CurrentUnits/UnitRow"
import RiskRowTitle from "./RiskRowTitle"

export default function RiskRow({riskMetric, title, isCollapsed, handleToggle}) {
  const renderDealerOrUnitRow = (metric) => (
    {
      dealers: <RiskDealerRow dealer={metric} key={metric.id} />,
      units: <UnitRow unit={metric} key={metric.id}/>,
      eaudits: <RiskDealerRow dealer={metric} key={metric.id}/>
    }[metric.type]
  )

  return(
    <>
      {riskMetric.length > 0 &&
        <div className="bg-grey-900 cursor-pointer">
          <Collapsible
            titleStyle={'font-bold text-lg text-fpx-red py-3 px-2'}
            title={<RiskRowTitle amount={riskMetric.length} title={title}/>}
            isCollapsed={isCollapsed}
            handleToggle={handleToggle}
          >
            <div className="pl-2 w-full">
              {riskMetric.map((metric) => renderDealerOrUnitRow(metric))}
            </div>
          </Collapsible>
        </div>
      }
    </>
  )
}
