import { NeutralButton, SolidGreenButton } from "../../common/Buttons";
import Spinner from "../../common/Spinner";

export default function EditDealerInfoButtons({ setIsEditing, onSubmit, isUpdating }) {
  return(
    <div className="flex w-full justify-evenly">
      {isUpdating ?
        <Spinner /> :
        <>
          <SolidGreenButton
            text="Submit"
            onClick={onSubmit}
            data-testid="submit-button"
          />
          <NeutralButton
            text="Cancel"
            onClick={() => setIsEditing(false)}
          />
        </>
      }
    </div>
  )
}