import { useTranslation } from "react-i18next"

export default function TransferHeader() {
  const { t } = useTranslation()

  return (
    <div className="col-span-2 font-header font-semibold text-md text-green mt-2 ml-2">
      {t("Transfer Information")}
    </div>
  )
}