import unitStatus from "./unitStatus"
import formatter from "../constants/currencyFormatter"

const unitFilter = (units, filter) => {
  return(
    units.filter((unit) => 
      unit.attributes.name.toLowerCase().includes(filter.toLowerCase()) ||
      unit.attributes.vin.toLowerCase().includes(filter.toLowerCase()) || 
      unitStatus(unit).text.toLowerCase().includes(filter.toLowerCase()) ||
      formatter.format(unit.attributes.payoff).includes(filter) ||
      formatter.format(unit.attributes.curtailment).includes(filter)
    )
  )
}

export default unitFilter