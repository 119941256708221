import useStore from '../../../store'
import tailwind from '../../../../tailwind.config'
import NeedsAttentionBanner from '../../common/NeedsAttentionBanner'

export default function UnitsNeedingAttention() {
  const grey100 = tailwind.theme.colors["grey-100"]
  const red = tailwind.theme.colors["fpx-red"]
  const dealer = useStore((state) => state.dealer)

  const items = [
    {
      needsAttentionUnitCount: dealer.attributes['untouched-unit-count'],
      testId: 'untouched',
      text: 'Untouched 40+ Days:',
    },
    {
      needsAttentionUnitCount: dealer.attributes['sold-unit-count'],
      testId: 'sold',
      text: 'Sold Units:',
    },
    {
      needsAttentionUnitCount: dealer.attributes['repo-unit-count'],
      testId: 'repos',
      text: 'Repo Units:',
    },
    {
      needsAttentionUnitCount: dealer.attributes['past-due-unit-count'],
      testId: 'past-due',
      text: 'Past Due Units:',
    },
  ]

  return(
    <NeedsAttentionBanner
      title="Units Needing Attention"
      status="error"
      items={items}
      bgColor={red}
    />
  )
}