const vinOptimizer = (vin) => {
  let returnVin = vin

  returnVin = returnVin.replaceAll('I', '1')
  returnVin = returnVin.replaceAll('O', '0')
  returnVin = returnVin.replaceAll('Q', '0')

  return returnVin
}

export default vinOptimizer