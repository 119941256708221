import { useState, useEffect } from "react"
import { useMediaQuery } from 'react-responsive'
import tailwind from "../../tailwind.config"
import resolveConfig from 'tailwindcss/resolveConfig'

export function useIsMobile() {
  const fullConfig = resolveConfig(tailwind)
  const isMobile = useMediaQuery({ query: `(max-width: ${fullConfig.theme.screens.lg})`})

  return isMobile
}