import useStore from "../../store"
import Message from "./Message"

export default function MessageHandling({ children, ignoreTimeout=false }) {
  const appMessage = useStore((state) => state.appMessage)
  const resetMessages = useStore((state) => state.resetMessages)

  return(
    <div className="w-full h-full" onClick={resetMessages}>
      {appMessage && <Message message={appMessage.message} buttonOptions={appMessage.buttonOptions} type={appMessage.type} ignoreTimeout={ignoreTimeout} />}
      {children}
    </div>
  )
}