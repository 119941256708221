import { initializeApp } from 'firebase/app'

export const firebaseConfig = {
  apiKey: 'AIzaSyCimSJKQR9Kmd8Qlp6q3SxLlq-xVswpAXM',
  authDomain: "triple-b-ccfb6.firebaseapp.com",
  projectId: "triple-b-ccfb6",
  storageBucket: "triple-b-ccfb6.appspot.com",
  messagingSenderId: "632194354443",
  appId: "1:632194354443:web:1b1bca6b87bfc7c4c9a60e"
}

const firebaseApp = initializeApp(firebaseConfig)
export default firebaseApp