import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react'
import tailwind from '../../../tailwind.config'
import NeedingAttentionItem from '../AccountManager/Dealer/NeedingAttentionItem'

export default function NeedsAttentionBanner({title, status, items, bgColor}) {
  const grey100 = tailwind.theme.colors["grey-100"]

  return(
    <div className='pb-2'>
      <Alert status={status} bg={bgColor}>
        <AlertIcon />
        <AlertTitle color={grey100} w={300}>{title}</AlertTitle>
        <AlertDescription color={grey100}>
          <div className='flex flex-wrap'>
            {items.map((item, index) => (
              <NeedingAttentionItem
                key={index}
                needsAttentionUnitCount={item.needsAttentionUnitCount}
                testId={item.testId}
                text={item.text}
              />
            ))}
          </div>
        </AlertDescription>
      </Alert>
    </div>
  )
}