import useStore from "../../../store"
import { useTranslation } from "react-i18next"
import { Payoff, Refloor, OtherPayment } from "../../common/PayoffRefloorPills"
import formatter from "../../../constants/currencyFormatter"
import formatAccountNumber from "../../../utilities/accountNumber"
import InfoItem from "../../common/InfoItem"
import { DateTime } from "luxon"
import { useEffect } from "react"
import Spinner from "../../common/Spinner"
import { useIsMobile } from "../../../hooks/useIsMobile"

export default function TransferPageBody({ transferId = null, showAltData = true, shiftPill=false }) {
  const { t } = useTranslation()
  const transfer = useStore((state) => state.transfer)
  const transferDate = () => DateTime.fromISO(transfer.attributes['actual-transfer-date'])
  const fetchTransfer = useStore((state) => state.fetchTransfer)
  const isFetchingTransfer = useStore((state) => state.isFetchingTransfer)
  const isMobile = useIsMobile()

  useEffect(() => {
    if (transferId) {
      fetchTransfer(transferId)
    }
  }, [transferId])


  const transferType = () => {
    switch(transfer.attributes['payment-type']) {
      case 'Refloor':
        return <Refloor />
      case 'Payoff':
        return <Payoff />
      default:
        return <OtherPayment type={transfer.attributes['payment-type']} />
    }
  }

  // Transfer status is either 'Locked' or 'In Progress'
  const transferIsProcessing = () => [2, 3].includes(transfer.attributes.status)

  return(
    <>
      {transfer && !isFetchingTransfer ?
        <div className="flex flex-col overflow-y-auto pt-2 pl-2 pb-4 bg-grey-900 relative">
          <div className={`absolute right-0 pt-1 mr-${shiftPill ? '10' : '4'}`}>
            {transferType()}
          </div>
          <div className= 'text-4xl text-grey-100 mt-2'>
            {formatter.format(transfer.attributes.amount)}
          </div>
          <div className="ml-1">
            {showAltData && 
              <div className="text-lg text-grey-100 mb-2">
                {`${transfer.attributes.make} ${transfer.attributes.model}`}
              </div>
            }
            {transferIsProcessing() &&
              <div className="flex flex-col items-center my-2 w-full text-yellow border border-yellow rounded">
                {t('This transaction is currently being processed.')}
              </div>
            }
          </div>
          <div className={`${!isMobile && "grid grid-cols-2"}`}>
            
            {showAltData && <InfoItem title="VIN">{transfer.attributes.vin}</InfoItem>}
            <InfoItem title="Initiated by">{transfer.attributes['created-by-name']}</InfoItem>
            <InfoItem title="Date">{transferDate().toFormat('M/d/yy')}</InfoItem>
            <InfoItem title="Account">{`${transfer.attributes['account-type']} ${formatAccountNumber(transfer.attributes['safe-act'])}`}</InfoItem>
            <InfoItem title="Account Nickname">{transfer.attributes['account-name-for-dealer']}</InfoItem>
            {showAltData &&
              <InfoItem title="Breakdown">
                <div className="flex flex-col pl-4">
                  <div>{`Principal - ${formatter.format(transfer.attributes['principal-paid'])}`}</div>
                  <div>{`Interest - ${formatter.format(transfer.attributes['interest-paid'])}`}</div>
                  <div>{`Fees - ${formatter.format(parseFloat(transfer.attributes['fees-paid']) + parseFloat(transfer.attributes['insurance-fees-paid']))}`}</div>
                  {transfer.attributes['reserves-added'] > 0 &&
                    <div>{`Reserves Added - ${formatter.format(transfer.attributes['reserves-added'])}`}</div>
                  }
                </div>
              </InfoItem>
            }
          </div>
        </div>
      :
        <div className="flex justify-center w-full pt-2 pl-2">
          <Spinner/>
        </div>
      }
    </>
  )
}