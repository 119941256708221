import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import fxLogo from '../../public/fx-header-logo-white.png'
import { apiUrl } from '../constants/apiUrl'
import { NeutralButton } from './common/Buttons'

export default function PasswordRecovery() {
  const [login, setLogin] = useState('')
  const [isEmailSent, setIsEmailSent] = useState(false)
  const navigate = useNavigate()
  
  const handleSubmit = () => {
    fetch(`${apiUrl}/auth/forgot-password`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ login: login })
    })
      .then(response => response.json())
    setIsEmailSent(true)
  }

  const emailForm = () => {
    return (
      <>
        <h1 className="text-lg">Reset Your Password</h1>
        <p className="w-80 m-3 text-sm text-center">Please enter the email address associated with your account. We’ll send you an email with instructions on how to reset your password.</p>
        <div className="flex m-2">
          <input 
            placeholder="Email"
            value={login}
            name="email"
            title="email"
            className="p-3 bg-black border-solid border-2 border-grey-700 focus:border-grey-300 focus:ring-0 rounded"
            onChange={(e) => setLogin(e.target.value)}
          />
        </div>
        <NeutralButton text="Send Email" onClick={handleSubmit} style="my-2" />
      </>
    )
  }

  const checkEmailText = () => {
    return (
      <>
        <h1 className="text-lg">Check Your Email</h1>
        <p className="w-80 m-3 text-sm text-center">We just sent you an email with password reset instructions to your email address:</p>
        <p className="w-80 m-2 p-2 text-sm text-center font-bold bg-grey-900 rounded">{login}</p>
      </>
    )
  }

  return (
    <div className="flex flex-col h-screen justify-center items-center">
        <img src={fxLogo} alt="Floorplan Xpress" className="h-20" />
        {isEmailSent ? checkEmailText() : emailForm()}
        <div
          className="text-fpx-red hover:opacity-75 underline"
          onClick={() => navigate("/")}
        >
          Return to Login
        </div>
    </div>
  )
}