import { FaChevronLeft } from "react-icons/fa"
import { useTranslation } from "react-i18next"

export default function BackButton({ onClick, styles = '' }) {
  const { t } = useTranslation()

  return(
    <div
      className={`${styles} flex items-center h-full hover:cursor-pointer`}
      onClick={onClick}
      data-testid="navigate-back"
    >
      <FaChevronLeft className="text-md mr-1" />
      {t('Back')}
    </div>
  )
}