import environment from "../constants/environment"

const rollbarConfig =  (user) => {
  let personPayload = {
    id: 'no user',
    username: 'no user',
    type: user?.type || 'no user'
  }

  if (user?.type === 'DealerUser') {
    personPayload.id = user.dealerUserId
    personPayload.username = user.email
  } else if (user?.type === 'AccountManager') {
    personPayload.id = user.userId
    personPayload.username = user.userFirstName
  }

  return {
    accessToken: import.meta.env.VITE_ROLLBAR_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: environment === 'production' || environment === 'staging',
    payload: {
      person: personPayload,
      environment: environment,
      client: {
        javascript: {
          // source_map_enabled: true,
          code_version: '1.0',
          guess_uncaught_frames: true
        }
      }
    }
  }
}

export default rollbarConfig