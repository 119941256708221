import { useState } from "react"
import SummaryOfRates from "./SummaryOfRates"
import TermsOfServiceBody from "./TermsOfServiceBody"

export default function TermsOfService() {
  const [showSummaryRates, setShowSummaryRates] = useState(false)

  return(
    <>
      {showSummaryRates ?
        <SummaryOfRates close={() => setShowSummaryRates(false)} /> :
        <TermsOfServiceBody setShowSummaryRates={setShowSummaryRates} />
      }
    </>
  )
}