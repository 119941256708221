import { useIsMobile } from "../../../hooks/useIsMobile"
import { useNavigate } from "react-router-dom"
import ClickableRow from "../../common/ClickableRow"
import useStore from "../../../store"

export default function NotesClickableRow({ onClick, isExpanded }) {
  const isMobile = useIsMobile()
  const navigate = useNavigate()
  const dealer = useStore((state) => state.dealer)

  const handleClick = () => {
    if (isMobile) {
      navigate(`/notes/dealers/${dealer.id}`)
    }

    onClick()
  }
  return(
    <div className="flex flex-col items-center w-full bg-grey-900" data-testid="reserve-info-page">
      {dealer &&
        <ClickableRow text={'Notes'}
          onClick={handleClick}
          isSelected={isExpanded}
        />
      }
    </div>
  )
}