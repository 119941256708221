import useStore from "../../../store"
import ProspectDealerInformation from "./ProspectDealerInformation"
import Touches from "./Touches/Touches"
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import tailwind from "../../../../tailwind.config"
import DealerNameHeader from "../Dealer/DealerNameHeader"
import Spinner from "../../common/Spinner"

export default function ProspectDealerBody() {
  const dealer = useStore((state) => state.dealer)
  const grey700 = tailwind.theme.colors["grey-700"]
  const grey100 = tailwind.theme.colors["grey-100"]
  const dealerName = dealer?.attributes['name'] ? dealer.attributes['name'] : 'No Name'

   return (
    <>
    {dealer ?
      <div className="mt-3 w-full">
        <Tabs variant={'soft-rounded'}>
          <DealerNameHeader name={dealerName}/>
          <TabList pb={4}>
            <Tab data-testid='info-tab' _selected={{ color: 'white', bg: grey700 }} color={grey100}>Information</Tab>
            <Tab data-testid='touches-tab' _selected={{ color: 'white', bg: grey700 }} color={grey100}>Touches</Tab>
          </TabList>
          <TabPanels>
            <TabPanel p={0}>
              <div data-testid='prospect-information'>
                <ProspectDealerInformation />
              </div>
            </TabPanel>
            <TabPanel p={0}>
              <Touches dealerId={dealer.id} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div> :
      <div className="flex justify-center pt-3 w-full">
        <Spinner />
      </div>
    }
    </>
  )
}