import useStore from "../../../store"
import { useTranslation } from "react-i18next"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import formatter from "../../../constants/currencyFormatter"
import { NeutralButton } from "../../common/Buttons"
import SwipeAnimation from "../../common/SwipeAnimation"

export default function NAPSuccess({ issues = false }) {
  const { t } = useTranslation()
  const blackbook = useStore((state) => state.NAPBlackbook)
  const napId = useStore((state) => state.napId)
  const resetNAPSlice = useStore((state) => state.resetNAPSlice)
  const fetchUnits = useStore((state) => state.fetchUnits)
  const subscribeToUnit = useStore((state) => state.subscribeToUnit)
  const floorAmount = useStore((state) => state.NAPFloorAmount)
  const navigate = useNavigate()

  useEffect(() => {
    fetchUnits()
    subscribeToUnit(napId)
  }, [])

  return(
    <SwipeAnimation>
      <div className="flex flex-col items-center justify-between pt-6 rounded w-full h-full">
        <div className="text-center">
          <div className="text-2xl">{t("Success!!")}</div>
          <div className="text-grey-300 my-2">
            {t("You have successfully created a floor request")}
          </div>
        </div>

        <div className="flex flex-col items-center w-full text-2xl text-grey-300 py-16 bg-grey-900 rounded">
          <div className="text-grey-100 text-3xl text-center">
            {`${blackbook.model_year} ${blackbook.make} ${blackbook.model}`}
          </div>
          <div>
            {blackbook.vin}
          </div>
          <div>
            {!issues && formatter.format(floorAmount)}
          </div>
        </div>

        <div className="flex flex-col items-center">
          <NeutralButton
            text={t("View Unit")}
            onClick={() => {
              resetNAPSlice()
              navigate(`/unit/${napId}`)
            }}
            style="my-2 text-lg"
          />
          <NeutralButton
            text={t("Return to Dashboard")}
            onClick={() => {
              resetNAPSlice()
              navigate('/')
            }}
            style="mb-16 text-lg"
          />
        </div>
      </div>
    </SwipeAnimation>
  )
}