import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import useStore from "../../../store"
import RecentFloorRequestRow from "./RecentFloorRequestRow"
import { HappyPill, WarningPill, SadPill } from "../../common/PayoffRefloorPills"

export default function RecentFloorRequests() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const NAPBlackbook = useStore((state) => state.NAPBlackbook)
  const pendingAndHistoricNaps = useStore((state) => state.pendingAndHistoricNaps)

  return(
    <div className="w-full px-2 bg-grey-900 rounded">
      <div className="sticky top-0 left-0 z-0 bg-grey-900 py-2">
        <h3 className="font-header font-semibold text-md text-green">{t('Recent Floor Requests')}</h3>
      </div>
      <div>
        {NAPBlackbook &&
          <RecentFloorRequestRow
            yearMake={`${NAPBlackbook.model_year} ${NAPBlackbook.make}`}
            model={`${NAPBlackbook.model} ${NAPBlackbook.series}`}
            vin={NAPBlackbook.vin}
            rowStatus={<SadPill text={t("Incomplete")} />}
            rowAction={() => navigate('/nap')}
          />
        }
        {pendingAndHistoricNaps()?.map((unit) => {
          return(
            <RecentFloorRequestRow
              key={unit.attributes.vin}
              yearMake={`${unit.attributes.year} ${unit.attributes.make}`}
              model={unit.attributes.model}
              vin={unit.attributes.vin}
              rowStatus={unit.attributes.status === 'Pending' ? <WarningPill text={t("Pending")} /> : <HappyPill text={t("Approved")} />}
              rowAction={() => navigate(`/unit/${unit.id}`)}
            />
          )
        })}
      </div>
    </div>
  )
}