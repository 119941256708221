export default function DesktopRiskSquare({riskMetric, text}) {
  const riskMetricColor = (metric) => {
    if(metric > 0) {
      return('text-3xl font-extrabold text-center text-fpx-red')
    } else {
      return('text-3xl font-extrabold text-center text-green')
    }
  }

  return(
    <div className="relative flex flex-col items-center justify-center lg:h-60 lg:w-60 md:h-60 md:w-60 w-full h-full p-12 border-2 border-grey-700 rounded-md">
      <h4 className="text-grey-100 font-header font-semibold text-lg absolute top-0 left-0 p-2 text-center">{text}</h4>
      <p className={riskMetricColor(riskMetric)}>
        {riskMetric}
      </p>
    </div>
  )
}
