import { useState, useEffect, useCallback } from "react"
import useStore from "../../../store"
import { useTranslation } from "react-i18next"
import { NeutralButton } from "../../common/Buttons"
import ImagePreview from "./ImagePreview"
import SwipeAnimation from "../../common/SwipeAnimation"

export default function NAPPicture({ title }) {
  const { t } = useTranslation()
  const [previewOpen, setPreviewOpen] = useState(false)
  const image = useStore((state) => state.NAPImages[title])
  const nextStep = useStore((state) => state.nextStep)
  const setNAPImages = useStore((state) => state.setNAPImages)
  const NAPImages = useStore((state) => state.NAPImages)

  const handleUpload = (e) => {
    const file = e.target.files[0]
    if (file) {
      const reader = new FileReader()

      reader.onloadend = () => {
        setNAPImages({...NAPImages, [title]: reader.result})
      }

      reader.readAsDataURL(file)
    }
  }

  const imagePreview = () => {
    return(
      <div className="flex flex-col items-center mt-6">
        <div
          className="w-full h-[45vh] rounded"
          onClick={() => setPreviewOpen(true)}
          data-testid='photo-preview'
        >
          <img src={image} alt="Preview" className="w-full h-full object-contain rounded" />
        </div>
      </div>
    )
  }

  return(
    <SwipeAnimation>
      <div className="fixed top-0 left-0 z-50">

        {previewOpen && <ImagePreview
          image={image}
          onClose={() => setPreviewOpen(false)}
        />}
      </div>

      <div className="flex flex-col items-center justify-between pt-6 rounded w-full h-full">
        <div className="text-2xl mb-4">{title}</div>


        {image && imagePreview()}

        <div className="flex flex-col items-center">
          {image &&
            <NeutralButton
              text={t("Use Picture")}
              onClick={() => nextStep()}
              style="text-lg mb-4"
            />
          }

          <input
            type="file"
            accept="image/*"
            capture="camera"
            id="photo-input"
            data-testid="photo-input"
            style={{ display: 'none' }}
            onChange={handleUpload}
          />
          <label
            htmlFor="photo-input"
            className="flex justify-center items-center text-center text-grey-100 hover:opacity-75 hover:cursor-pointer py-1.5 px-8 border border-2 border-grey-100 rounded-full"
          >
            {image ? "Retake Picture" : "Take Picture"}
          </label>
        </div>
      </div>
    </SwipeAnimation>
  )
}