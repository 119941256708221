import Spinner from "../../common/Spinner"
import useStore from "../../../store"
import ReserveInfoBody from "./ReserveInfoBody"

export default function MobileReserveInfo() {
  const dealer = useStore((state) => state.dealer)

  return(
      <div className="flex grow flex-col items-center w-full overflow-y-auto">
        <div className="sticky text-center z-30 pt-2 pb-2 lg:pb-3 px-2 rounded-t">
          <div className="font-black text-2xl pl-2 lg:pl-2">{dealer?.attributes.name}</div>
        </div>
        {dealer ?
          <ReserveInfoBody/>
        :
          <Spinner />
        }
      </div>
  )
}