import useStore from "../../../store"
import formatter from '../../../constants/currencyFormatter'
import AlertMessage from '../../common/AlertMessage'
import { useTranslation } from 'react-i18next'

export default function CreditAvailable() {
  const dealer = useStore((state) => state.dealer)
  const isSuspended = dealer.attributes['active-status'] === 'Suspended'
  const { t } = useTranslation()

  return (
    <div>
      <div 
        className={`font-black text-2xl mt-1 pl-2 lg:pl-2 ${isSuspended && 'text-yellow'}`}
        data-testid="credit-available"
      >
        {formatter.format(dealer.attributes['total-credit-remaining'])}
      </div>
      {isSuspended && 
        <AlertMessage 
          message="Please contact your Account Manager immediately" 
          style="justify-center text-yellow !text-base" 
        />
      }
    </div>
  )
}