import useStore from "../../store"
import { useState, useEffect } from "react"
import { FiAlertCircle, FiCheck } from "react-icons/fi"
import { IoMdClose } from "react-icons/io"
import { useNavigate } from "react-router-dom"

export default function ErrorMessage({ message, type, buttonOptions, ignoreTimeout=false }) {
  const [isLoaded, setIsLoaded] = useState(false)
  const appMessage = useStore((state) => state.appMessage)
  const resetMessages = useStore((state) => state.resetMessages)
  const startAutoDismissMessageTimer = useStore((state) => state.startAutoDismissMessageTimer)
  const navigate = useNavigate()

  useEffect(() => {
    setIsLoaded(true)
  }, [appMessage])

  const icon = () => {
    if (type === 'success') {
      return (
        <FiCheck 
          className="text-2xl text-green"
        />
      )
    } else if (type === 'alert') {
      return (
        <FiAlertCircle 
          className="text-2xl text-yellow"
        />
      )
    } else {
      return (
        <FiAlertCircle 
          className="text-2xl"
        />
      )
    }
  }

  const dismissMessage = () => {
    setIsLoaded(false)
    setTimeout(() => {
      resetMessages()
    }, 500)
  }

  if (!ignoreTimeout) {
    startAutoDismissMessageTimer(10000)
  }
 
  return(
    <div 
      className={`fixed z-50 ${isLoaded ? 'top-4' : '-top-20'} 
      flex items-center justify-center
      w-full p-1 transition-all duration-500`}
    >
      <div 
        className={`flex items-center justify-between w-full lg:w-3/4 p-3 rounded 
        ${type === 'error' ? 'bg-fpx-red' : 'bg-black border border-grey-700'}`}
      >
        <div className="flex items-center">
          {icon()}
          <div className="ml-2">
            {message}
          </div>
        </div>

        <div className="flex">
          {buttonOptions &&
            <div 
              className="flex items-center justify-center px-1.5 py-0.5 text-sm bg-grey-100 bg-opacity-20 hover:cursor-pointer hover:bg-opacity-30 rounded"
              onClick={() => {
                dismissMessage()
                navigate(buttonOptions.url)
              }}
            >
              {buttonOptions.text}
            </div>
          }

          <div
            className="p-1 rounded lg:hover:bg-grey-100  lg:hover:bg-opacity-20 hover:cursor-pointer"
            onClick={() => {
              dismissMessage()
            }}
          >
            <IoMdClose className="text-2xl" />
          </div>
        </div>
      </div>
    </div>
  )
}