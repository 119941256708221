import { UIKitSettingsBuilder } from "@cometchat/chat-uikit-react"

const COMETCHAT_CONSTANTS = {
  APP_ID: import.meta.env.VITE_COMETCHAT_APPID,
  REGION: "US",
  AUTH_KEY: import.meta.env.VITE_COMETCHAT_AUTH_KEY
}

const UIKitSettings = new UIKitSettingsBuilder()
  .setAppId(COMETCHAT_CONSTANTS.APP_ID)
  .setRegion(COMETCHAT_CONSTANTS.REGION)
  .setAuthKey(COMETCHAT_CONSTANTS.AUTH_KEY)
  .subscribePresenceForFriends()
  .build()

export default UIKitSettings