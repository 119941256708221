import useStore from "../../../store"
import CreditAvailable from "./CreditAvailable"
import Spinner from '../../common/Spinner'
import { useTranslation } from 'react-i18next'

export default function CreditUtilization() {
  const dealer = useStore((state) => state.dealer)
  const { t } = useTranslation()

  return(
    <div className="flex flex-col items-center w-full">
      {dealer ?
        <div className="w-full">
          <div className="sticky top-0 left-0 z-30 bg-grey-900 pt-2 pb-2 lg:pb-3 px-2 rounded-t">
            <h3 className="font-header font-semibold text-md text-green">{t('Credit Available')}</h3>
          </div>
          <div className="bg-grey-900 mb-2 pb-2 px-2 pt-0.5 rounded-b">
            <CreditAvailable />
          </div>
        </div> :
        <Spinner />
      }
    </div>
  )
}