import BusinessDevCircle from "./BusinessDevCircle"
import Spinner from "../../common/Spinner"
import useStore from "../../../store"
import ClickableDiv from "../../common/ClickableDiv"

export default function BusinessDevMenu({ handleTableClick }) {
  const businessDevData = useStore((state) => state.businessDevData)
  const businessDevTableName = useStore((state) => state.businessDevTableName)
  
  return (
    businessDevData ?
      <div className="flex flex-wrap lg:flex-nowrap lg:gap-4 w-full bg-grey-900 rounded-b lg:pt-6 justify-evenly lg:pb-10">
        <ClickableDiv
          onClick={() => handleTableClick('dealers', 'Prospective Dealers', 'status=2000')}
          testid={"prospect-table"}
        >
          <BusinessDevCircle
            number={businessDevData.prospects_count}
            title={"Prospects"}
            goal={businessDevData.prospects_goal}
            prospects={true}
            selected={businessDevTableName === "Prospective Dealers"}
          />
        </ClickableDiv>
        <ClickableDiv
          onClick={() => handleTableClick('apps', 'Apps Awaiting Stips', 'scope=no_stips')}
          testid={"no-stips-table"}
        >
          <BusinessDevCircle
            number={businessDevData.no_stip_apps_count}
            title={"Apps Awaiting Stips"}
            goal={businessDevData.no_stip_apps_goal}
            selected={businessDevTableName === "Apps Awaiting Stips"}
          />
        </ClickableDiv>
        <ClickableDiv
          onClick={() => handleTableClick('apps', 'Apps Pending Approval', 'scope=pending_approval')}
          testid={'apps-pending-table'}
        >
          <BusinessDevCircle
            number={businessDevData.apps_pending_approval_count}
            title={"Apps Pending Approval"}
            goal={businessDevData.apps_pending_approval_goal}
            apps_pending={true}
            selected={businessDevTableName === "Apps Pending Approval"}
          />
        </ClickableDiv>
        <ClickableDiv
          onClick={() => handleTableClick('dealers', 'Awaiting 1st Floor', 'scope=awaiting_first_floor')}
          testid={'awaiting-first-floor-table'}
        >
          <BusinessDevCircle
          number={businessDevData.dealers_awaiting_first_floor_count}
          title={"Awaiting 1st Floor"}
          goal={businessDevData.dealers_awaiting_first_floor_goal}
          selected={businessDevTableName === "Awaiting 1st Floor"}
        />
        </ClickableDiv>
        <ClickableDiv
          onClick={() => handleTableClick('dealers','First Floors this Month','scope=first_floored_this_month')}
          testid={'first-floors-mtd-table'}
        >
          <BusinessDevCircle
            number={businessDevData.dealers_first_floor_mtd_count}
            title={"1st Floors MTD"}
            goal={businessDevData.dealers_first_floor_mtd_goal}
            selected={businessDevTableName === "First Floors this Month"}
          />
        </ClickableDiv>
      </div>
    :
      <Spinner/>
  )
}