
export default function RiskRowTitle({ amount, title }) {
  return(
    <>
      <span className="text-grey-100 mr-1.5">
        {amount}
      </span>
      {title}
    </>
  )
}