import useStore from "../../../store"
import formatter from "../../../constants/currencyFormatter"
import { FaEdit } from "react-icons/fa"
import { useTranslation } from "react-i18next"


export default function IssueFreeConfirmationDetails() {
  const { t } = useTranslation()
  const bankAccounts = useStore((state) => state.bankAccounts)
  const NAPRequiresBankPayoff = useStore((state) => state.NAPRequiresBankPayoff)
  const NAPBankAccount = useStore((state) => state.NAPBankAccount)
  const floorAmount = useStore((state) => state.NAPFloorAmount)
  const setCurrentStep = useStore((state) => state.setCurrentStep)

  return(
    <>
      <div className="flex items-center">
        {t('Floor Amount')}: {formatter.format(floorAmount)}
        <FaEdit 
          className="text-sm mx-1"
          onClick={() => setCurrentStep(0)}
          data-testid='edit-floor-amount'
        />
      </div>
      {NAPRequiresBankPayoff ?
        t('This unit requires a bank payoff') :
        `${t('Bank Account')}: ${bankAccounts.find((acct) => acct.id === NAPBankAccount.id)?.attributes['name-personal']}`
      }
      <FaEdit 
        className="text-sm mx-1"
        onClick={() => setCurrentStep(0)}
        data-testid='edit-bank-account'
      />
    </>
  )
}